<template>
  <div class="height100">
    <ReportFormHeader
      v-if="headerShow"
      :mouth="mouth"
      @dateChange="dateChange"
      @voucherSave="init"
      @printFun="printFun"
      :headerData="headerData"
    ></ReportFormHeader>
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-table" v-if="voucherList.length > 0">
          <div class="reportForm-table-center" id="html">
            <div
              class="voucher-box"
              v-for="(item, i1) in voucherList"
              :key="i1"
            >
              <div class="voucher-box-header print-header">
                <div class="voucher-box-header-l">
                  <slot v-if="item.belongtoDay === 0"
                    >{{ mouth }}-{{ lastDay }}</slot
                  >
                  <slot v-else>{{
                    (item.belongtoDay + "").substring(0, 4) +
                    "-" +
                    (item.belongtoDay + "").substring(4, 6) +
                    "-" +
                    (item.belongtoDay + "").substring(6, 8)
                  }}</slot>
                </div>
                <div class="voucher-box-header-c">
                  凭证：记 -
                  <span>
                    <slot v-if="item.code < 10">
                      <span>00{{ item.code }}</span>
                    </slot>
                    <slot v-else-if="item.code < 100">
                      <span>0{{ item.code }}</span>
                    </slot>
                    <slot v-else>
                      <span>{{ item.code }}</span>
                    </slot>
                  </span>
                </div>
                <div class="print-header-r">
                  ({{ pages.pageSize * (pages.pageNum - 1) + i1 + 1 }}/{{
                    pages.total
                  }})
                </div>
                <div class="voucher-box-header-r">
                  <!-- item.billCatagory === 100 || -->
                  <el-link
                    type="primary"
                    style="margin-left: 10px"
                    v-if="item.imageList.length > 0"
                    :underline="false"
                    @click="queryBankFlow(item.imageList)"
                    >附件</el-link
                  >
                  <!-- <el-link
                    type="primary"
                    v-if="
                      item.billCatagory === 100 || item.imageList.length > 0
                    "
                    :underline="false"
                    @click="editDate(item)"
                    >修改</el-link
                  > -->
                  <el-link
                    style="margin-left: 10px"
                    type="primary"
                    class="voucher-el-link"
                    :underline="false"
                    v-if="visit === 'visit' || item.type === 1"
                    @click="deleteEnterpriseVoucher(item, i1)"
                    >删除</el-link
                  >
                </div>
              </div>
              <table class="accountTable">
                <thead>
                  <tr>
                    <th class="table-width1"><div>摘要</div></th>
                    <th class="table-width2"><div>科目</div></th>
                    <th class="table-width3"><div>借方</div></th>
                    <th class="table-width3"><div>贷方</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(child, i2) in item.voucherDetailList"
                    :key="i2 + '-2'"
                  >
                    <td class="table-width1">
                      <div>{{ child.summary }}</div>
                    </td>
                    <td class="table-width2">
                      <div>{{ child.subjectName }}</div>
                    </td>
                    <td class="table-width3">
                      <div>
                        {{ child.debitPrice.toFixed(2) }}
                      </div>
                    </td>
                    <td class="table-width3">
                      <div>
                        {{ child.creditPrice.toFixed(2) }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">合计</td>
                    <td>
                      {{ item.debitPriceSum.toFixed(2) }}
                    </td>
                    <td>
                      {{ item.creditPriceSum.toFixed(2) }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="voucher-box-header voucher-box-bottom">
                <div class="voucher-box-header-l print-bottom">
                  核 准 :{{ item.approveUsername }}
                </div>
                <div class="voucher-box-header-c1 print-bottom">
                  审 核 :{{ item.checkUsername }}
                </div>
                <div class="voucher-box-header-c1 print-bottom">
                  出 纳 :{{ item.doctoredUsername }}
                </div>
                <div class="voucher-box-header-r print-bottom">
                  制 单 :{{ item.createUsername }}
                </div>
              </div>
            </div>
            <div class="pages" v-if="totalPage > 1">
              <el-pagination
                background
                layout="total, prev, pager, next, jumper"
                :page-size="pages.pageSize"
                :current-page.sync="pages.pageNum"
                :total="pages.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[30, 50, 100, 150]"
              >
              </el-pagination>
            </div>
          </div>
        </div>

        <NoRecord v-else></NoRecord>
      </div>
      <TheFooter></TheFooter>
    </div>
    <div class="popup-layer" v-if="BankFlowFlag">
      <div class="popup-layer-bg" @click="BankFlowFlag = false"></div>
      <div class="voucherList-popup-main">
        <div class="voucherList-popup-main-box">
          <div class="voucherList-popup-main-box-l">交易日期</div>
          <div class="voucherList-popup-main-box-r">
            {{ BankFlow.createTime.split(" ")[0] }}
          </div>
        </div>
        <div class="voucherList-popup-main-box voucherList-border">
          <div class="voucherList-popup-main-box-l">交易单号</div>
          <div class="voucherList-popup-main-box-r">
            {{ BankFlow.transFlowNo }}
          </div>
        </div>
        <div class="voucherList-popup-main-box voucherList-pad">
          <div class="voucherList-popup-main-box-l">对方账户名称</div>
          <div class="voucherList-popup-main-box-r">
            {{ BankFlow.duifangBank }}
          </div>
        </div>
        <div class="voucherList-popup-main-box voucherList-border">
          <div class="voucherList-popup-main-box-l">对方账号</div>
          <div class="voucherList-popup-main-box-r">
            {{ BankFlow.duifangAccountNo }}
          </div>
        </div>
        <div class="voucherList-popup-main-box voucherList-pad">
          <div class="voucherList-popup-main-box-l">金额</div>
          <div class="voucherList-popup-main-box-r">
            <slot v-if="BankFlow.payAmt === ''">{{
              BankFlow.shoukuanAmt
            }}</slot>
            <slot v-else>{{ BankFlow.payAmt }}</slot>
            <!-- {{ BankFlow.transFlowNo }} -->
          </div>
        </div>
        <div class="voucherList-popup-main-box">
          <div class="voucherList-popup-main-box-l">摘要</div>
          <div class="voucherList-popup-main-box-r">
            {{ BankFlow.yongtu }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="voucherDialog"
      :visible.sync="isShowImageDialog"
      title="查看票据"
      @click="clearImg"
    >
      <el-carousel indicator-position="outside" height="600px">
        <el-carousel-item autoplay="falses" v-for="src in imgs" :key="src">
          <a :href="src" target="_block">
            <img
              :src="src"
              style="
                max-width: 100%;
                max-height: 100%;
                display: block;
                margin: 0 auto;
                width: auto;
              "
          /></a>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <div class="print-show" id="printArea"></div>
  </div>
</template>
<script>
import ReportFormHeader from "./components/voucherHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import NoRecord from "@/components/NoRecord.vue";
import print from "print-js";
import {
  queryEnterpriseVoucher,
  queryBankFlow,
  deleteEnterpriseVoucher,
  voucherCodeReset,
  downloadVoucher,
  queryDownloadTask,
} from "@/http/api";
export default {
  data() {
    return {
      isShowImageDialog: false,
      headerShow: false,
      tableShow: false,
      overflowStyle: false,
      BankFlowFlag: false,
      wstyle: "",
      value: "",
      mouth: "",
      date: this.$config.getDate(),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      visit: sessionStorage.getItem("visit"),
      company: sessionStorage.getItem("company"),
      headerData: {
        left: true,
        right: true,
        title: "记账凭证",
        btn: 2,
        len: 0,
      },

      pages: {
        pageNum: 1,
        pageSize: 30,
        total: 0,
      },
      totalPage: 0,
      oldDate: "",
      imgUrl: "",
      voucherList: [],
      tableData: [],
      BankFlow: {},
      imgs: [],
      lastDay: "",
      printArea: "",
    };
  },
  components: {
    ReportFormHeader,
    TheFooter,
    NoRecord,
  },
  mounted() {
    this.mouth = this.date.year + "-" + this.date.thisMounth;
    this.oldDate = this.mouth;
    this.init();
    this.getCurrentMonthLast();
    // this.downloadVoucher();
    this.$nextTick(() => {
      this.headerShow = true;
    });
  },
  methods: {
    printFun() {
      if (this.voucherList.length === 0) {
        this.$store.commit("MyMessage", "暂无凭证可以打印");
        return false;
      }
      const style = "@page {margin:0 10mm};"; //打印时去掉眉页眉尾
      //打印为什么要去掉眉页眉尾？因为眉页title时打印当前页面的title，相当于是获取html中title标签里面的内容，但是比如我打印的内容只是一个弹框里面的内容，是没有title的，这时候就会出现undefined，为了避免出现这种情况，就可以隐藏眉页眉尾
      this.$nextTick(() => {
        printJS({
          printable: "printArea", // 标签元素id
          type: "html",
          header: "",
          targetStyles: ["*"],
          // style,
        });
      });
    },
    getCurrentMonthLast() {
      var date = new Date(this.mouth);
      var currentMonth = date.getMonth();
      var nextMonth = ++currentMonth;
      var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      var oneDay = 1000 * 60 * 60 * 24;
      var lastTime = new Date(nextMonthFirstDay - oneDay);
      var month = parseInt(lastTime.getMonth() + 1);
      var day = lastTime.getDate();
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }
      this.lastDay = day;
    },
    clearImg() {
      this.isShowImageDialog = false;
    },
    init() {
      this.queryEnterpriseVoucher();
    },
    queryBankFlow(item) {
      let array = [];
      item.forEach((element) => {
        let url = element;
        if (url.indexOf("http://jz.jzdcs.com") !== -1) {
          url = "https://www.jzcfo.com" + url.split("http://jz.jzdcs.com")[1];
        }
        array.push(url);
      });
      this.imgs = array;
      this.isShowImageDialog = true;
      return;
      this.imgUrl = item.billImg;
      let param = {
        enterpriseId: this.enterpriseId,
        bankFlowId: item.bankFlowId,
        userId: this.userId,
      };
      queryBankFlow(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.BankFlowFlag = true;
          this.BankFlow = res.data;
        }
        // this.voucherDetailList = res.data.voucher;
        // this.imgPopupFlag = true;
      });
    },

    voucherCodeReset() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth.split("-")[0] + "" + this.mouth.split("-")[1],
      };
      voucherCodeReset(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.init();
        }
      });
    },
    queryEnterpriseVoucher() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        queryDate: this.mouth,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
      };
      queryEnterpriseVoucher(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          // if (res.data.voucherList.totalRow === 0) {
          //   if (this.oldDate !== this.mouth) {
          //     this.$store.commit("MyMessage", "暂无记录");
          //     this.mouth = this.oldDate;
          //     this.init();
          //     return;
          //   }
          // }
          this.oldDate = this.mouth;
          this.voucherList = res.data.voucherList.list;
          this.totalPage = res.data.voucherList.pages;
          this.pages.total = res.data.voucherList.total;
          this.headerData.len = res.data.voucherList.total;
          this.voucherList.forEach((e) => {
            let creditPriceSum = 0,
              debitPriceSum = 0;
            e.voucherDetailList.forEach((e2) => {
              if (e2.creditPrice !== "") {
                creditPriceSum += e2.creditPrice * 1;
              }
              if (e2.creditPrice !== "") {
                debitPriceSum += e2.debitPrice * 1;
              }
            });
            e.creditPriceSum = creditPriceSum;
            e.debitPriceSum = debitPriceSum;
            e.boderShow = false;
          });

          this.voucherList.forEach((e) => {
            if (e.belongtoMonth) {
              e.belongtoMonth += "";
              let a = e.belongtoMonth + "";
            }
          });
          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
          this.$nextTick(() => {
            if (this.voucherList.length > 0) {
              let a = document.querySelector("#html").innerHTML;
              this.printArea = "600px";
              document.querySelector("#printArea").innerHTML = a;
            }
          });
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
        document.getElementById("reportFormMain").scrollTop = 0;
        // this.$config.scrollTop("reportFormMain");
        this.$forceUpdate();
      });
    },
    deleteEnterpriseVoucher(item, i) {
      item.boderShow = true;
      this.$confirm("此操作不可逆，是否进行删除", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          console.log(item);
          let param = {
            voucherId: item.id,
            enterpriseId: this.enterpriseId,
            userId: this.userId,
          };
          deleteEnterpriseVoucher(param).then((res) => {
            console.log(res);
            if (res.code === 0) {
              // this.queryEnterpriseVoucher();
              this.voucherList.splice(i, 1);
              this.$store.commit("MyMessage", "删除成功");
              this.voucherCodeReset();
            } else {
              this.$store.commit("MyMessage", res.msg);
            }
          });
        })
        .catch(() => {
          item.boderShow = false;
          this.$forceUpdate();
        });
    },
    dateChange(mouth) {
      this.mouth = mouth;
      this.pages.pageNum = 1;
      this.init();
      this.getCurrentMonthLast();
      // this.voucherCodeReset();
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.init();
    },
  },
};
</script>
<style lang="less" scoped>
.accountTable tbody td {
  height: 40px;
}
.voucher-box {
  padding: 20px 58px 0;
  border: 1px dashed #bbb;
  margin-bottom: 15px;
}

.voucher-box-header {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  height: 26px;
  line-height: 26px;
  .voucher-box-header-l {
    float: left;
    width: 25%;
    text-align: left;
    line-height: normal;
  }
  .voucher-box-header-r {
    float: left;
    width: 25%;
    text-align: right;
    line-height: normal;
    .voucher-el-link {
      margin-left: 15px;
      color: #999;
    }
  }
  .voucher-box-header-c {
    float: left;
    width: 50%;
    text-align: center;
    line-height: normal;
  }
  .voucher-box-header-c1 {
    float: left;
    width: 25%;
    text-align: center;
    line-height: normal;
  }
}
.voucher-box-bottom {
  margin-bottom: 20px;
}
.table-width1 {
  width: 20%;
}
.table-width2 {
  width: 32%;
}
.table-width3 {
  width: 26%;
}
.table-width4 {
  width: 22%;
}
#print {
  overflow-y: auto;
}
.voucherList-popup-main {
  width: 300px;
  min-height: 190px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;
  padding: 10px 20px;
  .voucherList-popup-main-box {
    width: 100%;
    // height: 30px;
    // line-height: 30px;
    overflow: hidden;
    &.voucherList-border {
      border-bottom: 1px dashed #dbdbdb;
      padding-bottom: 5px;
    }
    &.voucherList-pad {
      padding-top: 5px;
    }
    .voucherList-popup-main-box-l {
      display: inline-block;
      width: 90px;
      text-align: left;
      padding: 10px 0;
      float: left;
    }
    .voucherList-popup-main-box-r {
      display: inline-block;
      width: calc(100% - 90px);
      text-align: left;
      padding: 10px 0;
      float: left;
    }
  }
}
</style>
<style lang="less">
.voucherDialog {
  top: -50px;
  .el-dialog__header {
    height: 60px;
    line-height: 60px;
    background: #f8f9fa;
    padding: 0;
    border-bottom: 1px solid #dbdbdb;
    text-align: center;
  }
}
.print-title1,
.print-header-r {
  display: none;
}
</style>
<style lang="less">
.downStyle {
  background: none !important;
  border: none !important;
  color: #4c8afc !important;
  font-size: 14px !important;
}
@media print {
  #printArea {
    height: 10000px;
    overflow: hidden;
    display: block;
  }
}
.print-show {
  display: inline-block;
  width: 900px;
  height: auto;
  .print-header .voucher-box-header-r,
  .pages {
    display: none;
  }
  .print-title1 {
    width: 600px;
    margin: 0 auto 20px;
    text-align: center;
    display: block;
  }
  .voucher-box {
    min-height: 1250px;
  }
  td {
    padding: 8px 0;
  }
  .print-header-r {
    display: block;
    float: right !important;
    text-align: right;
  }
  .voucher-box {
    margin-bottom: 50px;
  }
  .voucher-box-bottom {
    width: 100%;
    .print-bottom {
      width: 18% !important;
      float: left !important;
      overflow: hidden;
    }
    .voucher-box-header-r {
      width: 34% !important;
      float: right !important;
      text-align: right;
    }
  }
  .print-title-1 {
    color: #262626;
    padding-bottom: 15px;
  }
  .print-title-2 {
    color: #262626;
    padding-bottom: 15px;
    border-bottom: 2px dashed #ddd;
  }
}
</style>
