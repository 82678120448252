<template>
  <div class="height100">
    <div class="reportForm-main" id="reportFormMain">
      <div class="reportForm-main-conent">
        <div class="reportForm-table-center">
          <el-tabs v-model="activeName">
            <el-tab-pane label="平衡式" name="slphs">
              <div v-if="activeName === 'slphs'">
                <slphs></slphs>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="价格平衡式" name="jgphs">
              <div v-if="activeName === 'jgphs'">
                <jgphs></jgphs>
              </div>
            </el-tab-pane>
            <el-tab-pane label="组合平衡式" name="zhphs">
              <div v-if="activeName === 'zhphs'">
                <zhphs></zhphs>
              </div>
            </el-tab-pane> -->
            <el-tab-pane label="商品库" name="commodity">
              <div v-if="activeName === 'commodity'">
                <commodity></commodity>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
  </div>
</template>

<script>
import slphs from "./slphs.vue";
// import jgphs from "./jgphs.vue";
// import zhphs from "./zhphs.vue";
import commodity from "./commoditys.vue";
import TheFooter from "@/components/TheFooter.vue";
import { deleteInventory } from "@/http/api";
export default {
  data() {
    return {
      activeName: "slphs",
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      tableData: [
        { idNum: "sss", oldCompany: 1, hosCompany: 2, jobCompany: 3 },
      ],
      mergeShow: false,
    };
  },
  components: {
    TheFooter,
    slphs,
    // jgphs,
    // zhphs,
    commodity,
    // detailedSearch,
  },
  props: {
    dataBase: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {},

  methods: {
    rosterClose() {
      this.mergeShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.reportForm-main {
  height: 100%;
}
.reportForm-table-center {
  padding-top: 30px;
}
</style>