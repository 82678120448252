<template>
  <div>
    <div class="popup-layer">
      <div class="popup-layer-bg"></div>
      <div class="voucher-add webkit-scrollbar">
        <div class="popup-layer-close" @click="popupClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="voucher-add-title">添加凭证</div>

        <div class="voucher-add-box">
          <div class="voucher-add-box-l">日期：</div>
          <div class="voucher-add-box-title">
            <el-date-picker
              style="width: 229px"
              v-model="belongtoMonth"
              value-format="yyyy-MM-dd"
              placeholder="请选择"
              :editable="false"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
              <i class="arrow-lower"></i>
            </el-date-picker>
          </div>
        </div>
        <div class="voucher-add-box">
          <div class="voucher-add-box-l">摘要：</div>
          <div class="voucher-add-box-title">
            <input
              type="text"
              v-model="zaiyao"
              class="voucher-add-ipt summaryBox"
              placeholder="请输入"
              style="width: 218px"
            />
          </div>
        </div>
        <div
          class="webkit-scrollbar"
          style="max-height: 375px; overflow-y: auto"
        >
          <div
            class="voucher-add-box"
            v-for="(item, index) in debitSubjectList"
            :key="index + '-1'"
          >
            <div class="voucher-add-box-l">借方科目：</div>
            <div class="voucher-add-box-c">
              <input
                type="text"
                v-model="item.text"
                class="voucher-add-ipt summaryBox"
                :class="{ inputShow: item.show }"
                placeholder="请选择"
                readonly
                @focus="sbjectPopupShow(item, $event)"
              />
            </div>
            <div class="voucher-add-box-r">
              <input
                v-model="item.amt"
                type="text"
                class="voucher-add-ipt"
                placeholder="请输入金额"
              />
            </div>
            <div class="voucher-add-box-btn">
              <span
                class="el-icon-circle-plus-outline inner-add-icon"
                @click="SubjectListAdd(1, index)"
              ></span>
              <span
                class="el-icon-remove-outline inner-del-icon"
                @click="SubjectListDel(1, index)"
              ></span>
            </div>
            <div class="clear"></div>
          </div>
          <div
            class="voucher-add-box"
            v-for="(item, index) in creditSubjectList"
            :key="index + '-2'"
          >
            <div class="voucher-add-box-l">贷方科目：</div>
            <div class="voucher-add-box-c">
              <input
                type="text"
                readonly
                v-model="item.text"
                :class="{ inputShow: item.show }"
                @focus="sbjectPopupShow(item, $event)"
                class="voucher-add-ipt summaryBox"
                placeholder="请选择"
              />
            </div>
            <div class="voucher-add-box-r">
              <input
                v-model="item.amt"
                type="text"
                class="voucher-add-ipt"
                placeholder="请输入金额"
              />
            </div>
            <div class="voucher-add-box-btn">
              <span
                class="el-icon-circle-plus-outline inner-add-icon"
                @click="SubjectListAdd(2, index)"
              ></span>
              <span
                class="el-icon-remove-outline inner-del-icon"
                @click="SubjectListDel(2, index)"
              ></span>
            </div>
            <div class="clear"></div>
          </div>
        </div>
        <div style="text-align: center">
          <el-button
            type="text"
            style="
              width: 200px;
              margin: 20px 30px 20px 0;
              color: #bbb;
              background: #f4f4f4;
            "
            @click="popupClose"
            >取 消</el-button
          >
          <el-button
            type="primary"
            style="width: 200px; margin: 20px 0"
            @click="SubjectListSave"
            >添 加</el-button
          >
        </div>
      </div>
    </div>
    <div
      class="sbjectPopup summaryBox"
      style="position: fixed"
      :style="{
        left: popupLeft + 'px',
        top: popupTop + 'px',
        bottom: popupBottom + 'px',
      }"
      v-if="subjectPopupShow"
      :class="{ popupBottom: popupBottom !== 'auto' }"
    >
      <Triangle :direction="direction" left="30"></Triangle>
      <div class="sbjectPopup-nav summaryBox">
        <div
          class="sbjectPopup-nav-box summaryBox"
          v-for="(nav, i1) in SubjectTypeList"
          :key="i1"
          :class="{ cr: subjectType === nav.name }"
          @click="navClick(nav.name)"
        >
          {{ nav.title }}
        </div>
      </div>
      <div class="sbjectPopup-list summaryBox">
        <div class="sbjectPopup-list-search summaryBox">
          <input
            type="text"
            v-model="sbjectValue"
            @input="NewItems(sbjectValue)"
            class="voucher-add-ipt search-ipt summaryBox"
            placeholder="请选择"
          />
        </div>
        <div
          class="sbjectPopup-list-box summaryBox"
          v-for="(e2, i2) in SubjectList"
          :key="i2"
          @click="SubjectListClick(subjectItem, e2)"
        >
          {{ e2.subjectText }}
          <div class="zdy-box" v-if="e2.valueShow">
            <input
              @click.stop=""
              class="voucher-add-ipt summaryBox"
              type="text"
              v-model="e2.zdyName"
              placeholder="请输入自定义名称"
              style="text-align: left; border: none"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSubjectList,
  addEnterpriseVoucher,
  voucherCodeReset,
} from "@/http/api";
import SvgIcon from "../../../components/SvgIcon.vue";
export default {
  components: { SvgIcon },
  data() {
    return {
      accountingCriterion: sessionStorage.getItem("accountingCriterion"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      subjectPopupShow: false,
      belongtoMonth: "",
      direction: "",
      popupLeft: "",
      popupTop: "",
      popupBottom: "",
      zaiyao: "",
      debitSubjectList: [
        {
          value: "",
          id: "",
          amt: "",
          text: "",
          isSelf: 0,
          debitORCredit: 10,
          subjectCode: "",
          subjectName: "",
          show: false,
          show1: false,
        },
      ], //借方科目列表
      creditSubjectList: [
        {
          value: "",
          id: "",
          amt: "",
          text: "",
          isSelf: 0,
          debitORCredit: 11,
          subjectCode: "",
          subjectName: "",
          show: false,
          show1: false,
        },
      ], //贷方科目列表
      subjectData: {
        value: "",
        id: "",
        amt: "",
        text: "",
        isSelf: 0,
        subjectCode: "",
        subjectName: "",
        show: false,
        show1: false,
      },
      subjectType: "1",
      SubjectTypeList: [
        {
          title: "资产",
          name: "1",
          content: "1",
        },
        {
          title: "负债",
          name: "2",
          content: "2",
        },
        {
          title: "权益",
          name: "3",
          content: "3",
        },
        {
          title: "成本",
          name: "4",
          content: "4",
        },
        {
          title: "损益",
          name: "5",
          content: "5",
        },
        {
          title: "共同",
          name: "6",
          content: "6",
        },
        {
          title: "公式",
          name: "7",
          content: "7",
        },
      ],
      subjectItem: {},
      SubjectList: [],
      SubjectListCopy: [],
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
    };
  },
  mounted() {
    let that = this;
    that.getSubjectListNavFun(1);
    if (that.$route.path === "/voucherList") {
      document.addEventListener("click", function (e) {
        if (typeof e.target.className === "string") {
          if (e.target.className.indexOf("summaryBox") === -1) {
            that.debitSubjectList.forEach((element) => {
              element.show = false;
              element.show1 = false;
            });
            that.creditSubjectList.forEach((element) => {
              element.show = false;
              element.show1 = false;
            });
            that.subjectPopupShow = false;
            that.$forceUpdate();
          }
        }
      });
    }
  },
  methods: {
    voucherCodeReset() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.belongtoMonth,
      };
      voucherCodeReset(param).then((res) => {
        console.log(res);
      });
    },
    popupClose() {
      this.$emit("popupClose");
    },
    NewItems(subjectText) {
      var _this = this;
      var NewItems = [];
      this.SubjectListCopy.map(function (item) {
        if (
          item.subjectName.search(subjectText) != -1 ||
          (item.subjectCode + item.subjectName).search(subjectText) != -1 ||
          item.subjectName.search(subjectText) != -1
        ) {
          NewItems.push(item);
        }
      });
      _this.SubjectList = NewItems;
    },
    SubjectListClick(item, e2) {
      if (e2.valueShow && e2.zdyName === "") {
        this.$store.commit("MyMessage", "请输入自定义名称");
        return;
      }
      if (e2.valueShow) {
        item.subjectName = e2.zdyName;
        item.text = e2.subjectCode + " " + e2.zdyName;
        item.isSelf = 1;
      } else {
        item.subjectName = e2.subjectName;
        item.text = e2.subjectCode + "" + e2.subjectName;
      }
      item.show = false;
      this.subjectPopupShow = false;
      item.subjectCode = e2.subjectCode;
    },
    SubjectListAdd(type, index) {
      let text = "借方";
      let SubjectList = this.debitSubjectList;
      let debitORCredit = 10;
      if (type === 2) {
        text = "贷方";
        SubjectList = this.creditSubjectList;
        debitORCredit = 11;
      }
      let flag1 = false;
      let flag2 = false;
      SubjectList.forEach((element) => {
        if (element.subjectCode === "") {
          flag1 = true;
        }
        if (element.amt === "") {
          flag2 = true;
        }
      });
      if (flag1) {
        return this.$store.commit("MyMessage", "请选择" + text + "科目");
      }
      if (flag2) {
        return this.$store.commit("MyMessage", "请输入金额");
      }
      let param = this.$config.cloneObj(this.subjectData);
      param.debitORCredit = debitORCredit;
      SubjectList.splice(index + 1, 0, param);
    },
    SubjectListDel(type, index) {
      if (type === 1) {
        if (this.debitSubjectList.length > 1) {
          this.debitSubjectList.splice(index, 1);
        } else {
          this.$store.commit("MyMessage", "最少要保留一条借方科目");
        }
      } else {
        if (this.creditSubjectList.length > 1) {
          this.creditSubjectList.splice(index, 1);
        } else {
          this.$store.commit("MyMessage", "最少要保留一条贷方科目");
        }
      }
    },
    SubjectListSave() {
      // console.log(this.belongtoMonth);
      // return;
      if (this.belongtoMonth === "") {
        this.$store.commit("MyMessage", "请选择月份");
        return;
      } else if (this.zaiyao === "") {
        this.$store.commit("MyMessage", "请输入摘要");
        return;
      }
      let array = this.belongtoMonth.split("-");
      let param = {
        belongtoDay: array[0] + "" + array[1] + "" + array[2],
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        belongtoMonth: array[0] + "" + array[1],
        summary: this.zaiyao,
        voucherDetailVoList: [],
      };
      let voucherDetailVoList = [];
      let amtSum1 = 0;
      let amtSum2 = 0;
      this.debitSubjectList.forEach((element) => {
        if (element.amt !== "") {
          voucherDetailVoList.push({
            debitORCredit: element.debitORCredit,
            subjectCode: element.subjectCode,
            subjectName: element.subjectName,
            debitPrice: element.amt,
            isSelf: element.isSelf,
          });
          amtSum1 += element.amt * 1;
        }
      });
      this.creditSubjectList.forEach((element) => {
        if (element.amt !== "") {
          voucherDetailVoList.push({
            debitORCredit: element.debitORCredit,
            subjectCode: element.subjectCode,
            subjectName: element.subjectName,
            creditPrice: element.amt,
            isSelf: element.isSelf,
          });
          amtSum2 += element.amt * 1;
        }
      });
      amtSum1 = amtSum1.toFixed(2);
      amtSum2 = amtSum2.toFixed(2);
      if (amtSum1 !== amtSum2) {
        this.$store.commit("MyMessage", "借方金额总额与贷方金额总额不相等");
        return;
      }
      console.log(voucherDetailVoList);
      // return;
      this.$store.commit("showLoading", "");
      param.voucherDetailVoList = voucherDetailVoList;
      addEnterpriseVoucher(param).then((res) => {
        this.$store.commit("hideLoading", "");
        if (res.code === 0) {
          this.$store.commit("MyMessage", "添加成功");
          // location.reload();
          this.voucherAddFlag = false;
          this.voucherCodeReset();
          this.$emit("voucherSave");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    sbjectPopupShow(item, e) {
      this.subjectPopupShow = false;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      if (H1 < 450) {
        this.popupTop = "auto";
        this.popupBottom = H1 + 50;
        this.direction = "bottom";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 50;
        this.popupBottom = "auto";
        this.direction = "top";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left;
      this.subjectPopupShow = true;
      this.subjectItem = item;
      this.sbjectValue = "";
      this.debitSubjectList.forEach((element) => {
        element.show = false;
      });
      this.creditSubjectList.forEach((element) => {
        element.show = false;
      });
      item.show = true;
      this.SubjectList = this.SubjectListCopy;
    },
    navClick(index) {
      this.subjectType = index;
      this.getSubjectListNavFun(this.subjectType);
    },
    getSubjectListNavFun(subjectType) {
      let param = {
        accountingCriterion: this.accountingCriterion,
        pageSize: 10000,
        pageNum: 1,
        subjectType: subjectType,
      };
      getSubjectList(param).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            this.SubjectList = res.data.list;
            this.SubjectList.forEach((element) => {
              element.subjectText =
                element.subjectCode + "     " + element.subjectName;
              if (
                element.subjectName.indexOf("**") !== -1 ||
                element.subjectName.indexOf("报销人员") !== -1
              ) {
                element.valueShow = true;
                element.zdyName = "";
              } else {
                element.valueShow = false;
              }
            });
            this.SubjectListCopy = this.$config.cloneObj(this.SubjectList);
          }
          this.$forceUpdate();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.popup-layer .popup-layer-close {
  padding: 0 15px;
}
.voucher-add {
  width: 600px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -320px;
  padding: 30px 20px;
  max-height: 630px;
  border-radius: 10px;
  .voucher-add-title {
    font-size: 24px;
    color: #262626;
    padding-bottom: 20px;
    text-align: center;
  }
}
.voucher-add-box {
  width: 100%;
  margin-bottom: 15px;
}
.voucher-add-box-title {
  width: calc(100% - 100px);
  display: inline-block;
  text-align: left;
}
.voucher-add-box-l {
  width: 100px;
  float: left;
  text-align: right;
  height: 50px;
  line-height: 50px;
}
.voucher-add-box-c {
  width: 240px;
  float: left;
  text-align: left;
  position: relative;
}
.voucher-add-box-r {
  width: 180px;
  float: left;
}
.voucher-add-box-btn {
  width: calc(100% - 520px);
  text-align: center;
  position: relative;
  height: 38px;
  float: left;
}
.voucher-add-ipt {
  width: 90%;
  margin: 0 auto;
  height: 36px;
  border: 1px solid #bbb;
  border-radius: 4px;
  padding-left: 10px;
  &::placeholder {
    color: #bbb;
  }
  &.search-ipt {
    width: 96%;
    margin: 5px auto;
  }
  &:focus {
    border: 1px solid #4c8afc;
    box-shadow: 0px 1px 7px #4c8afc;
  }
  &.inputShow {
    border: 1px solid #4c8afc;
    box-shadow: 0px 1px 7px #4c8afc;
  }
}
.inner-add-icon {
  color: #4c8afc;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  left: 0px;
  top: 4px;
  padding: 0 5px;
}

.inner-del-icon {
  position: relative;
  left: 0px;
  top: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  padding: 0 5px;
}
.sbjectPopup {
  position: absolute;
  left: 0;
  background: #fff;
  width: 450px;
  height: 275px;
  border: 1px solid #4c8afc;
  box-shadow: 0px 1px 7px #4c8afc;
  z-index: 99;
}

.sbjectPopup-nav {
  width: 100%;
  height: 37px;
  overflow: hidden;
  .sbjectPopup-nav-box {
    float: left;
    width: 58.6px;
    border-right: 1px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #262626;
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    cursor: pointer;
    &:last-child {
      border-right: none;
    }
    &.cr {
      background: #1a73e8;
      color: #fff;
    }
  }
}
.sbjectPopup-list {
  height: 230px;
  overflow-y: auto;
  .sbjectPopup-list-box {
    height: 40px;
    line-height: 40px;
    position: relative;
    cursor: pointer;
    width: 92%;
    border-bottom: 1px solid rgba(187, 187, 187, 0.3);
    margin-left: 2%;
    text-align: left;
    &:hover {
      color: #4c8afc;
    }
    .zdy-box {
      width: 205px;
      display: inline-block;
      height: 24px;
    }
  }
}
</style>
<style lang="less">
.voucher-add-box-title {
  .el-select .el-input.is-focus .el-input__inner,
  .el-input__inner {
    border: 1px solid #bbb;
    &::placeholder {
      color: #bbb;
    }
  }
  .el-select .el-input__inner:focus {
    border-color: #bbb;
  }
  .el-date-editor {
    margin-left: 0;
    .el-input__inner {
      width: 100%;
      height: 38px;
      border: 1px solid #bbb;
      text-align: left;
      padding-left: 30px;
    }
  }
}
.vouche-span {
  width: 90px;
  text-align: left;
  margin: 0 auto;
}
.voucherDialog .el-dialog__header {
  height: 60px;
  line-height: 60px;
  background: #f8f9fa;
  padding: 0;
  border-bottom: 1px solid #dbdbdb;
}
.accountBaslance {
  .el-date-editor--monthrange.el-input,
  .el-date-editor--monthrange.el-input__inner {
    width: 180px;
  }
  .el-select {
    width: 180px;
    margin-right: 10px;
  }
}
</style>