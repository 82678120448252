<template>
  <div class="popup_layer handleAssets">
    <div class="popup_layer_bg"></div>
    <div class="popup_layer_content">
      <div class="popup_layer_content_top">
        处置资产
        <i class="popup_layer_close el-icon-close" @click="closeFun()"></i>
      </div>
      <div class="popup_layer_main webkit-scrollbar">
        <div class="popup_layer_box">
          <div class="popup_layer_box_left">
            处置方式<span class="require">*</span>
          </div>
          <div class="popup_layer_box_right">
            <el-select
              v-model="dataBase.dealType"
              class="popup_layer_select"
              placeholder="请选择"
            >
              <el-option
                v-for="item in disposalModeList"
                :key="item.id"
                :label="item.text"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="popup_layer_box"
          :class="{
            disposalModeShow: dataBase.dealType !== '1',
            disposalModeShow1: dataBase.dealType !== '1',
          }"
        >
          <div class="popup_layer_box_left">
            变卖金额<span class="require">*</span>
          </div>
          <div class="popup_layer_box_right">
            <el-input
              v-model="dataBase.dealAmt"
              placeholder="请输入"
            ></el-input>
            <span class="box_right_text">元</span>
          </div>
        </div>

        <div
          class="popup_layer_box"
          :class="{
            disposalModeShow: dataBase.dealType !== '1',
            disposalModeShow1: dataBase.dealType !== '1',
          }"
        >
          <div class="popup_layer_box_left">
            收款方式<span class="require">*</span>
          </div>
          <div class="popup_layer_box_right">
            <el-select
              class="popup_layer_select"
              v-model="dataBase.shoukuanType"
              v-show="dataBase.dealType === '1'"
              placeholder="请选择"
            >
              <el-option
                v-for="item in collectionModeList"
                :key="item.id"
                :label="item.text"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="popup_layer_box">
          <el-button type="primary" @click="open">确定处置</el-button>
        </div>
      </div>
    </div>
    <div class="toast" v-show="toastShow">
      {{ toastText }}
    </div>
  </div>
</template>

<script>
import { gudingAssetDeal, queryEnterpriseInfoForUser } from "@/http/api";
export default {
  name: "addAssets",
  data() {
    return {
      toastText: "",
      toastShow: false,
      // popupShow: true,
      titleName: "",
      value: "选项1",
      input: "",
      disposalMode: "1",
      dataBase: {
        enterpriseId: "",
        id: "",
        dealType: "",
        dealAmt: "",
        shoukuanType: "",
        bankCardId: "",
      },
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      disposalModeList: this.$addAssets.disposalMode.list, //处置方式
      collectionMode: "",
      collectionModeList: this.$addAssets.collectionMode.list, //收款方式
    };
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    itemData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.queryEnterpriseInfoForUser();
  },
  methods: {
    queryEnterpriseInfoForUser() {
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        // console.log("res", res);
        if (res.code === 0) {
          if (res.data.bankList.length > 0)
            this.dataBase.bankCardId = res.data.bankList[0].bankcardId;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    toast(str) {
      let v = this;
      v.toastText = str;
      v.toastShow = true;
      setTimeout(function () {
        v.toastShow = false;
      }, 2000);
    },
    gudingAssetDeal() {
      let that = this;
      gudingAssetDeal(this.dataBase).then((res) => {
        if (res.code === 0) {
          that.toast("操作成功");
          setTimeout(function () {
            that.$emit("closePopupFun", "handle");
          }, 1000);
        } else {
          that.toast(res.msg);
        }
      });
    },
    open() {
      this.$confirm("一旦确认，无法撤销！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          //           dataBase: {
          //   enterpriseId: "",
          //   id: "",
          //   dealType: "",
          //   dealAmt: "",
          //   shoukuanType: "",
          //   bankCardId: "",
          // },
          this.dataBase.enterpriseId = this.itemData.enterpriseId;
          this.dataBase.id = this.itemData.id;
          this.gudingAssetDeal();
        })
        .catch(() => {
          this.closeFun();
        });
    },
    closeFun() {
      this.$emit("closePopupFun", "handle");
    },
    submitClick() {
      gudingAssetDeal();
      // this.$emit("closePopupFun", "handle");
    },
  },
};
</script>

<style lang="less" scoped>
.popup_layer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

  .popup_layer_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(38, 38, 38, 0.6);
  }

  .popup_layer_content {
    width: 486px;
    height: 900px;
    background: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -243px;
    margin-top: -450px;

    .popup_layer_content_top {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #f4f4f4;
      font-size: 18px;
      font-weight: 400;
      position: relative;

      .popup_layer_close {
        position: absolute;
        right: 20px;
        top: 0;
        font-size: 20px;
        font-weight: bold;
        padding-top: 14px;
        color: #999999;
        cursor: pointer;
      }
    }

    .popup_layer_main {
      width: 100%;
      height: calc(100% - 76px);
      overflow-y: auto;
      padding-top: 26px;

      .popup_layer_box {
        padding: 0 56px 10px 28px;

        .popup_layer_box_left {
          width: 130px;
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          text-align: right;
          position: relative;

          .require {
            position: absolute;
            right: -9px;
            top: 1px;
            font-size: 16px;
            color: #e74b47;
          }

          .desc {
            font-size: 12px;
            text-align: right;
            /* float: right; */
            position: absolute;
            color: #999;
            right: -13px;
          }
        }
        .popup_layer_box_right {
          width: calc(100% - 150px);
          display: inline-block;
          padding-left: 20px;
          position: relative;

          .el-input__inner {
            padding-right: 50px;
          }

          .box_right_text {
            position: absolute;
            right: 15px;
            top: 9px;
            font-size: 14px;
            font-weight: bold;
            color: #262626;
          }
        }
      }

      .el-button {
        width: 100%;
        margin-top: 20px;
      }

      .disposalModeShow {
        margin-bottom: 50px;
      }

      .disposalModeShow1 {
        opacity: 0;
        margin-bottom: 0px;
      }
    }
  }

  &.handleAssets {
    .popup_layer_content {
      width: 486px;
      height: 366px;
      margin-left: -243px;
      margin-top: -300px;
      // padding-bottom: 30px;

      .popup_layer_box {
        padding: 0 69px 10px;
        text-align: right;

        .popup_layer_box_left {
          width: 60px;
        }

        .popup_layer_box_right {
          width: calc(100% - 80px);
        }
      }

      .el-button {
        margin-top: 50px;
      }
    }
  }
}
.popup_layer_select {
  width: 100%;
}
</style>