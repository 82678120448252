<template>
  <div class="assetMain">
    <div class="assetMain-table">
      <table class="accountTable">
        <thead>
          <tr>
            <th rowspan="2"><div class="table-width1">编号</div></th>
            <th rowspan="2"><div class="table-width2">所属部门</div></th>
            <th rowspan="2"><div class="table-width3">入账时间</div></th>
            <th colspan="2">资产类别</th>
            <th rowspan="2"><div class="table-width4">资产名称</div></th>
            <th rowspan="2"><div class="table-width5">规格型号</div></th>
            <th rowspan="2"><div class="table-width5">单位</div></th>
            <th rowspan="2">
              <div class="table-width5">使用寿命<br />（月）</div>
            </th>
            <th rowspan="2">
              <div class="table-width5">已用寿命<br />（月）</div>
            </th>
            <th rowspan="2">
              <div class="table-width5">剩余寿命<br />（月）</div>
            </th>

            <th rowspan="2"><div class="table-width3">资产原值</div></th>
            <th rowspan="2">
              <div class="table-width5">预计<br />净残值率</div>
            </th>
            <th rowspan="2">
              <div class="table-width7">以前年度<br />累计折旧额</div>
            </th>
            <th rowspan="2">
              <div class="table-width5">期初资产<br />净值</div>
            </th>
            <th rowspan="2"><div class="table-width6">折旧方法</div></th>
            <th rowspan="2"><div class="table-width5">月折旧额</div></th>
            <th rowspan="2">
              <div class="table-width5">本年累计<br />折旧额</div>
            </th>
            <th rowspan="2">
              <div class="table-width5">期末资产<br />净值</div>
            </th>
          </tr>
          <tr>
            <th><div class="table-width7">大类</div></th>
            <th><div class="table-width8">细类</div></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td class="table-width1">{{ index + 1 }}</td>
            <td class="table-width2">{{ item.assetDepartmentName }}</td>
            <td class="table-width3">
              {{ item.assetRecordDate.split(" ")[0] }}
            </td>
            <td class="table-width7">{{ item.assetKindName }}</td>
            <td class="table-width8">{{ item.assetKindSecName }}</td>

            <td class="table-width4">{{ item.assetName }}</td>
            <td class="table-width5">{{ item.assetMode }}</td>
            <td class="table-width5">{{ item.assetUnit }}</td>

            <td class="table-width5">{{ item.assetLife }}</td>
            <td class="table-width5">{{ item.assetUsedLife }}</td>
            <td class="table-width5">{{ item.assetShengyuLife }}</td>

            <td class="table-width5">
              <slot> {{ item.assetInitPrice }} </slot>
            </td>
            <td class="table-width9">{{ item.yjjczl }}%</td>
            <td class="table-width5">{{ item.yiqiannianleijizhejiu }}</td>
            <td class="table-width7">{{ item.qichuPrice }}</td>
            <td class="table-width4">{{ item.zhejiuFangfa }}</td>
            <td class="table-width5">
              <slot v-if="item.isDeal === 1 || item.isDeal === 2"> 0 </slot>
              <slot v-else> {{ item.yuezhejiue }} </slot>
            </td>
            <td class="table-width5">{{ item.bennianleijizhejiue }}</td>
            <td class="table-width5">{{ item.qimozichanjingzhi }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.assetMain {
  width: 100%;
}
.table-width1 {
  width: 50px;
}
.table-width2 {
  width: 100px;
}
.table-width3 {
  width: 80px;
}
.table-width4 {
  width: 82px;
}
.table-width5 {
  width: 60px;
}
.table-width6 {
  width: 80px;
}
.table-width7 {
  width: 70px;
}
.table-width8 {
  width: 84px;
}
.table-width9 {
  width: 90px;
}
</style>