<template>
  <div class="height100">
    <ReportFormHeader
      v-if="false"
      :mouth="mouth"
      @dateChange="dateChange"
      :headerData="headerData"
    ></ReportFormHeader>
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-tabs">
          <div
            class="reportForm-tabs-active is-top"
            :style="{
              transform: 'translateX(' + activeIndex + 'px)',
            }"
          ></div>
          <div class="reportForm-tabs-left">
            <div class="reportForm-tablist">
              <div
                class="reportForm-tabs-item"
                v-for="(item, i) in navList"
                :key="i"
                :id="'tabsId-' + i"
                :class="{ tabsCr: item.moduleName === activeTab }"
                @click="tabsClick(item, i, 1)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="reportForm-tabs-right">
            <div class="reportForm-tablist">
              <div
                class="reportForm-tabs-item"
                v-for="(item, i) in navListRight"
                :key="i"
                :id="'tabsId-' + i"
                :class="{ tabsCr: item.moduleName === activeTab }"
                @click="tabsClick(item, i, item.id)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="reportForm-table">
          <router-view />
          <div class="reportForm-table-center">
            <!-- <div class="detailed-search">
              <div class="detailed-search-left">
                <button
                  class="reportForm-btn active noLeft"
                  @click="addInventoryShow = true"
                >
                  添加库存品类
                </button>
              </div>

              <div class="detailed-search-right">
                <el-input
                  class="inputPrefix"
                  placeholder="搜索行业、品类、商品名称"
                  prefix-icon="el-icon-search"
                  v-model="searchValue"
                >
                </el-input>
              </div>
            </div>
            <div class="assetList-main">
              <slot v-if="dataList.length > 0">
                <kcmxb
                  v-if="activeTab === 1"
                  :dataBase="dataList"
                  @closeFun="closeFun"
                ></kcmxb>
                <kcpl
                  v-if="activeTab === 6"
                  :dataBase="dataList"
                  @closeFun="closeFun"
                ></kcpl>
                <div class="pages" v-if="totalPage > 1">
                  <el-pagination
                    background
                    layout="total, prev, pager, next, jumper"
                    :page-size="pages.pageSize"
                    :current-page.sync="pages.curren"
                    :total="pages.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[20, 50, 100]"
                  >
                  </el-pagination>
                </div>
              </slot>
              <NoRecord v-else></NoRecord>
            </div> -->
          </div>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
    <addInventory
      v-if="addInventoryShow"
      @closeFun="closeFun"
      :options="options"
    ></addInventory>
  </div>
</template>
<script>
import ReportFormHeader from "../components/reportFormHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
// import NoRecord from "@/components/NoRecord.vue";
// import kcpl from "./components/kcpl.vue";
// import kcmxb from "./components/kcmxb.vue";
import addInventory from "./components/addInventory.vue";
import { inventoryList, keywordsGetKeywordsList } from "@/http/api";
export default {
  data() {
    return {
      addInventoryShow: false,
      headerShow: false,
      overflowStyle: false,
      date: this.$config.getDate(),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      mouth: "",
      searchValue: "",
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      headerData: {
        left: false,
        right: false,
        title: "库存明细",
      },
      type: "10",
      moduleName: "yinszk",
      activeTab: 1,
      activeIndex: 0,
      // navList: [],
      navList: [
        {
          id: 1,
          title: "库存明细表",
          moduleName: "/kcmxb",
          width: 70,
        },
        {
          id: 1,
          title: "暂估库存明细表",
          moduleName: "/zgkcmxb",
          width: 80,
        },
        {
          id: 1,
          title: "待处理库存",
          moduleName: "/dclkc",
          width: 70,
        },
      ],
      navListRight: [
        {
          id: 6,
          title: "库存品类",
          moduleName: "/kcpl",
          width: 52,
        },
      ],
      tableData: [],
      totalPage: 0,
      pages: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      oldDate: "",
      dataList: [{}],
      options: [],
    };
  },
  components: {
    ReportFormHeader,
    TheFooter,
    // NoRecord,
    // kcpl,
    // kcmxb,
    addInventory,
  },
  mounted() {
    this.activeTab = this.$route.path;
    this.mouth = this.date.year + "-" + this.date.thisMounth;
    this.oldDate = this.mouth;
    this.$nextTick(() => {
      this.headerShow = true;
    });
    let navId = 1;
    let index = 0;
    this.navList.forEach((element, i) => {
      // console.log(element);
      if (element.moduleName === this.activeTab) {
        navId = element.id;
        index = i;
      }
    });
    this.navListRight.forEach((element, i) => {
      if (element.moduleName === this.activeTab) {
        navId = element.id;
        index = i;
      }
    });
    // this.activeIndex = 1;
    this.navStyle(navId, index);
    // console.log(navId);
    // this.keywordsGetKeywordsList();
  },
  watch: {
    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        // console.log(newValue);
        this.activeTab = newValue.path;
        this.$forceUpdate();
      },
    },
  },
  methods: {
    keywordsGetKeywordsList() {
      var param = {
        pageSize: 10000,
        pageNum: 1,
      };
      keywordsGetKeywordsList(param).then((res) => {
        this.options = res.data.list;
        this.options.forEach((element) => {
          element.keyWordText = element.keyWord;
          if (element.keywordSecond) {
            element.keyWordText =
              element.keyWordText + "-" + element.keywordSecond;
          }
        });
      });
    },
    closeFun(flag) {
      this.addInventoryShow = false;
      if (flag) {
        this.init();
      }
    },
    init() {
      this.oldDate = this.mouth;
      this.pages = {
        total: 0,
        current: 1,
        pageSize: 20,
      };
      this.ajaxFun();
    },

    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.ajaxFun();
      this.$config.scrollTop("reportFormMain");
    },
    handleCurrentChange(val) {
      this.pages.current = val;
      this.ajaxFun();
      this.$config.scrollTop("reportFormMain");
    },
    ajaxFun() {
      this.inventoryList();
    },
    inventoryList() {
      let param = {
        enterpriseId: this.enterpriseId,
      };
      inventoryList(param).then((res) => {
        if (res.code === 0) {
          // this.dataList = res.data;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    dateChange(mouth) {
      this.pages = {
        current: 1,
        pageSize: 20,
        total: 0,
      };
      this.ajaxFun();
    },
    navStyle(increment, index) {
      // console.log(index);
      if (increment < 6) {
        this.activeIndex = 0;
        this.navList.forEach((v, k) => {
          if (k < index) {
            this.activeIndex = this.activeIndex + v.width + 43;
          }
          if (k === index) {
            this.activeIndex = this.activeIndex + (v.width - 38) / 2;
          }
        });
      } else {
        this.activeIndex = 1328;
        this.navListRight.forEach((v, k) => {
          if (k > index) {
            this.activeIndex = this.activeIndex - v.width - 43;
          }
          if (k === index) {
            this.activeIndex = this.activeIndex - (v.width + 38) / 2;
          }
        });
      }
    },
    tabsClick(item, index, increment) {
      console.log(increment);
      this.navStyle(increment, index);
      this.moduleName = item.moduleName;
      // this.activeTab = item.id;
      this.$router.push(item.moduleName);
      // this.funName = item.fun;
      // this.init();
      // this.$forceUpdate();
    },
  },
};
</script>
<style lang="less" scoped>
.reportForm-main {
  height: 100%;
}
.reportForm-main-conent {
  width: 96% !important;
  margin: 0 auto;
  min-width: 1328px;
}
</style>
