<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="closeFun(false)"></div>
    <div class="stock-popup-main">
      <div class="popup-layer-close" @click="closeFun(false)">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class=""></div>
      <div class="stock-popup-name popup-add-name popup-add-name-f">
        添加暂估库存明细
      </div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <span class="box-span">库存日期</span>
          <div class="stock-popup-box-l">
            <el-date-picker
              v-model="item.inventoryDate"
              format="yyyy-MM-dd"
              value-format="yyyyMMdd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <span class="box-span">商品名称</span>
          <div class="stock-popup-box-l">
            <el-input
              v-model="item.goodsName"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <span class="box-span">规格型号</span>
          <div class="stock-popup-box-l">
            <el-input
              v-model="item.goodsModel"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <span class="box-span">商品单位</span>
          <div class="stock-popup-box-l">
            <el-input
              v-model="item.goodsUnit"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <span class="box-span">暂估单价</span>
          <div class="stock-popup-box-l">
            <el-input
              v-model="item.estimatedPrice"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <span class="box-span">暂估数量</span>
          <div class="stock-popup-box-l">
            <el-input
              v-model="item.estimatedNum"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <span class="box-span">暂估金额</span>
          <div class="stock-popup-box-l">
            <el-input
              v-model="item.estimatedAmount"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <span class="box-span">摘要</span>
          <div class="stock-popup-box-l">
            <el-input
              v-model="item.summary"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
      </div>
      <el-button type="primary" class="stock-popup-btn" @click="addStock">
        添 加
      </el-button>
    </div>
  </div>
</template>

<script>
import { addEstimatedDetail } from "@/http/api";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      stockValue: "",
      stockValue1: "",
      inventoryDate: "",
      item: {
        enterpriseId: sessionStorage.getItem("enterpriseId"), //企业Id
        // inventoryYear: "", //2022
        // inventoryMonth: "", //月份 2022-01
        inventoryDate: "", //日期 2022-01-01
        goodsName: "", //商品名称
        goodsModel: "", //规格型号
        goodsUnit: "", //商品单位
        estimatedPrice: "", //暂估单价
        estimatedNum: "", //暂估数量
        estimatedAmount: "", //暂估金额
        summary: "", //摘要
      },
      list1: [
        {
          key: "1",
          value: "进项票",
        },
        {
          key: "-1",
          value: "销项票",
        },
      ],
      list2: [
        {
          key: "9",
          value: "直接结转",
        },
        {
          key: "10",
          value: "间接结转",
        },
      ],
    };
  },
  mounted() {},
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    addStock() {
      let param = {
        enterpriseId: this.enterpriseId,
        keywordId: this.stockValue,
      };
      if (!this.item.inventoryDate) {
        this.$store.commit("MyMessage", "请选择日期");
        return false;
      }
      // let date = this.inventoryDate.split("-");
      // this.item.inventoryYear = date[0];
      // this.item.inventoryMonth = date[0] + "-" + date[1];
      // this.item.inventoryDate = date[0] + "" + date[1] + "" + date[2];
      // console.log(this.item);
      // return;
      this.item.estimatedAmount = this.item.estimatedAmount * 1;
      this.item.estimatedNum = this.item.estimatedNum * 1;
      this.item.estimatedPrice = this.item.estimatedPrice * 1;
      addEstimatedDetail(this.item).then((res) => {
        if (res.code === 0) {
          this.$store.commit("MyMessage", "操作成功");
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    closeFun(flag) {
      this.$emit("closeFun", flag);
    },
  },
};
</script>

<style lang="less" scoped>
.stock-popup-main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 412px;
  min-height: 189px;
  margin-left: -206px;
  margin-top: -400px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 12px;
  padding: 10px 0;
  text-align: center;
  .stock-popup-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    .el-icon-close {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .stock-popup-box-scrollbar {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
  }
  .stock-popup-title {
    width: 323px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #262626;
  }
  .stock-popup-name {
    width: 323px;
    margin: 23px auto 10px;
    text-align: left;

    font-size: 16px;
    font-weight: 400;
    color: #262626;
    &.popup-add-name {
      margin: 10px auto 10px;
    }
    &.popup-add-name-f {
      margin: 20px auto 10px;
    }
  }
  .stock-popup-box {
    width: 323px;
    margin-left: 45px;
    text-align: left;
    padding-bottom: 10px;
    .box-span {
      display: inline-block;
      width: 70px;
    }
    .stock-popup-box-l {
      width: 252px;
      display: inline-block;
      .el-select {
        width: 100%;
      }
    }
    .stock-popup-box-r {
      width: calc(100% - 230px);
      display: inline-block;
      text-align: right;
    }
  }
  .stock-popup-btn {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 323px;
    height: 34px;
    line-height: 34px;
    background: #4c8afc;
    border: 1px solid #4c8afc;
    border-radius: 3px;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  }
}
</style>

<style lang="less">
.reportForm {
  .stock-popup-box-l {
    .el-date-editor {
      width: 100%;
      margin-left: 0;
      .el-input__inner {
        width: 100%;
        height: 34px;
        background: #ffffff;
        border: 1px solid #dcdfe6;
        color: #606266;
        padding: 0;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        padding: 0 15px;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
  }
}
</style>