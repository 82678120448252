<template>
  <el-container>
    <el-header>
      <div>
        选择方式：
        <el-radio-group v-model="radio">
          <el-radio :label="1">数量</el-radio>
          <el-radio :label="2">价格</el-radio>
        </el-radio-group>
      </div>
      <div class="balanced" v-if="radio === 1">
        <div class="balanced-box balanced-box-1">
          主商品：<input type="text" placeholder="请输入名称" />
          (商品数量：<input type="text" placeholder="请输入" /> 商品单位：<input
            type="text"
            placeholder="请输入"
          />)
        </div>
        <div class="balanced-box" v-for="(e, i) in list" :key="i">
          <span v-if="i === 0" class="icon1">=</span>
          <span v-else class="icon1">+</span>
          子商品：<input
            type="text"
            v-model="e.name"
            placeholder="请输入名称"
          />
          (商品数量：<input type="text" v-model="e.num" placeholder="请输入" />
          商品单位：<input type="text" v-model="e.unit" placeholder="请输入" />)
          <i
            @click="addBalanced"
            v-if="i === list.length - 1"
            class="el-icon-circle-plus-outline"
          ></i>
        </div>
        <el-button type="primary" size="medium">添 加</el-button>
      </div>
      <div class="balanced" v-if="radio === 2">
        <div class="balanced-box balanced-box-1">
          主商品：<input type="text" placeholder="请输入名称" />
          (商品数量：<input type="text" placeholder="请输入" /> 商品单位：<input
            type="text"
            placeholder="请输入"
          />)
        </div>
        <div class="balanced-box" v-for="(e, i) in list" :key="i">
          <span v-if="i === 0" class="icon1">=</span>
          <span v-else class="icon1">+</span>
          子商品：<input
            type="text"
            v-model="e.name"
            placeholder="请输入名称"
          />
          (商品单价：<input
            type="text"
            v-model="e.num"
            placeholder="请输入"
          />%)

          <i
            @click="addBalanced"
            v-if="i === list.length - 1"
            class="el-icon-circle-plus-outline"
          ></i>
        </div>
        <el-button type="primary" size="medium">添 加</el-button>
      </div>
    </el-header>
    <div class="clear"></div>
    <el-main>
      <div>历史记录</div>
      <br />
      <div class="balanced-list">
        <el-table
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          border
          lazy
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="name" label="商品名称"> </el-table-column>
          <el-table-column prop="num" align="center" label="数量" width="180">
          </el-table-column>
          <el-table-column prop="unit" align="center" label="单位" width="180">
          </el-table-column>
          <el-table-column
            prop="address"
            align="center"
            width="80"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      radio: 1,
      list: [
        {
          name: "",
          num: "",
          unit: "",
        },
      ],

      tableData: [
        {
          id: "1",
          unit: "台",
          num: "1",
          name: "电脑",
          children: [
            {
              id: "101",
              unit: "个",
              num: "4",
              name: "鼠标",
            },
            {
              id: "102",
              unit: "个",
              num: "1",
              name: "键盘",
            },
          ],
        },
        {
          id: "2",
          unit: "个",
          num: "4",
          name: "洗衣机",
        },
        {
          id: "3",
          unit: "个",
          num: "3",
          name: "显示器",
        },
        {
          id: "4",
          unit: "个",
          num: "5",
          name: "桌子",
        },
      ],
    };
  },
  methods: {
    handleClick(row) {},
    addBalanced() {
      this.list.push({
        name: "",
        num: "",
        unit: "",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.balanced {
  // width: 100%;
  padding: 10px 20px;
  text-indent: 20px;
  .balanced-box {
    font-size: 14px;
    height: 40px;
    position: relative;
    .icon1 {
      position: absolute;
      left: -20px;
      top: 3px;
      display: inline-block;
    }
    input {
      border-bottom: 1px solid #dbdbdb;
      font-size: 14px;
      font-weight: 400;
      &::placeholder {
        color: #bbb;
      }
    }
  }
}
.balanced-list {
}
.el-header,
.el-main {
  padding: 0;
  height: auto !important;
}

.el-main {
  border-top: 1px solid #dbdbdb;
  padding-top: 15px;
}
.balanced-box-1 {
}
.el-icon-circle-plus-outline {
  color: #4c8afc;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
</style>