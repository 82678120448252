import { render, staticRenderFns } from "./addZgKc.vue?vue&type=template&id=376fabe7&scoped=true&"
import script from "./addZgKc.vue?vue&type=script&lang=js&"
export * from "./addZgKc.vue?vue&type=script&lang=js&"
import style0 from "./addZgKc.vue?vue&type=style&index=0&id=376fabe7&lang=less&scoped=true&"
import style1 from "./addZgKc.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376fabe7",
  null
  
)

export default component.exports