<template>
  <div class="height100">
    <ReportFormHeader
      v-if="headerShow"
      :mouth="mouth"
      @dateChange="dateChange"
      :headerData="headerData"
    ></ReportFormHeader>
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
        style="height: calc(100% - 79px)"
      >
        <div class="reportForm-table" v-if="billList.length > 0">
          <div class="reportForm-table-center">
            <table class="accountTable">
              <thead>
                <tr>
                  <th><div class="table-width1">序号</div></th>
                  <th><div class="table-width2">购买方</div></th>
                  <th><div class="table-width3">销售方</div></th>
                  <th><div class="table-width4">摘要</div></th>
                  <th><div class="table-width5">金额</div></th>
                  <th><div class="table-width6">上传日期</div></th>
                </tr>
              </thead>
            </table>
            <div class="accountDiv">
              <table class="accountTable">
                <tbody>
                  <tr
                    v-for="(item, index) in billList"
                    :key="index"
                    @click="queryBillInfo(item)"
                  >
                    <td>
                      <div class="table-width1">{{ index + 1 }}</div>
                    </td>
                    <td>
                      <div class="table-width2">{{ item.buyer }}</div>
                    </td>
                    <td>
                      <div class="table-width3">{{ item.seller }}</div>
                    </td>
                    <td>
                      <div class="table-width4">
                        {{
                          item.billClass === 12
                            ? "银行流水单"
                            : item.billPurposeDesc
                        }}
                      </div>
                    </td>
                    <td>
                      <div class="table-width5">
                        {{ item.amountInFiguers.toFixed(2) }}
                      </div>
                    </td>
                    <td>
                      <div class="table-width6">{{ item.createTime }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <NoRecord v-else></NoRecord>
      </div>
      <TheFooter></TheFooter>
    </div>
    <div
      class="popup-layer"
      v-show="isShowImageDialog"
      :style="{ opacity: loadingShow ? '1' : '0' }"
    >
      <div class="popup-layer-bg" @click="isShowImageDialog = false"></div>
      <div class="img-popup-main" :style="{ marginLeft: marginLeft + 'px' }">
        <a :href="imgs" target="_block">
          <img ref="pageContainer" :src="imgs" alt=""
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
import { queryEnterpriseBill, queryBillInfo } from "@/http/api";
import ReportFormHeader from "./components/reportFormHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import NoRecord from "@/components/NoRecord.vue";
export default {
  data() {
    return {
      headerShow: false,
      tableShow: false,
      overflowStyle: false,
      value: "",
      mouth: "",
      date: this.$config.getDate(),
      visit: sessionStorage.getItem("visit"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      headerData: {
        left: true,
        right: false,
        title: "公司票据",
        btn: 1,
      },
      oldDate: "",
      billList: [],
      pages: {
        pageNum: 1,
        pageSize: 999,
        total: 0,
      },
      marginLeft: "",
      imgs: "",
      loadingShow: false,
      isShowImageDialog: false,
    };
  },
  components: {
    ReportFormHeader,
    TheFooter,
    NoRecord,
  },
  mounted() {
    let _route = this.$route;
    if (_route.query.mouth) {
      this.mouth = _route.query.mouth;
    } else {
      this.mouth = this.date.year + "-" + this.date.thisMounth;
    }
    this.oldDate = this.mouth;
    this.init();
    this.$nextTick(() => {
      this.headerShow = true;
    });
  },
  watch: {
    $route(to, from) {
      if (to.query.mouth === undefined) {
        this.mouth = this.date.year + "-" + this.date.thisMounth;
        this.oldDate = this.mouth;
        this.init();
        // location.reload();
      }
    },
  },
  methods: {
    init() {
      this.tableShow = false;
      this.queryEnterpriseBill();
    },
    queryBillInfo(item) {
      console.log(item.billImg);
      let that = this;
      if (!item.billImg) {
        return;
      }
      this.imgs = item.billImg;
      this.isShowImageDialog = true;
      this.loadingShow = false;
      setTimeout(() => {
        this.$nextTick(() => {
          let a = that.$refs.pageContainer.clientWidth;
          that.marginLeft = (a / 2) * -1;
          that.loadingShow = true;
        });
      }, 500);
    },
    queryEnterpriseBill() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
      };
      queryEnterpriseBill(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          // this.pages.total = res.data.totalRow;
          let array = [];
          if (res.data.billList.length) {
            res.data.billList.forEach((element) => {
              if (element.billClass !== 12) {
                if (element.billImg.indexOf("http://jz.jzdcs.com") !== -1) {
                  element.billImg =
                    "https://www.jzcfo.com" +
                    element.billImg.split("http://jz.jzdcs.com")[1];
                }
                array.push(element);
              }
            });
          }
          // if (array.length === 0) {
          //   if (this.oldDate !== this.mouth) {
          //     let text =
          //       this.mouth.split("-")[0] +
          //       "年" +
          //       this.mouth.split("-")[1] +
          //       "月";
          //     this.$store.commit("MyMessage", `暂无 ${text} 记录`);
          //     this.mouth = this.oldDate;
          //     this.$router.push({
          //       path: "/billList",
          //       query: {
          //         mouth: this.mouth,
          //       },
          //     });
          //     return;
          //   }
          // }
          this.oldDate = this.mouth;
          this.billList = array;
          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
        // this.subjectList = this.copyList;
      });
    },
    dateChange(mouth) {
      this.mouth = mouth;
      this.$router.push({
        path: "/billList",
        query: {
          mouth: this.mouth,
        },
      });
      this.init();
    },
  },
};
</script>
<style lang="less" scoped>
tbody tr {
  cursor: pointer;
}
.reportForm-main-conent {
  height: calc(100% - 79px);
}
.reportForm-table {
  height: 100%;
}
.reportForm .reportForm-table .reportForm-table-center {
  height: 100%;
}

.accountDiv {
  width: 100%;
  height: calc(100% - 47px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  .accountTable tbody tr {
    &:first-child {
      td {
        border-top: none;
      }
    }
  }
}
.table-width1 {
  display: inline-block;
  width: 40px;
}
.table-width2 {
  display: inline-block;
  width: 240px;
}
.table-width3 {
  display: inline-block;
  width: 240px;
}
.table-width4 {
  display: inline-block;
  width: 280px;
}
.table-width5 {
  display: inline-block;
  width: 160px;
}
.table-width6 {
  display: inline-block;
  width: 216px;
}
.img-popup-main {
  max-height: 600px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -300px;
  padding: 10px 20px;
  img {
    height: 100%;
    max-height: 400px;
    width: auto;
  }
}
</style>

