<template>
  <div class="reportForm-header">
    <div class="reportForm-header-left">
      <div class="reportForm-header-width" v-if="headerData.left">
        日期
        <el-date-picker
          v-if="dataShow"
          v-model="mouthR"
          :editable="false"
          value-format="yyyy-MM"
          type="month"
          placeholder="选择月"
          @change="dateChange"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <span class="pingzheng" v-if="sum > 0">凭证总计：{{ sum }}条</span>
      </div>
      <div class="reportForm-header-width" v-if="headerData.jiduShow">
        <el-select
          v-model="quarterDate"
          placeholder="请选择"
          @change="selectChange"
        >
          <el-option
            v-for="item in quarterList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <span class="pingzheng" v-if="sum > 0">凭证总计：{{ sum }}条</span>
      </div>
    </div>
    <div class="reportForm-header-center">
      <div class="reportForm-header-width">
        <slot v-if="headerData.jiduShow">{{ jiduText }}</slot
        >{{ showMouth }}{{ headerData.title }}
      </div>
    </div>
    <div class="reportForm-header-right">
      <div class="reportForm-header-width" v-if="headerData.right">
        <el-button
          v-if="addType === 'kemu' && visit === 'visit'"
          type="primary"
          class="btn-primary"
          style="width: 110px"
          @click="addAccount"
          >添加科目</el-button
        >
        <el-button
          v-if="headerData.xiazai === 'true'"
          type="primary"
          class="btn-primary"
          style="width: 110px"
          @click="dowmReport"
          >下载报表</el-button
        >
        <el-button
          type="primary"
          v-if="jindu === 'true'"
          class="btn-primary"
          @click="goToFun('/' + headerData.url)"
          >季度报表</el-button
        >
        <el-button
          type="primary"
          v-if="jindu === 'yue'"
          class="btn-primary"
          @click="goToFun('/' + headerData.url)"
          >月报表</el-button
        >
        <el-button
          type="primary"
          v-if="headerData.btn === 1 && visit === 'visit'"
          class="btn-primary"
          @click="goToFun('/taxReturns')"
          >报税表</el-button
        >
        <el-button
          type="primary"
          v-if="headerData.btn === 2 && jurisdiction === '1'"
          class="btn-primary"
          @click="voucherCodeReset"
          style="width: 140px"
        >
          重新生成编码
        </el-button>
        <el-button
          type="primary"
          v-if="headerData.btn === 2 && visit === 'visit'"
          class="btn-primary"
          @click="popupShow = true"
        >
          添加凭证
        </el-button>
        <!-- <el-button
          type="primary"
          v-if="headerData.btn === 2"
          class="btn-primary"
          @click="printFun"
        >
          打印凭证
        </el-button> -->
        <el-button
          type="primary"
          v-if="headerData.btn === 2"
          class="btn-primary"
          @click="queryDownloadTaskLoad"
        >
          下载凭证
        </el-button>

        <!-- <span @click="downloadVoucher">请求</span> -->
        <!-- <span @click="queryDownloadTask">查询</span> -->
        <!-- <el-input
          class="voucherIpt"
          placeholder="请输入摘要名称"
          prefix-icon="el-icon-search"
          v-model="voucherIpt"
        >
        </el-input> -->
      </div>
    </div>
    <voucherListPopup
      v-if="headerData.btn === 2 && popupShow"
      @popupClose="popupClose"
      @voucherSave="voucherSave"
    ></voucherListPopup>
  </div>
</template>

<script>
import {
  voucherCodeReset,
  downloadVoucher,
  queryDownloadTask,
  downloadGet,
} from "@/http/api";
import voucherListPopup from "./voucherListPopup.vue";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      company: sessionStorage.getItem("company"),
      userId: sessionStorage.getItem("userId"),
      visit: sessionStorage.getItem("visit"),
      jurisdiction: sessionStorage.getItem("jurisdiction"),
      popupShow: false,
      dataShow: true,
      showMouth: "",
      mouthR: "",
      jiduText: "",
      voucherIpt: "",
      queryYear: "",
      quarterDate: "",
      date: this.$config.getDate(),
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      mouthText: "",
      quarterYear: "",
      quarterList: [],
    };
  },
  components: {
    voucherListPopup,
  },
  props: {
    mouth: {
      type: String,
      default: null,
    },
    jindu: {
      type: String,
      default: null,
    },
    addType: {
      type: String,
      default: null,
    },
    headerData: {
      type: Object,
      default() {
        return {};
      },
    },
    sum: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  watch: {
    mouth(val) {
      this.mouthR = val;
      if (this.mouthR !== "") {
        // this.mouthText =
        //   this.mouthR.split("-")[0] + "年" + this.mouthR.split("-")[1] + "月";
      }
    },
  },
  mounted() {
    this.mouthR = this.mouth;
    if (this.mouthR !== "") {
      // this.mouthText =
      //   this.mouthR.split("-")[0] + "年" + this.mouthR.split("-")[1] + "月";
    }
    this.quarterYear = this.date.year;
    if (this.headerData.quarter === 4) {
      this.quarterYear = this.quarterYear - 1;
    }
    // console.log(window.location.origin);
    this.quarterList = [];
    if (
      this.date.thisMounth === "01" ||
      this.date.thisMounth === "02" ||
      this.date.thisMounth === "03"
    ) {
      this.queryYear = this.date.year - 1;
    } else {
      this.queryYear = this.date.year;
    }
    if (this.headerData.jiduShow) {
      for (let i = 0; i < this.headerData.quarter; i++) {
        this.quarterList.push({ id: i, title: `第${i + 1}季度` });
      }
      this.jiduText = `${this.queryYear}年第${this.headerData.quarter * 1}季度`;
      this.quarterDate = this.headerData.quarter * 1 - 1;
    }
    // this.downloadVoucher();
    this.$forceUpdate();
  },
  methods: {
    addAccount() {
      this.$emit("addAccount");
    },
    downloadVoucher() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
      };
      downloadVoucher(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.queryDownloadTask();
        }
      });
    },
    queryDownloadTask() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        taskType: 7,
        pageSize: 99999,
        pageNum: 1,
      };
      queryDownloadTask(param).then((res) => {
        if (res.code === 0) {
          if (res.data.list.length > 0) {
            if (res.data.list[0].createStatus === 2) {
              this.downloadGet(res.data.list[0].id);
            }
            // let origin = window.location.origin;
            // if (origin === "http://192.168.3.8:8010")
            //   origin = "http://www.ceshihr.com";
            // let downName = "/" + res.data.list[0].path;
            // window.open(origin + "" + downName);
          } else {
            this.$store.commit("showLoading", "");
            setTimeout(() => {
              this.queryDownloadTask();
            }, 2000);
          }
        }
      });
    },
    queryDownloadTaskLoad() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        taskType: 7,
        pageSize: 99999,
        pageNum: 1,
      };
      queryDownloadTask(param).then((res) => {
        if (res.code === 0) {
          if (res.data.list.length > 0) {
            if (res.data.list[0].createStatus === 2) {
              this.downloadGet(res.data.list[0].id);
            } else {
              this.$store.commit("showLoading", "");
              setTimeout(() => {
                this.queryDownloadTaskLoad();
              }, 2000);
            }
            // let origin = window.location.origin;
            // if (origin === "http://192.168.3.8:8010")
            //   origin = "http://www.ceshihr.com";
            // let downName = "/" + res.data.list[0].path;
            // window.open(origin + "" + downName);
          } else {
            this.downloadVoucher();
          }
        }
      });
    },
    downloadGet(downloadId) {
      let date = this.mouthR.split("-")[0] + "-" + this.mouthR.split("-")[1];

      let that = this;
      this.$store.commit("showLoading", "");
      const formData = new FormData();
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // type: "application/zip"

          const blob = new Blob([xhr.response], {
            type: "application/zip",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.company + " " + "记账凭证"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          }
          this.$store.commit("hideLoading", "");
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open(
        "GET",
        url +
          `/manager/voucher-download/download?enterpriseId=${this.enterpriseId}&downloadId=${downloadId}&month=${date}`
      );
      xhr.setRequestHeader("token", this.token);
      xhr.responseType = "blob";
      xhr.send(formData);
    },
    voucherCodeReset() {
      let date = this.mouthR.split("-")[0] + "" + this.mouthR.split("-")[1];
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: date,
      };
      voucherCodeReset(param).then((res) => {
        console.log(res);
        if (res.code == 0) {
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    printFun() {
      this.$emit("printFun");
    },
    selectChange() {
      this.jiduText = `${this.queryYear}年第${this.quarterDate * 1 + 1}季度`;
      this.$emit("jiduDate", this.quarterDate * 1 + 1);
    },
    dowmReport() {
      this.$emit("dowmReport");
    },
    voucherSave() {
      this.$emit("voucherSave");
      this.popupShow = false;
    },
    popupClose() {
      this.popupShow = false;
      this.$forceUpdate();
    },
    dateChange() {
      // this.showMouth = this.mouthR;
      this.showMouth =
        this.mouthR.split("-")[0] + "年" + this.mouthR.split("-")[1] + "月";
      this.$emit("dateChange", this.mouthR);
    },
    goToFun(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.pingzheng {
  padding-left: 20px;
}
.voucherIpt {
  width: 150px;
  margin-left: 20px;
}
</style>