<template>
  <div class="taxReturns">
    <div class="taxReturns-body">
      <div class="reportForm-title">{{ taxReturnsText }}税费明细</div>
      <div class="taxReturns-main">
        <slot v-if="queryTaxDetailList.length > 0">
          <div class="taxReturns-mian-box-l">
            <table>
              <thead>
                <tr>
                  <th><div class="table-w1">税种</div></th>
                  <th><div class="table-w2">金额¥</div></th>
                  <th><div class="table-w2">税率%</div></th>
                  <th><div class="table-w3">付款方式</div></th>
                  <th><div class="table-w5">状态</div></th>
                </tr>
              </thead>
              <tbody>
                <slot v-for="(item, index) in queryTaxDetailList">
                  <tr :key="index" v-if="index < queryTaxDetailList.length / 2">
                    <td>
                      <div class="table-w1">{{ item.taxName }}</div>
                    </td>
                    <td>
                      <div class="table-w2">
                        <input
                          type="text"
                          :readonly="item.taxState !== -1 || !baoshuiFlag"
                          v-model="item.taxAmt"
                          placeholder="0"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="table-w2">
                        <el-select
                          v-model="item.value"
                          @change="selectChange(item)"
                          placeholder="请选择"
                          :disabled="item.taxState !== -1 || !baoshuiFlag"
                        >
                          <el-option
                            v-for="item in list"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <td>
                      <div class="table-w3">
                        <el-select
                          v-model="item.taxPayType"
                          @change="selectChange(item)"
                          placeholder="请选择"
                          :disabled="item.taxState !== -1 || !baoshuiFlag"
                        >
                          <el-option
                            v-for="item in selectList"
                            :key="item.bankcardId"
                            :label="item.bankName"
                            :value="item.bankcardId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <td class="td-style">
                      <div
                        class="table-w4"
                        v-if="item.taxState === -1"
                        @click="shenbaoClick(item)"
                        :class="{ unClick: !baoshuiFlag && !noTime }"
                      >
                        申报
                      </div>
                      <div
                        class="table-w5"
                        v-if="item.taxState === 1 || item.taxState === 0"
                      >
                        完成
                      </div>
                      <div
                        class="add-icon"
                        v-if="item.taxName === '支付应交增值税'"
                      >
                        <i class="el-icon-circle-plus-outline inner-add-icon"></i>
                      </div>
                    </td>
                  </tr>
                </slot>
              </tbody>
            </table>
          </div>
          <div class="taxReturns-mian-box-l">
            <table>
              <thead>
                <tr>
                  <th><div class="table-w1">税种</div></th>
                  <th><div class="table-w2">金额¥</div></th>
                  <th><div class="table-w2">税率%</div></th>
                  <th><div class="table-w3">付款方式</div></th>
                  <th><div class="table-w5">状态</div></th>
                </tr>
              </thead>
              <tbody>
                <slot v-for="(item, index) in queryTaxDetailList">
                  <tr :key="index" v-if="index >= queryTaxDetailList.length / 2">
                    <td>
                      <div class="table-w1">{{ item.taxName }}</div>
                    </td>
                    <td>
                      <div class="table-w2">
                        <input
                          type="text"
                          :readonly="item.taxState !== -1 || !baoshuiFlag"
                          v-model="item.taxAmt"
                          placeholder="0"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="table-w2">
                        <el-select
                          v-model="item.value"
                          @change="selectChange(item)"
                          placeholder="请选择"
                          :disabled="item.taxState !== -1 || !baoshuiFlag"
                        >
                          <el-option
                            v-for="item in list"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <td>
                      <div class="table-w3">
                        <el-select
                          v-model="item.taxPayType"
                          @change="selectChange(item)"
                          placeholder="请选择"
                          :disabled="item.taxState !== -1 || !baoshuiFlag"
                        >
                          <el-option
                            v-for="item in selectList"
                            :key="item.bankcardId"
                            :label="item.bankName"
                            :value="item.bankcardId"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </td>
                    <td>
                      <div
                        class="table-w4"
                        v-if="item.taxState === -1"
                        :class="{ unClick: !baoshuiFlag && !noTime }"
                        @click="shenbaoClick(item)"
                      >
                        申报
                      </div>
                      <div
                        class="table-w5"
                        v-if="item.taxState === 1 || item.taxState === 0"
                      >
                        完成
                      </div>
                    </td>
                  </tr>
                </slot>
              </tbody>
            </table>
          </div>
        </slot>
        <slot v-else><p class="listHright">暂无记录！</p></slot>
      </div>
      <div class="clear"></div>
      <!-- {{ baoshuiFlag }} -->
      <slot v-if="queryTaxDetailList.length > 0">
        <el-button
          class="plainStyle"
          v-if="baoshuiFlag && !noTime"
          :disabled="!baoshuiFlag && noTime"
          @click="declareFun()"
          plain
        >
          完成报税
        </el-button>
        <el-button class="plainStyle" :disabled="!baoshuiFlag" v-else plain>
          <slot v-if="sum === 0">申报完成</slot>
          <slot v-else> 申报完成 已缴款</slot>
        </el-button>
      </slot>
    </div>
    <TheFooter></TheFooter>
    <div class="popup-layer" v-if="invitationShow">
      <div class="popup-layer-bg"></div>
      <div class="invitation-main">
        <div class="banklist-main">
          <p class="payment-p1">{{ popupData.taxName }}</p>
          <p class="payment-p2"><span>¥</span>{{ popupData.taxAmt }}</p>
          <p class="payment-text1">付款方式：</p>
          <p class="payment-text2">{{ popupData.bankName }}</p>
        </div>
        <div class="payment-main">
          <div class="payment-btns" style="margin-top: 30px">
            <el-button
              type="primary"
              style="width: 397px; height: 44px; font-size: 14px"
              @click="submitBtn"
            >
              确定
            </el-button>
            <br />
            <el-button class="payment-btn1" @click="invitationShow = false">
              取消
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
import {
  queryEnterpriseInfoForUser,
  queryEnterpriseInfo,
  queryTaxDetail,
  taxDetailDeal,
  updateBaoShuiStatus,
  queryBaoShuiStatusG,
} from "@/http/api";
export default {
  data() {
    return {
      date: this.$config.getDate(),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      declareType: "",
      mouth: "",
      taxReturnsText: "",
      queryTaxDetailList: [],
      baoshuiFlag: false,
      invitationShow: false,
      selectList: [],
      list: [
        {
          key: 0,
          value: "0%",
        },
        {
          key: 1,
          value: "1%",
        },
        {
          key: 3,
          value: "3%",
        },
        {
          key: 5,
          value: "5%",
        },
        {
          key: 6,
          value: "6%",
        },
        {
          key: 9,
          value: "9%",
        },
        {
          key: 13,
          value: "13%",
        },
        {
          key: 111,
          value: "免税",
        },
        {
          key: 112,
          value: "不征税",
        },
        {
          key: 113,
          value: "资产类4%",
        },
        {
          key: 114,
          value: "减免税额",
        },
        {
          key: 115,
          value: "资产类3%",
        },
        {
          key: 116,
          value: "资产类13%",
        },
      ],
      payment: this.$dictionaries.payment,
      popupData: {
        taxName: "",
        taxAmt: "",
      },
      sum: 0,
      noTime: false,
    };
  },
  components: {
    TheFooter,
  },
  mounted() {
    let year =
      this.date.lastMounth === "12" ? this.date.year - 1 : this.date.year;
    this.mouth = year + "-" + this.date.lastMounth;
    this.queryEnterpriseInfo();
    this.queryEnterpriseInfoForUser();
  },
  methods: {
    queryBaoShuiStatusG(param) {
      queryBaoShuiStatusG(param).then((res) => {
        // console.log(res.data[0].status);
        if (res.code === 0) {
          this.baoshuiFlag = false;
          if (res.data.length > 0) {
            if (this.declareType === 1) {
              if (
                this.date.thisMounth + "" === "01" ||
                this.date.thisMounth + "" === "04" ||
                this.date.thisMounth + "" === "07" ||
                this.date.thisMounth + "" === "10"
              ) {
                this.noTime = false;
                if (res.data[0].status + "" !== "12") this.baoshuiFlag = true;
              } else {
                this.noTime = true;
              }
            } else {
              this.noTime = false;
              if (res.data[0].status + "" !== "12") this.baoshuiFlag = true;
            }
          }
        }
      });
    },
    declareFun() {
      if (this.noTime) {
        return false;
      }
      // ! declareType;   1季报 2月报
      let year = this.date.year;
      let month = this.date.lastMounth;
      let month1 = this.date.thisMounth;
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        status: "12",
      };
      if (this.declareType === 1) {
        param.quarter = this.$config.status(month1);
        if (param.quarter === "4") {
          year = year - 1;
        }
        param.year = year;
      } else if (this.declareType === 2) {
        param.quarter = this.$config.status(month);
        if (month === "12") {
          month = "12";
          year = year - 1;
        }
        param.month = year + "" + month;
      } else {
        this.$store.commit("MyMessage", "未设置申报方式");
        return;
      }
      // param.quarter = this.$config.status(month1);
      updateBaoShuiStatus(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          // location.reload();
          this.$store.commit("MyMessage", "报税成功");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    submitBtn() {
      // return;
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        dataId: this.popupData.dataId,
        taxAmt: this.popupData.taxAmt,
        belongMonth: this.mouth,
      };
      if (
        this.popupData.taxPayType === "11" ||
        this.popupData.taxPayType === "12"
      ) {
        param.taxPayType = this.popupData.taxPayType;
      } else {
        param.taxPayType = "10";
        param.bankCardId = this.popupData.taxPayType;
      }

      taxDetailDeal(param).then((res) => {
        if (res.code === 0) {
          this.$store.commit("MyMessage", "申报成功");
          0;
          this.invitationShow = false;
          this.queryTaxDetail();
        } else {
          this.$store.commit("MyMessage", res.code);
        }
      });
    },
    queryEnterpriseInfo() {
      let param = {
        enterpriseId: this.enterpriseId,
      };
      queryEnterpriseInfo(param).then((res) => {
        // console.log(res);
        let _self = this.$store.state;
        this.declareType = res.data.enterpriseInfo.declareType;
        // if (this.declareType === 1) {
        //   console.log(this.date.thisMounth);
        //   if (
        //     this.date.thisMounth === "01" ||
        //     this.date.thisMounth === "04" ||
        //     this.date.thisMounth === "07" ||
        //     this.date.thisMounth === "10"
        //   ) {
        //     this.noTime = true;
        //   } else {
        //     this.noTime = false;
        //   }
        // } else {
        //   this.noTime = true;
        // }
        this.queryTaxDetail();
      });
    },
    queryEnterpriseInfoForUser() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        console.log("queryEnterpriseInfoForUser", res);
        this.selectList = res.data.bankList;
        if (this.selectList.length > 0) {
          this.value = this.selectList[0].bankcardId;
        }
        this.payment.forEach((element) => {
          this.selectList.push(element);
        });
      });
    },
    queryTaxDetail() {
      let year = this.date.year;
      let mouth = this.date.lastMounth;
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        // month: this.mouth,
      };
      param.quarter = this.$config.status(this.date.thisMounth);
 
      console.log(param);
      if (this.declareType === 1) {
        if (param.quarter === "4") {
         param.year = year - 1;
          this.taxReturnsText = year - 1  + "年第" + param.quarter + "季度";
        }else{
          param.year = year;
          this.taxReturnsText = year  + "年第" + param.quarter + "季度";
        }
      } else {
        param.year = year;
        param.month = this.mouth;
        this.taxReturnsText = year + "年" + mouth + "月";
      }
      this.queryBaoShuiStatusG(param);
      queryTaxDetail(param).then((res) => {
        if(res.code === 0){
          this.queryTaxDetailList = res.data;
          this.sum = 0;
          if( this.queryTaxDetailList.lenght > 0){

            this.queryTaxDetailList.forEach((element) => {
              this.sum += element.taxAmt;
              if (element.taxPayType === -1) {
                element.taxPayType = "";
              } else {
                element.taxPayType += "";
              }
              if (element.taxPayType === "10") {
                element.taxPayType = element.bankcardId + "";
              }
            });
          }
        }
       
      });
    },
    selectChange(item) {
      this.selectList.forEach((element) => {
        if (element.bankcardId === item.taxPayType) {
          item.bankName = element.bankName;
        }
      });
      if (item.taxPayType === "11" || item.taxPayType === "12") {
        item.bankcardId = "";
      } else {
        item.bankcardId = "10";
      }
    },
    shenbaoClick(item) {
      if (this.noTime) {
        return;
      }
      if (!this.baoshuiFlag) {
        return;
      }
      if (!item.taxPayType) {
        this.$store.commit("MyMessage", "请选择申报方式");
        return;
      }
      this.popupData = item;
      this.invitationShow = true;
    },
  },
};
</script>

<style lang="less" scoped>
.listHright{
  text-align: center;
  font-size: 20px;
  color: #999;
  padding: 200px 0px 300px;
}
.td-style {
  position: relative;
}
.add-icon {
  position: absolute;
  right: -4px;
  top: 0;
  .inner-add-icon {
    color: #4c8afc;
    position: relative;
    top: 23px;
    cursor: pointer;
  }
}
.taxReturns-body {
  min-height: calc(100% - 105px);
}
.plainStyle {
  width: 425px;
  height: 54px;
  background: #4c8afc;
  border: 1px solid #4c8afc;
  opacity: 1;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin: 30px auto 0;
  display: block;
  &:hover {
    background: #4c8afc;
    border: 1px solid #4c8afc;
    color: #ffffff;
  }
}
.invitation-main {
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff;
  width: 538px;
  margin-left: -269px;
  height: 370px;
  margin-top: -185px;
  border-radius: 12px;
  text-align: center;
}
.banklist-main {
  overflow-y: auto;
  width: calc(100% - 20px);
  padding: 20px 0px;
  margin: 0 auto;
  max-height: 340px;
  margin: 0;
  margin-left: 15px;
  height: auto;
  &.minWidth {
    width: calc(100% - 30px);
  }
}
.payment-p1 {
  font-size: 14px;
  margin: 40px 0 20px;
}
.payment-p2 {
  height: 50px;
  line-height: 50px;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 67px;
  color: #262626;
  span {
    color: #262626;
    font-size: 17px;
  }
}

.payment-text1 {
  width: 397px;
  margin: 20px auto 0;
  font-weight: bold;
  text-align: left;
}
.payment-text2 {
  width: 397px;
  margin: 7px auto 0;
  text-align: left;
}
.payment-btn1 {
  width: 397px;
  height: 44px;
  border: none;
  color: #999999;
  font-size: 14px;
  margin: 10px 0;
  &:hover {
    background: none;
    color: #999999;
  }
}

.taxReturns {
  height: 100%;
  // overflow: hidden;
  overflow-y: auto;
}
.reportForm-title {
  height: 45px;
  font-size: 32px;
  font-weight: bold;
  line-height: 45px;
  color: #262626;
  text-align: center;
  margin: 26px auto 16px;
  position: relative;
  width: 1328px;
  .title-span {
    position: absolute;
    left: 0;
    top: 0;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
  }
}
.taxReturns-main {
  width: 1328px;
  margin: 0 auto;
  .taxReturns-mian-box-l {
    width: calc(50% - 1px);
    float: left;
    &:first-child {
      border-right: 1px dashed #bbbbbb;
    }
    &:nth-child(2) {
      float: right;
      table {
        float: right;
      }
    }
    table {
      width: 610px;
      thead {
        width: 610px;
        height: 25px;
        background: #f8f9fa;
        th {
          font-size: 12px;
          font-weight: 400;
          color: #262626;
          height: 25px;
        }
      }
      tbody {
        tr {
          padding-top: 10px;
          td {
            height: 44px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            padding-top: 10px;
          }
        }
      }
      .table-w1 {
        width: 154px;
        display: inline-block;
        text-align: left;
        padding-left: 18px;
      }
      .table-w2 {
        display: inline-block;
        width: 110px;
        text-align: center;
        input {
          text-align: center;
          width: 100%;
          height: 44px;
          background: #ffffff;
          border: 1px solid #eeeeee;
          opacity: 1;
          border-radius: 2px;
          font-size: 20px;
        }
      }
      .table-w3 {
        display: inline-block;
        width: 110px;
        text-align: center;
        .el-input__inner {
          width: 190px;
          height: 44px;
          background: #f8f9fa;
          opacity: 1;
          border-radius: 2px;
        }
      }
      .table-w4 {
        width: 60px;
        display: inline-block;
        text-align: center;
        color: #4c8afc;
        cursor: pointer;
      }
      .table-w5 {
        width: 60px;
        display: inline-block;
        text-align: center;
        color: #262626;
      }
      .unClick {
        color: #999;
      }
    }
  }
}
.table-w1 {
  width: 154px;
  text-align: left;
  padding-left: 18px;
}
.table-w2 {
  width: 140px;
  text-align: center;
  input {
    text-align: center;
    width: 140px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    opacity: 1;
    border-radius: 2px;
    font-size: 20px;
  }
}
.table-w3 {
  width: 190px;
  text-align: center;
  .el-input__inner {
    width: 190px;
    height: 44px;
    background: #f8f9fa;
    opacity: 1;
    border-radius: 2px;
  }
}
.table-w4 {
  width: 60px;
  text-align: center;
  color: #4c8afc;
  cursor: pointer;
}
.table-w5 {
  width: 60px;
  text-align: center;
  color: #262626;
}
.hideStyle {
  background: #bbbbbb !important;
  border: 1px solid #bbbbbb !important;
  &:hover {
    background: #bbbbbb !important;
    border: 1px solid #bbbbbb !important;
  }
}
</style>