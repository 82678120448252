<template>
  <div class="height100">
    <ReportFormHeader
      v-if="headerShow"
      :mouth="mouth"
      @dateChange="dateChange"
      :headerData="headerData"
      @dowmReport="dowmReport"
    ></ReportFormHeader>
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-table">
          <div class="reportForm-table-center">
            <div class="reportForm-table-left">
              <table class="accountTable">
                <thead>
                  <tr>
                    <th>
                      <div
                        class="table-width1"
                        :class="{ visitStyle: visit === 'visit' }"
                      >
                        资产
                      </div>
                    </th>
                    <th><div class="table-width2">行次</div></th>
                    <th><div class="table-width3">期末余额</div></th>
                    <th><div class="table-width4">年初余额</div></th>
                    <th v-if="visit === 'visit' && reportIdMonth">
                      <div class="table-width5">操作</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(itemList, i1) in tableLeft" :key="i1 + '-2'">
                    <td>
                      <div class="table-width1">
                        <span
                          :class="{
                            tableColor1: itemList.level === 99,
                            tableColor2: itemList.level == '2',
                            tableColor3: itemList.level == '3',
                          }"
                          >{{ itemList.name }}</span
                        >
                      </div>
                    </td>
                    <td>
                      <div class="table-width2">{{ itemList.line }}</div>
                    </td>
                    <td>
                      <div class="table-width3">{{ itemList.monthAmount }}</div>
                    </td>
                    <td>
                      <div class="table-width4">{{ itemList.yearAmount }}</div>
                    </td>
                    <td v-if="visit === 'visit' && reportIdMonth">
                      <div class="table-width5">
                        <slot
                          v-if="
                            itemList.monthAmount !== '' &&
                            itemList.reportIdMonth !== '' &&
                            itemList.reportIdMonth !== undefined
                          "
                          ><span @click="reportClick(itemList)">修改</span>
                        </slot>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="reportForm-table-right">
              <table class="accountTable">
                <thead>
                  <tr>
                    <th>
                      <div
                        class="table-width1"
                        :class="{ visitStyle: visit === 'visit' }"
                      >
                        负债和所有者权益
                      </div>
                    </th>
                    <th><div class="table-width2">行次</div></th>
                    <th><div class="table-width3">期末余额</div></th>
                    <th><div class="table-width4">年初余额</div></th>
                    <th v-if="visit === 'visit' && reportIdMonth">
                      <div class="table-width5">操作</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(itemList, i1) in tableRight" :key="i1 + '-2'">
                    <td>
                      <div class="table-width1">
                        <span
                          :class="{
                            tableColor1: itemList.level === 99,
                            tableColor2: itemList.level == '2',
                            tableColor3: itemList.level == '3',
                          }"
                          >{{ itemList.name }}</span
                        >
                      </div>
                    </td>
                    <td>
                      <div class="table-width2">{{ itemList.line }}</div>
                    </td>
                    <td>
                      <div class="table-width3">{{ itemList.monthAmount }}</div>
                    </td>
                    <td>
                      <div class="table-width4">{{ itemList.yearAmount }}</div>
                    </td>
                    <td v-if="visit === 'visit' && reportIdMonth">
                      <div class="table-width5">
                        <slot
                          v-if="
                            itemList.monthAmount !== '' &&
                            itemList.reportIdMonth !== '' &&
                            itemList.reportIdMonth !== undefined
                          "
                          ><span @click="reportClick(itemList)">修改</span>
                        </slot>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
    <reprotPopup
      type="1"
      :reportItem="reportItem"
      v-if="reprotPopupFlag"
      @sucFun="sucFun"
      @closeFun="closeFun"
    ></reprotPopup>
  </div>
</template>
<script>
import ReportFormHeader from "./components/reportFormHeader.vue";
import reprotPopup from "./components/reprotPopup.vue";
import TheFooter from "@/components/TheFooter.vue";
import { assetliabilityReport, assetliabilityReportSmall } from "@/http/api";
export default {
  data() {
    return {
      headerShow: false,
      reprotPopupFlag: false,
      overflowStyle: false,
      value: "",
      mouth: "",
      downUrl: "",
      date: this.$config.getDate(),
      visit: sessionStorage.getItem("visit"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      accountingCriterion: sessionStorage.getItem("accountingCriterion"),
      company: sessionStorage.getItem("company"),
      token: sessionStorage.getItem("pc-tkn"),
      tableData: [],
      tableLeft: [],
      tableRight: [],
      num: 0,
      headerData: {
        left: true,
        right: true,
        title: "资产负债表",
        btn: 1,
        xiazai: "true",
      },
      reportItem: {},
      reportIdMonth: false,
    };
  },
  components: {
    ReportFormHeader,
    TheFooter,
    reprotPopup,
  },
  mounted() {
    // console.log(this.accountingCriterion);
    this.enterpriseId = sessionStorage.getItem("enterpriseId");
    let _route = this.$route;
    if (_route.query.mouth) {
      this.mouth = _route.query.mouth;
    } else {
      this.mouth = this.date.year + "-" + this.date.thisMounth;
    }
    this.init();
    this.$nextTick(() => {
      this.headerShow = true;
      let div = document.querySelector(".reportForm-main");
    });
  },

  methods: {
    // @sucFun="sucFun"
    // @closeFun="closeFun"
    sucFun() {
      this.closeFun();
      this.init();
    },
    closeFun() {
      this.reprotPopupFlag = false;
    },
    reportClick(itemList) {
      this.reportItem = itemList;
      this.reprotPopupFlag = true;
    },
    formatJson1(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    dowmReport() {
      let that = this;
      this.$store.commit("showLoading", "");
      const formData = new FormData();
      formData.append("enterpriseId", this.enterpriseId);
      formData.append("userId", this.userId);
      formData.append("month", this.mouth);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          console.log(data);
          const blob = new Blob([xhr.response], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.company + " " + this.mouth + "资产负债表.xlsx"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          }
          this.$store.commit("hideLoading", "");
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open("POST", url + that.downUrl);

      xhr.setRequestHeader("token", this.token);
      xhr.responseType = "blob";
      xhr.send(formData);
    },
    init() {
      if (this.accountingCriterion + "" == "1") {
        this.assetliabilityReportSmall();
        this.downUrl =
          "/manager/enterprise-small-report/assetliabilityReportDownload";
      } else {
        this.assetliabilityReport();
        this.downUrl = "/enterprise-report-query/assetliabilityReportDownload";
      }
    },
    dateChange(mouth) {
      this.$router.push({
        path: "/balance",
        query: {
          mouth: mouth,
        },
      });
      this.mouth = mouth;
      this.init();
    },
    assetliabilityReport() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
      };
      assetliabilityReport(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.tableData = res.data;
          this.tableLeft = [];
          this.tableRight = [];
          this.reportIdMonth = false;
          this.tableData.forEach((e, i) => {
            if (e.reportIdMonth && !this.reportIdMonth) {
              this.reportIdMonth = true;
            }
            e.listvo1.forEach((e1, i1) => {
              // let monthAmountTotal = e1.monthAmountTotal;
              // let yearAmountTotal = e1.yearAmountTotal;
              if (e1.reportIdMonth && this.reportIdMonth) {
                console.log(222);
                this.reportIdMonth = true;
              }
              let monthAmountTotal =
                e1.monthAmountTotal === "0.00" ? "0" : e1.monthAmountTotal;
              let yearAmountTotal =
                e1.yearAmountTotal === "0.00" ? "0" : e1.yearAmountTotal;
              if (
                e1.name === "流动资产" ||
                e1.name === "流动负债" ||
                e1.name === "非流动负债" ||
                e1.name === "非流动资产"
              ) {
                monthAmountTotal = "";
                yearAmountTotal = "";
              }
              if (i === 0) {
                this.tableLeft.push({
                  id: e1.id,
                  level: 99,
                  monthAmount: monthAmountTotal,
                  name: e1.name,
                  yearAmount: yearAmountTotal,
                });
              } else {
                this.tableRight.push({
                  id: e1.id,
                  level: 99,
                  monthAmount: monthAmountTotal,
                  name: e1.name,
                  yearAmount: yearAmountTotal,
                });
              }
              e1.list.forEach((e2, i2) => {
                if (e2.reportIdMonth && !this.reportIdMonth) {
                  this.reportIdMonth = true;
                }
                if (
                  e2.name === "其中：原材料" ||
                  e2.name === "流动资产合计" ||
                  e2.name === "非流动资产合计" ||
                  e2.name === "非流动负债合计"
                ) {
                  e2.level = "2";
                }
                if (e2.name === "资产合计") {
                  e2.level = "";
                }
                if (
                  e2.name === "在产品" ||
                  e2.name === "库存商品" ||
                  e2.name === "周转材料"
                ) {
                  e2.level = "3";
                }

                e2.monthAmount =
                  e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
                e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
                if (i === 0) {
                  this.tableLeft.push(e2);
                } else {
                  this.tableRight.push(e2);
                }
              });
            });
            if (i === 2) {
              this.tableRight.push({
                id: e.id,
                level: 99,
                monthAmount: "",
                name: e.name,
                yearAmount: "",
              });
              e.list.forEach((e2, i2) => {
                if (i === 0) {
                  if (
                    e2.name === "其中：原材料" ||
                    e2.name === "流动资产合计" ||
                    e2.name === "非流动资产合计" ||
                    e2.name === "资产总计"
                  ) {
                    e2.level = "2";
                  }
                  if (e2.name === "资产合计") {
                    e2.level = "";
                  }
                  if (
                    e2.name === "在产品" ||
                    e2.name === "库存商品" ||
                    e2.name === "周转材料"
                  ) {
                    e2.level = "3";
                  }
                  e2.monthAmount =
                    e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
                  e2.yearAmount =
                    e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
                  this.tableLeft.push(e2);
                } else {
                  this.tableRight.push(e2);
                }
              });
            }
            let no = "2";
            if (i > 1) {
              no = "";
            }

            let monthAmountTotal1 =
              e.monthAmountTotal === "0.00" ? "0" : e.monthAmountTotal;
            let yearAmountTotal1 =
              e.yearAmountTotal === "0.00" ? "0" : e.yearAmountTotal;
            if (i === 0) {
              if (this.accountingCriterion + "" === "1") {
                this.tableLeft.push({
                  id: e.id,
                  line: 30,
                  level: no,
                  monthAmount: monthAmountTotal1,
                  name: name,
                  yearAmount: yearAmountTotal1,
                });
              }
            } else {
              if (i === 2) {
                return;
              }
              let line = "";

              if (e.name === "负债合计") {
                line = "47";
              } else if (e.name === "所有者权益（或股东权益）合计") {
                line = "52";
              } else if (e.name === "负债和所有者权益（或股东权益）总计") {
                line = "53";
              }

              this.tableRight.push({
                id: e.id,
                level: no,
                line: line,
                monthAmount: monthAmountTotal1,
                name: e.name,
                yearAmount: yearAmountTotal1,
              });
              if (e.name === "负债合计") {
                for (let i = 1; i <= 2; i++) {
                  this.tableRight.push({});
                }
              }
            }
            if (e.name === "资产合计") {
              if (this.accountingCriterion + "" === "2") {
                for (let index = 0; index <= 8; index++) {
                  this.tableLeft.push({});
                }
                this.tableLeft.push({
                  id: e.id,
                  line: 30,
                  level: no,
                  monthAmount: monthAmountTotal1,
                  name: e.name,
                  yearAmount: yearAmountTotal1,
                });
              } else if (this.accountingCriterion + "" === "22") {
                for (let index = 0; index < 7; index++) {
                  this.tableLeft.push({});
                }
                this.tableLeft.push({
                  id: e.id,
                  line: 34,
                  level: 1,
                  monthAmount: monthAmountTotal1,
                  name: e.name,
                  yearAmount: yearAmountTotal1,
                });
              }
            }
          });

          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          if (res.msg === "请求参数不能为空!:企业id错误") {
            this.num++;
            if (this.num < 5) {
              this.init();
            } else {
              this.$store.commit("MyMessage", res.msg);
              this.overflowStyle = false;
            }
          } else {
            this.$store.commit("MyMessage", res.msg);
            this.overflowStyle = false;
          }
        }
        console.log(this.tableLeft);
      });
    },
    assetliabilityReportSmall() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
      };
      assetliabilityReportSmall(param).then((res) => {
        // console.log(res);
        // console.log("res", res.data);
        // return;
        if (res.code === 0) {
          this.tableData = res.data;
          this.tableLeft = [];
          this.tableRight = [];
          this.reportIdMonth = false;
          this.tableData.forEach((e, i) => {
            if (e.reportIdMonth && !this.reportIdMonth) {
              this.reportIdMonth = true;
            }
            e.listvo1.forEach((e1, i1) => {
              if (e1.reportIdMonth && !this.reportIdMonth) {
                this.reportIdMonth = true;
              }
              let monthAmountTotal =
                e1.monthAmountTotal === "0.00" ? "0" : e1.monthAmountTotal;
              let yearAmountTotal =
                e1.yearAmountTotal === "0.00" ? "0" : e1.yearAmountTotal;
              if (
                e1.name === "流动资产" ||
                e1.name === "流动负债" ||
                e1.name === "非流动负债" ||
                e1.name === "非流动资产"
              ) {
                monthAmountTotal = "";
                yearAmountTotal = "";
              }
              if (i === 0) {
                this.tableLeft.push({
                  id: e1.id,
                  level: 99,
                  monthAmount: monthAmountTotal,
                  name: e1.name,
                  yearAmount: yearAmountTotal,
                });
              } else {
                this.tableRight.push({
                  id: e1.id,
                  level: 99,
                  monthAmount: monthAmountTotal,
                  name: e1.name,
                  yearAmount: yearAmountTotal,
                });
              }
              e1.list.forEach((e2, i2) => {
                if (e2.reportIdMonth && !this.reportIdMonth) {
                  this.reportIdMonth = true;
                }
                e2.monthAmount =
                  e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
                e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
                if (
                  e2.name === "其中：原材料" ||
                  e2.name === "流动资产合计" ||
                  e2.name === "非流动资产合计" ||
                  e2.name === "非流动负债合计" ||
                  e2.name === "资产总计"
                ) {
                  e2.level = "2";
                }
                if (e2.name === "资产合计") {
                  e2.level = "";
                }
                if (
                  e2.name === "在产品" ||
                  e2.name === "库存商品" ||
                  e2.name === "周转材料"
                ) {
                  e2.level = "3";
                }
                // console.log(e2);
                if (i === 0) {
                  this.tableLeft.push(e2);
                } else {
                  this.tableRight.push(e2);
                }
              });
            });
            if (i === 2) {
              this.tableRight.push({
                id: e.id,
                level: 99,
                monthAmount: "",
                name: e.name,
                yearAmount: "",
              });
              e.list.forEach((e2, i2) => {
                e2.monthAmount =
                  e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
                e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
                if (i === 0) {
                  if (
                    e2.name === "其中：原材料" ||
                    e2.name === "流动资产合计" ||
                    e2.name === "非流动资产合计" ||
                    e2.name === "资产总计"
                  ) {
                    e2.level = "2";
                  }

                  if (
                    e2.name === "在产品" ||
                    e2.name === "库存商品" ||
                    e2.name === "周转材料"
                  ) {
                    e2.level = "3";
                  }
                  this.tableLeft.push(e2);
                } else {
                  this.tableRight.push(e2);
                }
              });
            }
            let monthAmountTotal1 =
              e.monthAmountTotal === "0.00" ? "0" : e.monthAmountTotal;
            let yearAmountTotal1 =
              e.yearAmountTotal === "0.00" ? "0" : e.yearAmountTotal;
            let no = "2";
            if (i > 1) {
              no = "";
            }
            if (e.name === "资产合计") {
              no = "";
            }
            if (i === 0) {
              this.tableLeft.push({
                id: e.id,
                line: 30,
                level: no,
                monthAmount: monthAmountTotal1,
                name: e.name,
                yearAmount: yearAmountTotal1,
              });
            } else {
              if (i === 2) {
                return;
              }
              let line = "";
              if (e.name === "负债合计") {
                line = "47";
              } else if (e.name === "所有者权益（或股东权益）合计") {
                line = "52";
              } else if (e.name === "负债和所有者权益（或股东权益）总计") {
                line = "53";
              }

              this.tableRight.push({
                id: e.id,
                level: no,
                line: line,
                monthAmount: monthAmountTotal1,
                name: e.name,
                yearAmount: yearAmountTotal1,
              });
              if (e.name === "负债合计") {
                for (let i = 1; i <= 6; i++) {
                  this.tableRight.push({ monthAmount: "" });
                }
              }
            }
          });
          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          if (res.msg === "请求参数不能为空!:企业id错误") {
            this.num++;
            if (this.num < 5) {
              this.init();
            } else {
              this.$store.commit("MyMessage", res.msg);
              this.overflowStyle = false;
            }
          } else {
            this.$store.commit("MyMessage", res.msg);
            this.overflowStyle = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
// balance
.accountTable {
  tbody {
    tr {
      &:nth-child(2n) {
        background: #f8f9fa;
      }
    }
  }
}
.table-width1 {
  width: 247px;
  padding-left: 18px;
  text-align: left;
}
.table-width5 {
  width: 50px;
  // padding-left: 18px;
  text-align: center;
  margin: 0 auto;
}
tbody {
  .table-width5 {
    color: #4c8afc;
    cursor: pointer;
  }
}
.table-width2 {
  width: 80px;
}
.table-width3 {
  width: 116px;
}
.table-width4 {
  width: 116px;
}
.tableColor1 {
  color: #f58e21;
}
.tableColor2 {
  color: #999999;
  padding-left: 28px;
}
.tableColor3 {
  color: #999999;
  padding-left: 68px;
}
</style>