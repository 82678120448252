<template>
  <div class="reportForm-table-center">
    <detailedSearch></detailedSearch>
    <table class="accountTable">
      <thead>
        <tr>
          <th><div class="table-width1">序号</div></th>
          <th><div class="table-width2">库存品类</div></th>
          <th><div class="table-width3">操作</div></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in dataBase" :key="index">
          <td>
            <div class="table-width1">{{ index + 1 }}</div>
          </td>
          <td>
            <div class="table-width2">{{ item.goodsKeyword }}</div>
          </td>
          <td>
            <div class="table-width3">
              <span class="operate operate-del" @click="deleteInventory(item)">
                删除
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import detailedSearch from "./detailedSearch.vue";
import { deleteInventory } from "@/http/api";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
    };
  },
  props: {
    dataBase: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    detailedSearch,
  },
  methods: {
    deleteInventory(item) {
      this.$confirm("此操作不可逆，是否进行删除", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let param = {
            enterpriseId: this.enterpriseId,
            goodsId: item.goodsId,
          };
          deleteInventory(param).then((res) => {
            if (res.code === 0) {
              this.$emit("closeFun", true);
              this.$store.commit("MyMessage", "操作成功");
            } else {
              this.$store.commit("MyMessage", res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.table-width1 {
  width: 62px;
}
.table-width2 {
  width: 1053px;
  padding: 5px 10px;
}

.table-width3 {
  width: 168px;
  padding: 5px 10px;
}
.operate {
  display: inline-block;
  padding: 0 17px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
.operate-edit {
  color: #4c8afc;
}
.operate-del {
  color: #bbbbbb;
}
</style>