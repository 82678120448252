<template>
  <div class="assetMain">
    <div class="assetMain-table">
      <table class="accountTable">
        <thead>
          <tr>
            <th rowspan="2"><div class="table-width1">编号</div></th>
            <th rowspan="2"><div class="table-width2">入账时间</div></th>
            <th rowspan="2"><div class="table-width2">所属部门</div></th>
            <th rowspan="2"><div class="table-width3">存放地点</div></th>
            <th colspan="2">资产类别</th>
            <th rowspan="2"><div class="">资产名称</div></th>
            <th rowspan="2"><div class="table-width5">规格型号</div></th>
            <th rowspan="2"><div class="table-width6">单位</div></th>
            <th rowspan="2"><div class="table-width5">资产单价</div></th>
            <th rowspan="2"><div class="table-width5">资产数量</div></th>
            <th rowspan="2"><div class="table-width5">资产原值</div></th>
            <th rowspan="2"><div class="table-width7">操作</div></th>
          </tr>
          <tr>
            <th><div class="table-width4">大类</div></th>
            <th><div class="table-width8">细类</div></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td class="table-width1">{{ index + 1 }}</td>
            <td class="table-width2">
              {{ item.assetRecordDate.split(" ")[0] }}
            </td>
            <td class="table-width2">{{ item.assetDepartmentName }}</td>
            <td class="table-width3">{{ item.stroePlace }}</td>
            <td class="table-width4">{{ item.assetKindName }}</td>
            <td class="table-width8">{{ item.assetKindSecName }}</td>
            <td class="table-width">{{ item.assetName }}</td>
            <td class="table-width5">{{ item.assetMode }}</td>
            <td class="table-width6">{{ item.assetUnit }}</td>
            <td class="table-width5">
              {{ (((item.assetInitPrice * 1) / item.assetNum) * 1).toFixed(2) }}
            </td>
            <td class="table-width5">
              {{ (item.assetNum * 1).toFixed(2) }}
            </td>
            <td class="table-width5">{{ item.assetInitPrice }}</td>
            <td class="table-width7">
              <!-- <slot v-if="item.isDeal === 1"> 已报废 </slot>
              <slot v-if="item.isDeal === 2"> 已变卖 </slot> -->
              <el-link
                class="elLink"
                type="primary"
                @click="handleDelete(index, item)"
                >处置</el-link
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <handleAssets
      v-if="popupShow"
      type="edit"
      :itemData="itemData"
      @closePopupFun="closePopupFun"
    ></handleAssets>
  </div>
</template>

<script>
import handleAssets from "./handleAssets";
export default {
  data() {
    return {
      value: "",
      popupShow: false,
      itemData: {},
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    handleAssets,
  },
  methods: {
    closePopupFun() {
      this.popupShow = false;
      this.$forceUpdate();
    },
    handleDelete(index, row) {
      this.popupShow = true;
      this.itemData = row;
    },
  },
};
</script>
<style lang="less" scoped>
.assetMain {
  width: 100%;
}
.table-width1 {
  width: 60px;
}
.table-width2 {
  width: 100px;
}
.table-width3 {
  width: 120px;
}
.table-width4 {
  width: 97px;
}
.table-width5 {
  width: 90px;
}
.table-width6 {
  width: 70px;
}
.table-width7 {
  width: 80px;
}
.table-width8 {
  width: 142px;
}
</style>