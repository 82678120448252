<template>
  <div class="height100">
    <!-- <ReportFormHeader
      v-if="headerShow"
      :mouth="mouth"
      @dateChange="dateChange"
      :headerData="headerData"
    ></ReportFormHeader> -->
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-tabs">
          <div
            class="reportForm-tabs-active is-top"
            :style="{
              transform: 'translateX(' + activeIndex + 'px)',
            }"
          ></div>
          <div class="reportForm-tabs-left">
            <div class="reportForm-tablist">
              <div
                class="reportForm-tabs-item"
                v-for="(item, i) in navList"
                :key="i"
                :id="'tabsId-' + i"
                :class="{ tabsCr: item.id === activeTab }"
                @click="tabsClick(item, i, 1)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="reportForm-tabs-right">
            <div class="reportForm-tabs-btn">
              <!-- <el-button type="primary">添加资产</el-button> -->
            </div>
          </div>
        </div>
        <div class="reportForm-table">
          <div class="reportForm-table-center">
            <!-- v-if="activeTab === 2 || activeTab === 4" -->
            <div class="detailed-search">
              日期
              <el-date-picker
                v-model="mouth"
                :editable="false"
                value-format="yyyy-MM"
                type="month"
                placeholder="选择月"
                @change="dateChange"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
            <div class="assetList-main">
              <slot v-if="tableData.length > 0">
                <gdzcmx v-if="activeTab === 1" :tableData="tableData"></gdzcmx>
                <gdzjmx v-if="activeTab === 2" :tableData="tableData"></gdzjmx>
                <wxzcmx v-if="activeTab === 3" :tableData="tableData"></wxzcmx>
                <wxtxmx v-if="activeTab === 4" :tableData="tableData"></wxtxmx>
                <div class="pages" v-if="totalPage > 1">
                  <el-pagination
                    background
                    layout="total, prev, pager, next, jumper"
                    :page-size="pages.pageSize"
                    :current-page.sync="pages.curren"
                    :total="pages.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[20, 50, 100]"
                  >
                  </el-pagination>
                </div>
              </slot>
              <NoRecord v-else></NoRecord>
            </div>
          </div>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
    <assetsAdd v-if="0"></assetsAdd>
  </div>
</template>
<script>
import ReportFormHeader from "../components/reportFormHeader.vue";
import gdzcmx from "./components/gdzcmxb.vue";
import gdzjmx from "./components/gdzczjmxb.vue";
import wxzcmx from "./components/wxzcmx.vue";
import wxtxmx from "./components/wxzctxmx.vue";
import assetsAdd from "./components/assetsAdd.vue";
import TheFooter from "@/components/TheFooter.vue";
import NoRecord from "@/components/NoRecord.vue";
import {
  gudingAssetList,
  gudingAssetZheJiuList,
  wuxingAssetList,
  wuxingAssetTanXiaoList,
} from "@/http/api";
export default {
  data() {
    return {
      headerShow: false,
      overflowStyle: false,
      date: this.$config.getDate(),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      mouth: "",
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      headerData: {
        left: false,
        right: false,
        title: "资产清单",
      },
      type: "10",
      moduleName: "yinszk",
      activeTab: 1,
      activeIndex: 0,
      navList: [
        {
          id: 1,
          title: "固定资产明细表",
          moduleName: "gdzcmx",
          width: 91,
          fun: gudingAssetList,
        },
        {
          id: 2,
          title: "固定资产折旧明细表",
          moduleName: "gdzczjmx",
          width: 117,
          fun: gudingAssetZheJiuList,
        },
        {
          id: 3,
          title: "无形资产明细表",
          moduleName: "wxzcmx",
          width: 91,
          fun: wuxingAssetList,
        },
        {
          id: 4,
          title: "无形资产摊销明细表",
          moduleName: "wxzctxmx",
          width: 117,
          fun: wuxingAssetTanXiaoList,
        },
      ],
      tableData: [],
      totalPage: 0,
      pages: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      oldDate: "",
      funName: gudingAssetList,
    };
  },
  components: {
    // ReportFormHeader,
    TheFooter,
    NoRecord,
    gdzcmx,
    gdzjmx,
    wxzcmx,
    wxtxmx,
    assetsAdd,
  },
  mounted() {
    this.mouth = this.date.year + "-" + this.date.thisMounth;
    this.oldDate = this.mouth;
    this.ajaxFun();
    this.$nextTick(() => {
      this.headerShow = true;
    });
    this.activeIndex = 27;
  },
  methods: {
    init() {
      this.oldDate = this.mouth;
      this.pages = {
        total: 0,
        current: 1,
        pageSize: 20,
      };
      this.ajaxFun();
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.ajaxFun();
      this.$config.scrollTop("reportFormMain");
    },
    handleCurrentChange(val) {
      this.pages.current = val;
      this.ajaxFun();
      this.$config.scrollTop("reportFormMain");
    },
    ajaxFun() {
      let param = {
        pageNum: this.pages.current,
        pageSize: this.pages.pageSize,
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth.split("-")[0] + this.mouth.split("-")[1],
        beginMonth: this.mouth.split("-")[0] + this.mouth.split("-")[1],
        endMonth: this.mouth.split("-")[0] + this.mouth.split("-")[1],
      };
      this.funName(param).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            // 旧代码
            // if (res.data.totalRow === 0) {
            //   if (this.oldDate !== this.mouth) {
            //     this.$store.commit("MyMessage", "暂无记录");
            //     this.mouth = this.oldDate;
            //     this.ajaxFun();
            //     this.$forceUpdate();
            //     this.overflowStyle = false;
            //     return;
            //   }
            // }
            // this.oldDate = this.mouth;
            this.pages.total = res.data.totalRow;
            this.totalPage = res.data.totalPage;
            this.tableData = res.data.list;
            this.$nextTick(() => {
              this.overflowStyle = this.$config.hasScrolled(
                "reportFormMain",
                "horizontal"
              );
            });
            // console.log(this.overflowStyle);
          } else {
            if (this.oldDate !== this.mouth) {
              this.$store.commit("MyMessage", "暂无记录");
              this.mouth = this.oldDate;
              this.ajaxFun();
              this.$forceUpdate();
              this.overflowStyle = false;
              return;
            }
          }
        }
      });
    },
    dateChange(mouth) {
      this.pages = {
        current: 1,
        pageSize: 20,
        total: 0,
      };
      this.ajaxFun();
    },
    tabsClick(item, index, increment) {
      if (increment > 0) {
        this.activeIndex = 0;
        this.navList.forEach((v, k) => {
          if (k < index) {
            this.activeIndex = this.activeIndex + v.width + 43;
          }
          if (k === index) {
            this.activeIndex = this.activeIndex + (v.width - 38) / 2;
          }
        });
      } else {
        this.activeIndex = 1328;
        this.navListRight.forEach((v, k) => {
          if (k > index) {
            this.activeIndex = this.activeIndex - v.width - 43;
          }
          if (k === index) {
            this.activeIndex = this.activeIndex - (v.width + 38) / 2;
          }
        });
      }
      this.moduleName = item.moduleName;
      this.activeTab = item.id;

      this.funName = item.fun;
      this.init();
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="less" scoped>
</style>
