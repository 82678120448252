<template>
  <div class="height100">
    <ReportFormHeader
      v-if="headerShow"
      :mouth="mouth"
      @dateChange="dateChange"
      @dowmReport="dowmReport"
      :headerData="headerData"
      jindu="true"
    ></ReportFormHeader>
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-table">
          <div class="reportForm-table-center">
            <componentTable
              v-if="tableShow"
              :tableData="tableData"
              @reportClick="reportClick"
              :flag="true"
            ></componentTable>
          </div>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
    <reprotPopup
      type="3"
      :reportItem="reportItem"
      v-if="reprotPopupFlag"
      @sucFun="sucFun"
      @closeFun="closeFun"
    ></reprotPopup>
  </div>
</template>
<script>
import ReportFormHeader from "./components/reportFormHeader.vue";
import componentTable from "./components/componentTable.vue";
import reprotPopup from "./components/reprotPopupC.vue";
import TheFooter from "@/components/TheFooter.vue";
import { cashFlowReport, cashFlowReportSmall } from "@/http/api";
export default {
  data() {
    return {
      headerShow: false,
      tableShow: false,
      reprotPopupFlag: false,
      overflowStyle: false,
      value: "",
      mouth: "",
      date: this.$config.getDate(),
      visit: sessionStorage.getItem("visit"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      accountingCriterion: sessionStorage.getItem("accountingCriterion"),
      company: sessionStorage.getItem("company"),
      token: sessionStorage.getItem("pc-tkn"),
      tableData: [],
      tableLeft: [],
      tableRight: [],
      headerData: {
        left: true,
        right: true,
        title: "现金流量表",
        btn: 1,
        xiazai: "true",
        url: "cashFlowQuarter",
      },
      reportItem: {},
      downUrl: "/enterprise-report-query/assetliabilityReportDownload",
    };
  },
  components: {
    ReportFormHeader,
    componentTable,
    TheFooter,
    reprotPopup,
  },
  mounted() {
    let _route = this.$route;
    if (_route.query.mouth) {
      this.mouth = _route.query.mouth;
    } else {
      this.mouth = this.date.year + "-" + this.date.thisMounth;
    }
    this.init();
    this.$nextTick(() => {
      this.headerShow = true;
    });
  },
  methods: {
    sucFun() {
      this.closeFun();
      this.init();
    },
    closeFun() {
      this.reprotPopupFlag = false;
    },
    reportClick(itemList) {
      this.reportItem = itemList;
      this.reprotPopupFlag = true;
    },
    dowmReport() {
      let that = this;
      this.$store.commit("showLoading", "");
      const formData = new FormData();
      formData.append("enterpriseId", this.enterpriseId);
      formData.append("userId", this.userId);
      formData.append("month", this.mouth);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          console.log(data);
          const blob = new Blob([xhr.response], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.company + " " + this.mouth + "现金流量表.xlsx"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          }
          this.$store.commit("hideLoading", "");
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open("POST", url + that.downUrl);
      xhr.setRequestHeader("token", that.token);
      xhr.responseType = "blob";
      xhr.send(formData);
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    init() {
      this.tableShow = false;
      if (this.accountingCriterion + "" == "1") {
        this.downUrl =
          "/manager/enterprise-small-report/cashFlowReportDownload";
        this.cashFlowReportSmall();
      } else {
        this.cashFlowReport();
        this.downUrl =
          "/manager/enterprise-report-query/cashFlowReportDownload";
      }
    },
    dateChange(mouth) {
      this.$router.push({
        path: "/cashFlow",
        query: {
          mouth: mouth,
        },
      });
      this.mouth = mouth;
      this.init();
    },
    cashFlowReport() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
      };
      cashFlowReport(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.tableData = [];
          res.data.forEach((e1, i1) => {
            let line = "";
            let monthAmountTotal =
              e1.monthAmountTotal === "0.00" ? "0" : e1.monthAmountTotal;
            let yearAmountTotal =
              e1.yearAmountTotal === "0.00" ? "0" : e1.yearAmountTotal;
            if (e1.name === "一、经营活动产生的现金流量：") {
              line = "1";
            } else if (e1.name === "二、投资活动产生的现金流量：") {
              line = "12";
            } else if (e1.name === "三、筹资活动产生的现金流量：") {
              line = "25";
            } else if (e1.name === "四、汇率变动对现金及现金等价物的影响") {
              line = "35";
            } else if (e1.name === "五、现金及现金等价物净增加额") {
              line = "36";
            } else if (e1.name === "五、现金及现金等价物净增加额") {
              line = "24";
            } else if (e1.name === "加：期初现金及现金等价物余额") {
              line = "38";
            } else if (e1.name === "六、期末现金及现金等价物余额") {
              line = "39";
            } else if (e1.name === "加：期初现金余额") {
              line = "22";
            } else if (e1.name === "五、期末现金余额") {
              line = "23";
            }

            if (e1.name !== "加：期初现金余额") {
              this.tableData.push({
                id: e1.id,
                level: 99,
                line: line,
                monthAmount: monthAmountTotal,
                name: e1.name,
                reportIdMonth: e1.reportIdMonth,
                yearAmount: yearAmountTotal,
              });
            }
            e1.list.forEach((e2, i2) => {
              e2.level = 2;
              e2.monthAmount = e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
              e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
              this.tableData.push(e2);
            });
          });

          this.tableShow = true;
          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          this.overflowStyle = false;
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    cashFlowReportSmall() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
      };
      cashFlowReportSmall(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.tableData = [];
          res.data.forEach((e1, i1) => {
            let line = "";
            let monthAmountTotal =
              e1.monthAmountTotal === "0.00" ? "0" : e1.monthAmountTotal;
            let yearAmountTotal =
              e1.yearAmountTotal === "0.00" ? "0" : e1.yearAmountTotal;
            if (e1.name === "一、经营活动产生的现金流量：") {
              monthAmountTotal = "";
              yearAmountTotal = "";
            } else if (e1.name === "二、投资活动产生的现金流量：") {
              monthAmountTotal = "";
              yearAmountTotal = "";
            } else if (e1.name === "三、筹资活动产生的现金流量：") {
              monthAmountTotal = "";
              yearAmountTotal = "";
            } else if (e1.name === "四、汇率变动对现金及现金等价物的影响") {
              line = "35";
            } else if (e1.name === "五、现金及现金等价物净增加额") {
              line = "36";
            } else if (e1.name === "五、现金及现金等价物净增加额") {
              line = "24";
            } else if (e1.name === "加：期初现金及现金等价物余额") {
              line = "38";
            } else if (e1.name === "六、期末现金及现金等价物余额") {
              line = "39";
            } else if (e1.name === "四、现金净增加额") {
              line = "20";
            } else if (e1.name === "六、期末现金及现金等价物余额") {
              line = "39";
            } else if (e1.name === "加：期初现金余额") {
              line = "22";
            } else if (e1.name === "五、期末现金余额") {
              line = "23";
            }
            this.tableData.push({
              id: e1.id,
              level: 99,
              line: line,
              monthAmount: monthAmountTotal,
              name: e1.name,
              reportIdMonth: e1.reportIdMonth,
              yearAmount: yearAmountTotal,
            });
            e1.list.forEach((e2, i2) => {
              e2.level = 2;
              e2.monthAmount = e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
              e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;

              if (e2.name !== "   加：期初现金余额") {
                this.tableData.push(e2);
              }
            });
          });

          this.tableShow = true;
          this.$nextTick(() => {
            // reportFormMain
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          this.overflowStyle = false;
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>
