<template>
  <div class="detailed-search">
    <div class="detailed-search-left">
      <div class="detailed-search-date">
        <el-date-picker
          v-model="month1"
          value-format="yyyy-MM"
          format="yyyy-MM"
          type="month"
          placeholder="选择月"
          @change="dateChange"
        >
        </el-date-picker>
      </div>
      <button
        class="reportForm-btn active noLeft"
        @click="addInventoryShow = true"
      >
        添加库存品类
      </button>
      <button class="reportForm-btn active" @click="goFun('/spphs')">
        商品平衡式
      </button>
      <button class="reportForm-btn active" @click="kcShow = true">
        添加库存
      </button>
      <button class="reportForm-btn active" @click="zgkcShow = true">
        添加暂估库存
      </button>
    </div>

    <div class="detailed-search-right">
      <el-input
        class="inputPrefix"
        placeholder="搜索行业、品类、商品名称"
        prefix-icon="el-icon-search"
        v-model="searchValue"
      >
      </el-input>
    </div>
    <addInventory
      v-if="addInventoryShow"
      @closeFun="closeFun"
      :options="options"
    ></addInventory>
    <addKc @closeFun="closeFun" v-if="kcShow"></addKc>
    <addZgKc @closeFun="closeFun" v-if="zgkcShow"></addZgKc>
  </div>
</template>

<script>
import addInventory from "./addInventory.vue";
import addKc from "./addKc.vue";
import addZgKc from "./addZgKc.vue";
export default {
  data() {
    return {
      addInventoryShow: false,
      kcShow: false,
      zgkcShow: false,
      searchValue: "",
      options: [],
      month1: "",
      date: this.$config.getDate(),
    };
  },
  props: {
    month: {
      type: String,
      default() {
        return "";
      },
    },
  },
  components: {
    addInventory,
    addKc,
    addZgKc,
  },
  mounted() {
    this.month1 = this.date.year + "-" + this.date.thisMounth;
    this.$forceUpdate();
  },
  methods: {
    dateChange() {
      this.$emit("dateChange", this.month1);
    },
    closeFun(flag) {
      this.addInventoryShow = false;
      this.kcShow = false;
      this.zgkcShow = false;
      if (flag) {
        this.init();
      }
    },
    goFun(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.detailed-search-date {
  float: left;
  .el-date-editor {
    margin-left: 0px;
    margin-right: 10px;
  }
}
.reportForm-btn {
  width: 102px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #f4f4f4;
  color: #4c8afc;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
  outline: none;
  cursor: pointer;

  &.active {
    background: #4c8afc;
    color: #fff;
    font-weight: bold;
  }
}
</style>