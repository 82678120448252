<template>
  <div class="popup_layer addAssets">
    <div class="popup_layer_bg"></div>
    <div class="popup_layer_content">
      <div class="popup_layer_content_top">
        添加期初
        <!-- <span class="popup_layer_close" type="info" @click="closeFun"
          >关闭</span
        > -->
      </div>
      <svg-icon
        icon-class="close-svg"
        @click="closeFun"
        class="close-svg"
      ></svg-icon>
      <div class="popup_layer_main webkit-scrollbar">
        <div class="popup_layer_box">
          <div class="popup_layer_box_left">科目：</div>
          <div class="popup_layer_box_right" id="elSelect">
            <el-select v-model="param.subjectCode" placeholder="请选择">
              <el-option
                v-for="item in subjectArray"
                :key="item.subjectCode"
                :label="item.title"
                :value="item.subjectCode"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="popup_layer_box">
          <div class="popup_layer_box_left">往来单位：</div>
          <div class="popup_layer_box_right" id="elSelect">
            <el-input
              v-model="param.title"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 9 }"
              placeholder="请输入"
            ></el-input>
          </div>
        </div>
        <div class="popup_layer_box">
          <div class="popup_layer_box_left">金额：</div>
          <div class="popup_layer_box_right">
            <el-input
              v-model="param.amt"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 9 }"
              placeholder="请输入"
            ></el-input>
          </div>
        </div>
        <div class="popup_layer_box">
          <div class="popup_layer_box_left">借贷：</div>
          <div class="popup_layer_box_right">
            <el-select v-model="param.debitOrCredit" placeholder="请选择">
              <el-option
                v-for="item in array1"
                :key="item.id"
                :label="item.text"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="popup_layer_box">
          <div class="popup_layer_box_left">时间：</div>
          <div class="popup_layer_box_right">
            <el-select v-model="param.zhouqi" placeholder="请选择">
              <el-option
                v-for="item in array"
                :key="item.id"
                :label="item.text"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="popup_layer_box">
          <div class="popup_layer_box_left"></div>
          <div class="popup_layer_box_right">
            <el-button type="primary" @click="submitClick"> 添加 </el-button>
            <!-- <el-button type="primary" v-if="type === 'edit'" @click="editClick">
              保存
            </el-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addCagData, getSubjectList } from "@/http/api";
export default {
  name: "addAssets",
  data() {
    return {
      toastShow: false,
      subjectPopupShow: false,
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      array: [
        {
          id: "termOne",
          text: "1~30天",
        },
        {
          id: "termTwo",
          text: "31~60天",
        },
        {
          id: "termThree",
          text: "61~90天",
        },
        {
          id: "termFour",
          text: "91~180天",
        },
        {
          id: "termFive",
          text: "181~1年",
        },
        {
          id: "termSix",
          text: "1年以上",
        },
      ],
      array1: [
        {
          id: 10,
          text: "借",
        },
        {
          id: 11,
          text: "贷",
        },
      ],
      dataList: {
        addType: "",
        assetCode: "",
        assetDepartmentId: "",
        assetDepartmentName: "",
        assetInitPrice: "",
        assetKind: "",
        assetKindName: "",
        assetKindSec: "",
        assetKindSecName: "",
        assetLife: "",
        assetMode: "",
        assetName: "",
        assetNum: "",
        assetShengyuLife: "",
        assetType: "",
        assetUnit: "",
        assetUsedLife: "",
        enterpriseId: sessionStorage.getItem("enterpriseId"),
        stroePlace: "",
        yijitiLeijiZhejiu: "",
        yiqiannianleijizhejiu: "",
        yjjczl: "",
        zhejiuFangfaId: "",
        zhejiuFangfaName: "",
      },
      value: "选项1",
      param: {
        enterpriseId: sessionStorage.getItem("enterpriseId"),
        type: "",
        amt: "",
        title: "",
        zhouqi: "",
        debitOrCredit: "",
        subjectCode: "",
      },
      EnterpriseType: sessionStorage.getItem("accountingCriterion"),
      toastText: "",
      direction: "",
      popupLeft: "",
      popupTop: "",
      popupBottom: "",
      zaiyao: "",
      debitSubjectList: [
        {
          value: "",
          id: "",
          amt: "",
          text: "",
          isSelf: 0,
          debitORCredit: 10,
          subjectCode: "",
          subjectName: "",
          show: false,
          show1: false,
        },
      ], //借方科目列表
      creditSubjectList: [
        {
          value: "",
          id: "",
          amt: "",
          text: "",
          isSelf: 0,
          debitORCredit: 11,
          subjectCode: "",
          subjectName: "",
          show: false,
          show1: false,
        },
      ], //贷方科目列表
      subjectData: {
        value: "",
        id: "",
        amt: "",
        text: "",
        isSelf: 0,
        subjectCode: "",
        subjectName: "",
        show: false,
        show1: false,
      },
      subjectType: "1",
      SubjectTypeList: [
        {
          title: "资产",
          name: "1",
          content: "1",
        },
        {
          title: "负债",
          name: "2",
          content: "2",
        },
        {
          title: "权益",
          name: "3",
          content: "3",
        },
        {
          title: "成本",
          name: "4",
          content: "4",
        },
        {
          title: "损益",
          name: "5",
          content: "5",
        },
        {
          title: "共同",
          name: "6",
          content: "6",
        },
        {
          title: "公式",
          name: "7",
          content: "7",
        },
      ],
      subjectItem: {},
      SubjectList: [],
      SubjectListCopy: [],
      subjectArray: [],
    };
  },
  props: {
    type: {
      type: String,
      default() {
        return "";
      },
    },
    itemData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    // this.enterpriseId = this.$route.query.id;
    // this.userId = this.$getCookie("jz-userId");
    if (this.itemData.addType === 0) {
      this.itemData.addType = "";
    }
    this.itemData.addType += "";
    if (this.type === "11" || this.type === "20" || this.type === "31") {
      this.getSubjectListNavFun(2);
    } else {
      this.getSubjectListNavFun(1);
    }
    console.log(this.type);
  },
  methods: {
    getSubjectListNavFun(subjectType) {
      let param = {
        accountingCriterion: this.EnterpriseType,
        pageSize: 10000,
        pageNum: 1,
        subjectType: subjectType,
      };
      getSubjectList(param).then((res) => {
        if (res.code === 0) {
          if (res.data) {
            let subjectCode = "";
            if (this.type === "10") {
              subjectCode = "1122";
            } else if (this.type === "11") {
              subjectCode = "2202";
            } else if (this.type === "20") {
              subjectCode = "2203";
            } else if (this.type === "21") {
              subjectCode = "1123";
            } else if (this.type === "30") {
              subjectCode = "1221";
            } else if (this.type === "31") {
              subjectCode = "2241";
            }
            this.subjectArray = [];
            res.data.list.forEach((element) => {
              element.subjectText =
                element.subjectCode + "     " + element.subjectName;
              if (
                element.subjectCode.indexOf(subjectCode) !== -1 &&
                (element.subjectName.indexOf("**") !== -1 ||
                  element.subjectName.indexOf("报销人员") !== -1)
              ) {
                element.title = element.subjectFullName.split("-**")[0];
                this.subjectArray.push(element);
              }
            });
            if (this.subjectArray.length > 0) {
              this.param.subjectCode = this.subjectArray[0].subjectCode;
            }
          }
          this.$forceUpdate();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    toast(str) {
      let v = this;
      v.toastText = str;
      v.toastShow = true;
      setTimeout(function () {
        v.toastShow = false;
      }, 2000);
    },
    closeFun() {
      // this.$emit("closePopupFun", "close");
      this.$emit("update:popupShow", false);
    },
    submitClick() {
      this.param.type = this.type;
      this.param.enterpriseId = this.enterpriseId;
      addCagData(this.param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          // this.$emit("closePopupFun", "add");
          this.$store.commit("MyMessage", "添加成功");
          location.reload();
        } else {
          this.toast(res.msg);
        }
      });
    },
    editClick() {
      this.$emit("closePopupFun", "add");
    },
  },
};
</script>

<style lang="less" scoped>
.popup_layer .popup_layer_content {
  // height: 500px;
  height: auto;
  max-height: 600px;
  min-height: 450px;
  margin-top: -250px;
  border-radius: 8px;
}
.popup_layer_content_top {
  padding: 28px 0;
  background: none !important;
  height: 20px !important;
  line-height: 20px !important;

  font-size: 18px !important;
  font-weight: 400 !important;
  color: #262626 !important;
  .popup_layer_close {
    top: 18px !important;
    right: 35px !important;
    color: #999999 !important;
    font-size: 16px !important;
    text-decoration: none !important;
    cursor: pointer;
    &:hover {
      text-decoration: none !important;
    }
  }
}
.popup_layer_box_left {
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  min-height: 1px;
}
.popup_layer
  .popup_layer_content
  .popup_layer_main
  .popup_layer_box
  .popup_layer_box_left {
  width: 130px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  text-align: right;
  position: relative;
  float: left;
  line-height: 40px;
}
</style>
<style lang="less">
#elSelect {
  .el-icon-arrow-up:before {
    content: "\e78f";
  }
}
.popup_input_width {
  width: 90px;
  margin: 0 10px;
  .el-input__inner {
    width: 100% !important;
    padding: 0 !important;
    text-align: center;
  }
}
.el-icon-style {
  font-weight: bold;
  cursor: pointer;

  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
.popup_input_text {
  font-size: 14px;
  color: #262626;
  margin-left: 20px;
  display: inline-block;
}
.popup_layer_main .el-textarea {
  .el-textarea__inner {
    // line-height: 30px !important;
    padding: 10px 15px 10px;
    resize: none;
    min-height: 42px !important;
    &::placeholder {
      font: 400 14px Arial;
    }
  }
}
</style>
