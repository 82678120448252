<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="closeFun"></div>
    <div class="popup-layer-content">
      <div class="popup-layer-close">
        <svg-icon icon-class="popup-close" @click="closeFun"></svg-icon>
      </div>
      <div class="reprot-popup">
        <div class="reprot-popup-title">{{ item.name }}</div>
        <div class="reprot-popup-box">
          <div class="reprot-popup-box-l">本月金额</div>
          <div class="reprot-popup-box-r">
            <el-input
              v-model="item.monthAmount"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
        <div class="reprot-popup-box">
          <div class="reprot-popup-box-l">本年累计金额</div>
          <div class="reprot-popup-box-r">
            <el-input
              v-model="item.yearAmount"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
        <div class="reprot-popup-btns" @click="saveReport">保 存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  modifyAssetliabilityReport,
  modifyCashFlowReport,
  modifyProfitReport,
} from "@/http/api";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      input: "",
      item: {},
    };
  },
  props: {
    reportItem: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default() {
        return "";
      },
    },
  },
  mounted() {
    console.log(this.reportItem);
    this.item = this.$config.cloneObj(this.reportItem);
  },
  methods: {
    closeFun() {
      this.$emit("closeFun");
    },
    saveReport() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        reportIdMonth: this.item.reportIdMonth,
        monthAmount: this.item.monthAmount,
        yearAmount: this.item.yearAmount,
      };
      if (this.type === "1") {
        this.modifyAssetliabilityReport(param);
      }
      if (this.type === "2") {
        this.modifyProfitReport(param);
      }
      if (this.type === "3") {
        this.modifyCashFlowReport(param);
      }
    },
    modifyAssetliabilityReport(param) {
      modifyAssetliabilityReport(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$emit("sucFun");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    modifyProfitReport(param) {
      modifyProfitReport(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$emit("sucFun");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    modifyCashFlowReport(param) {
      modifyCashFlowReport(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$emit("sucFun");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.popup-layer-content {
  min-height: 300px;
  border-radius: 5px;
}
.reprot-popup {
  width: 100%;
  .reprot-popup-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #262626;
    padding: 15px 0 15px;
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  }
  .reprot-popup-box {
    padding: 0 30px;
    margin-top: 30px;
    .reprot-popup-box-l {
      width: 60px;
      display: inline-block;
    }
    .reprot-popup-box-r {
      width: calc(100% - 70px);
      display: inline-block;
      margin-left: 10px;
    }
  }
  .reprot-popup-btns {
    width: 150px;
    margin: 0 auto;
    text-align: center;
    height: 34px;
    line-height: 34px;
    background: #4c8afc;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    margin-top: 35px;
    user-select: none;
    cursor: pointer;
  }
}
</style>