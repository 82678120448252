<template>
  <div class="reportForm">
    <div class="reportForm-nav">
      <ul>
        <li
          v-for="(item, index) in navList"
          :class="{
            active: item.url === navIndex,
            noClick: !item.url,
          }"
          :key="index"
          @click="navClick(item)"
        >
          {{ item.title }}
          <span v-if="item.url === navIndex" class="nav-border"></span>
        </li>
      </ul>
    </div>
    <div class="reportForm-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
export default {
  data() {
    return {
      navIndex: 1,
      month: "",
      navList: [
        {
          id: "/balance",
          title: "资产负债表",
          url: "/balance",
        },
        {
          id: 2,
          title: "利润表",
          url: "/profit",
        },
        {
          id: 3,
          title: "现金流量表",
          url: "/cashFlow",
        },
        {
          id: 4,
          title: "科目余额表",
          url: "/accountBalance",
        },
        {
          id: 5,
          title: "往来明细",
          url: "/detailed",
        },
        {
          id: 6,
          title: "库存明细",
          url: "",
          // url: "/kcmxb",
        },
        {
          id: 7,
          title: "资产清单",
          url: "/assetList",
        },
        {
          id: 8,
          title: "记账凭证",
          url: "/voucherList",
        },
        {
          id: 9,
          title: "公司票据",
          url: "/billList",
        },
      ],
    };
  },
  components: {},
  watch: {
    $route: {
      handler(val, oldval) {
        // console.log(val);
        this.navList.forEach((element) => {
          if (val.fullPath === element.url) {
            this.navIndex = element.url;
          }

          if (val.fullPath === "/profitQuarter") {
            this.navIndex = "/profit";
          }
          if (val.fullPath === "/cashFlowQuarter") {
            this.navIndex = "/cashFlow";
          }
          if (val.fullPath === "/kcpl") {
            this.navIndex = "/kcmxb";
          }
          if (val.fullPath === "/spphs") {
            this.navIndex = "/kcmxb";
          }
        });
      },
      // 深度观察监听
      deep: true,
    },
  },
  mounted() {
    /**
     * 刷新页面时，查询对应导航
     */
    let fullPath = this.$route.path;
    this.navList.forEach((element) => {
      if (fullPath === element.url) {
        this.navIndex = element.url;
      }
      if (fullPath === "/profitQuarter") {
        this.navIndex = "/profit";
      }
      if (fullPath === "/cashFlowQuarter") {
        this.navIndex = "/cashFlow";
      }
      if (fullPath === "/kcpl") {
        this.navIndex = "/kcmxb";
      }
      if (fullPath === "/spphs") {
        this.navIndex = "/kcmxb";
      }
    });
    this.$forceUpdate();
  },
  methods: {
    navClick(item) {
      if (item.url) {
        this.navIndex = item.url;
        this.$router.push({
          path: item.url,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.noClick {
  color: #999;
  cursor: default !important;
}
</style>