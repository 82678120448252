<template>
  <div class="balanceAdd">
    <div class="balanceAdd-bg"></div>
    <div class="balanceAdd-main">
      <div class="popup-layer-close">
        <svg-icon icon-class="popup-close" @click="closeFun"></svg-icon>
      </div>
      <div class="balanceAdd-main-header">添加科目</div>
      <div class="balanceAdd-main-centent">
        <div class="balanceAdd-box" style="margin-bottom: 15px">
          <input
            class="drawer-ipt"
            readonly
            v-model="subject"
            @click="selectTabShow()"
            type="text"
            placeholder="请选择"
          />

          <!-- 下拉tabs选项卡 -->
          <div v-if="active" class="select_tabs">
            <ul class="select_tabs_top">
              <li
                class="select_tabs_top-click"
                v-for="(topItem, index) in SubjectTypeList"
                :class="{ cr: topItem.content == topItemNun }"
                :key="index"
                @click="topItemSelected(topItem)"
              >
                <span class="select_tabs_top-click" ref="s_tabs_top">{{
                  topItem.title
                }}</span>
              </li>
            </ul>
            <ul class="select_tabs_list">
              <el-input
                class="balanec-ipt"
                @input="NewItems(subjectText)"
                v-model="subjectText"
                placeholder="请输入内容"
              ></el-input>
              <li
                v-for="(listItem, index) in SubjectList"
                :key="index"
                :class="{
                  inSelect: listItem.inSelect,
                  inSelectN: !listItem.inSelect,
                  inSelect1: listItem.subjectLevel === 2,
                  inSelect2: listItem.subjectLevel === 3,
                  inSelect3: listItem.subjectLevel === 4,
                  inSelect4: listItem.subjectLevel === 5,
                  inSelect5: listItem.subjectLevel === 6,
                }"
                @click="listItemSelected(listItem)"
              >
                <span
                  >{{ listItem.subjectCode }}{{ listItem.subjectName }}</span
                >
              </li>
              <li class="message" v-if="!SubjectList.length">暂无记录</li>
            </ul>
          </div>
        </div>
        <div
          class="balanceAdd-box"
          style="margin-bottom: 15px"
          v-if="valueShow"
        >
          <input
            class="drawer-ipt"
            v-model="value"
            type="text"
            placeholder="请输入"
          />
        </div>
        <div class="balanceAdd-box">
          <div class="balanceAdd-box-l"></div>
          <div class="balanceAdd-box-l">借方</div>
          <div class="balanceAdd-box-l">贷方</div>
        </div>
        <div class="balanceAdd-box">
          <div class="balanceAdd-box-l">期初余额</div>
          <div class="balanceAdd-box-l">
            <input type="text" v-model="dataList.qichuAmountDebit" />
          </div>
          <div class="balanceAdd-box-l">
            <input type="text" v-model="dataList.qichuAmountCredit" />
          </div>
        </div>
        <div class="balanceAdd-box">
          <div class="balanceAdd-box-l">本期发生</div>
          <div class="balanceAdd-box-l">
            <input type="text" v-model="dataList.benqiAmountDebit" />
          </div>
          <div class="balanceAdd-box-l">
            <input type="text" v-model="dataList.benqiAmountCredit" />
          </div>
        </div>
        <div class="balanceAdd-box">
          <div class="balanceAdd-box-l">本年累计</div>
          <div class="balanceAdd-box-l">
            <input type="text" v-model="dataList.yearAmountDebit" />
          </div>
          <div class="balanceAdd-box-l">
            <input type="text" v-model="dataList.yearAmountCredit" />
          </div>
        </div>
        <!-- <div class="balanceAdd-box">
          <div class="balanceAdd-box-l">期末余额</div>
          <div class="balanceAdd-box-l">
            <input type="text" v-model="dataList.qimoAmountCredit" />
          </div>
          <div class="balanceAdd-box-l">
            <input type="text" v-model="dataList.qimoAmountDebit" />
          </div>
        </div> -->
        <div class="balanceAdd-box">
          <el-button
            type="primary"
            style="width: 78%; margin-top: 30px; margin-left: 60px"
            @click="balanceReportAdd"
            >确认添加</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reportFormulaList,
  getSubjectList,
  addSubjectBalance,
} from "@/http/api";
export default {
  data() {
    return {
      value: "",
      subject: "",
      subjectText: "",
      active: false,
      valueShow: false,
      topItemNun: "",
      SubjectList: [],

      visit: sessionStorage.getItem("visit"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      accountingCriterion: sessionStorage.getItem("accountingCriterion"),
      company: sessionStorage.getItem("company"),
      token: sessionStorage.getItem("pc-tkn"),
      userId: sessionStorage.getItem("userId"),
      dataList: {
        qichuAmountDebit: "",
        qichuAmountCredit: "",
        benqiAmountDebit: "",
        benqiAmountCredit: "",
        yearAmountDebit: "",
        yearAmountCredit: "",
        qimoAmountDebit: "",
        qimoAmountCredit: "",
        fatherCode: "",
        subjectCode: "",
        subjectId: "",
        subjectName: "",
        subjectType: "1",
      },
      SubjectListCopy: [],
      SubjectTypeList: [
        {
          title: "资产",
          name: "1",
          content: "1",
        },
        {
          title: "负债",
          name: "2",
          content: "2",
        },
        {
          title: "权益",
          name: "3",
          content: "3",
        },
        {
          title: "成本",
          name: "4",
          content: "4",
        },
        {
          title: "损益",
          name: "5",
          content: "5",
        },
        {
          title: "共同",
          name: "6",
          content: "6",
        },
      ],
      arrayList: [],
      copy: [],
    };
  },
  props: {
    fatherList: {
      type: Array,
      default() {
        return [];
      },
    },
    mouth: {
      type: String,
      default() {
        return "";
      },
    },
  },
  mounted() {
    this.dataList.subjectType = "1";
    this.getSubjectListNavFun(this.dataList.subjectType);
    console.log(this.fatherList);
    this.copy = [];
    this.fatherList.forEach((element) => {
      this.copy.push(element.subjectCode);
    });
  },
  methods: {
    closeFun() {
      console.log("closeFun");
      this.$emit("update:showPopup", false);
    },
    selectTabShow() {
      this.active = true;
      console.log("closeFun");
    },
    balanceReportAdd() {
      if (this.valueShow) {
        this.dataList.subjectDesc = this.value;
        this.dataList.subjectName = this.value;
      }
      // let qichu =
      //   this.dataList.qichuAmountDebit * 1 -
      //   this.dataList.qichuAmountCredit * 1;
      // let benqi =
      //   this.dataList.benqiAmountDebit * 1 -
      //   this.dataList.benqiAmountCredit * 1;
      // if (qichu > 0) {
      //   this.dataList.qichuAmountDebit = Math.abs(qichu).toFixed(2);
      //   this.dataList.qichuAmountCredit = "";
      // } else {
      //   this.dataList.qichuAmountCredit = Math.abs(qichu).toFixed(2);
      //   this.dataList.qichuAmountDebit = "";
      // }
      // 期初余额（借-贷）+本期发生（借-贷）
      // this.dataList.qimoAmountDebit =
      //   qichu * 1 + benqi * 1 > 0
      //     ? Math.abs(qichu * 1 + benqi * 1).toFixed(2)
      //     : "";
      // this.dataList.qimoAmountCredit =
      //   qichu * 1 + benqi * 1 <= 0
      //     ? Math.abs(qichu * 1 + benqi * 1).toFixed(2)
      //     : "";
      let param = this.$config.cloneObj(this.dataList);
      delete param.subjectTextShow;
      console.log(param);
      param.userId = this.userId;
      param.enterpriseId = this.enterpriseId;
      param.enterpriseId = this.enterpriseId;
      param.reportMonth = this.mouth;
      param.qichuAmountDebit *= 1;
      param.qichuAmountCredit *= 1;
      param.qimoAmountDebit *= 1;
      param.qimoAmountCredit *= 1;
      param.benqiAmountDebit *= 1;
      param.benqiAmountCredit *= 1;
      param.yearAmountDebit *= 1;
      param.yearAmountCredit *= 1;
      // this.$emit("balanceReportAdd", this.dataList, this.arrayList);
      addSubjectBalance(param).then((res) => {
        if (res.code === 0) {
          this.$store.commit("MyMessage", "添加成功");
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    NewItems(subjectText) {
      var _this = this;
      var NewItems = [];
      this.SubjectListCopy.map(function (item) {
        if (
          item.subjectName.search(subjectText) != -1 ||
          (item.subjectCode + item.subjectName).search(subjectText) != -1 ||
          item.subjectName.search(subjectText) != -1
        ) {
          NewItems.push(item);
        }
      });
      _this.SubjectList = NewItems;
    },
    topItemSelected(topItem) {
      this.subjectText = "";
      this.dataList.subjectType = topItem.content;
      this.topItemNun = topItem.content;

      this.getSubjectListNavFun(topItem.content);
    },
    addCode(a, b) {
      let n = this.copy.indexOf(a + b);
      console.log(n);
      if (n === -1) {
        return a + b;
      }
      b = b * 1 + 1;
      b = b < 10 ? "0" + b : b + "";
      return this.addCode(a, b);
    },
    fatherCodeSelect(code) {
      return fatherCode;
    },
    listItemSelected(listItem) {
      let that = this;
      let copy = this.$config.cloneObj(listItem);
      if (listItem.inSelect) {
        // if (listItem.subjectName === "valueShow") {
        if (
          listItem.subjectName.indexOf("**") !== -1 ||
          listItem.subjectName.indexOf("报销人员") !== -1
        ) {
          let a = copy.subjectCode.substring(0, copy.subjectCode.length - 2);
          let b = copy.subjectCode.substring(copy.subjectCode.length - 2);
          let c = this.addCode(a, b);
          copy.subjectCode = c;
          copy.subjectTextShow = true;

          this.valueShow = true;
        } else {
          this.valueShow = false;
        }
        this.arrayList = [];
        this.SubjectList.forEach((element, k) => {
          if (listItem.subjectCode.indexOf(element.subjectCode) !== -1) {
            this.arrayList.push(element);
          }
        });
        if (this.valueShow) {
          this.subject = listItem.subjectCode;
        } else {
          this.subject = listItem.subjectCode + listItem.subjectName;
        }
        this.dataList.fatherCode = listItem.fatherCode;
        this.dataList.subjectCode = listItem.subjectCode;
        this.dataList.subjectName = listItem.subjectName;
        this.dataList.subjectId = listItem.subjectId;
        this.active = false;
        this.subjectCode = listItem.subjectCode;
        if (copy.subjectTextShow) {
          this.dataList.subjectTextShow = true;
          this.dataList.subjectType = 1;
        } else {
          this.dataList.subjectType = 0;
        }
        this.$forceUpdate();
      }
    },

    getSubjectListNavFun(subjectType) {
      let param = {
        accountingCriterion: this.accountingCriterion,
        pageSize: 10000,
        pageNum: 1,
        subjectType: subjectType,
      };
      getSubjectList(param).then((res) => {
        console.log("查询科目列表接口", res);
        if (res.data) {
          this.SubjectList = res.data.list;
          let code = "";
          code = this.SubjectList[0].subjectCode;
          let date = [];
          this.SubjectList.forEach((element, key) => {
            element.inSelect = false;
            element.subjectId = element.id;
            let nextSubjectLevel = "";
            let nextCode = "";
            if (this.SubjectList.length - 1 > key) {
              nextSubjectLevel = this.SubjectList[key + 1].subjectLevel;
            }
            if (this.SubjectList.length - 1 > key) {
              nextCode = this.SubjectList[key + 1].fatherCode;
            }
            if (element.subjectLevel >= nextSubjectLevel) {
              element.inSelect = true;
            } else {
              element.inSelect = false;
            }
          });
          this.SubjectListCopy = this.$config.cloneObj(this.SubjectList);
        }
      });
    },
    reportFormulaListFun() {
      let param = {
        pageSize: 10000,
        pageNum: 1,
      };
      reportFormulaList(param).then((res) => {
        // console.log("查询科目列表接口", res);
        if (res.data) {
          this.SubjectList = res.data.list;

          console.log("...", this.SubjectList);
          this.SubjectList.forEach((d) => {
            d.subjectId = d.id;
            console.log(111);
          });
          console.log(this.SubjectList);

          this.SubjectListCopy = this.$config.cloneObj(this.SubjectList);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.balanceAdd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  .balanceAdd-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.6);
  }
  .balanceAdd-main {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 500px;
    height: 500px;
    margin-left: -250px;
    margin-top: -250px;
    background: #fff;
    .balanceAdd-main-header {
      height: 50px;
      line-height: 50px;
      // background: #f4f4f4;
      font-size: 18px;
      font-weight: 400;
      color: #262626;
      text-align: center;
      position: relative;
      a {
        position: absolute;
        right: 0;
        padding: 0 18px;
      }
    }
    .balanceAdd-main-centent {
      margin-top: 30px;
      text-align: center;
    }
    .balanceAdd-box {
      width: 400px;
      margin: 0 auto 10px;
      display: inline-block;
      position: relative;
      .balanceAdd-box-l {
        display: inline-block;
        width: calc(100% / 3);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        color: #262626;
        input {
          background: #f4f4f4;
          width: 90%;
          height: 40px;
          text-align: center;
        }
      }
      .select_tabs {
        width: 100%;
        height: 274px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        border-top: none;
        overflow-y: hidden;
        border-radius: 4px;
        position: absolute;
        top: 47px;
        left: 0;
        z-index: 1;
      }

      .select_tabs_top {
        display: flex;
        li {
          height: 40px;
          flex: 1;
          border-bottom: 1px solid #dbdbdb;
          border-right: 1px solid #dbdbdb;
          cursor: pointer;
          span:hover {
            color: rgb(56, 123, 224);
          }
          &:last-child {
            border-right: none;
          }
          span {
            display: inline-block;
            width: 100%;
            height: 100%;
            line-height: 40px;
            text-align: center;
          }
          &.cr {
            background: #1a73e8;
            color: #fff;
            span {
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
      .select_tabs_list {
        height: calc(100% - 60px);
        padding: 10px;
        overflow-y: auto;
        li {
          text-align: left;
          margin: 3px 0;
          cursor: pointer;
          span {
            color: #666;
          }
        }
        li:hover {
          background-color: #ddd;
        }
      }
    }
    .drawer-ipt {
      width: calc(100% - 27px);
      height: 100%;
      float: left;
      padding: 12px 15px 12px 12px;
      border: 1px solid #e3e5e6;
      color: #262626;
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
      text-align: left;

      &::placeholder {
        font-weight: 100;
      }
    }
  }
}
</style>
<style lang="less">
.balanec-ipt {
  width: 100%;
  .el-input__inner {
    width: 100% !important;
  }
}
.inSelect {
  span {
    color: #262626 !important;
    // padding-left: 10px;
  }
}
.inSelect1 {
  span {
    padding-left: 20px;
  }
}
.inSelect2 {
  span {
    padding-left: 30px;
  }
}
.inSelect3 {
  span {
    padding-left: 40px;
  }
}
.inSelect4 {
  span {
    padding-left: 50px;
  }
}
.inSelect5 {
  span {
    padding-left: 60px;
  }
}
.inSelectN {
  span {
    color: #999 !important;
  }
}
</style>