<template>
  <div class="height100">
    <!-- <ReportFormHeader
      v-if="headerShow"
      :mouth="showMouth"
      @dateChange="dateChange"
      :headerData="headerData"
    ></ReportFormHeader> -->
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-tabs">
          <div
            class="reportForm-tabs-active is-top"
            :style="{
              transform: 'translateX(' + activeIndex + 'px)',
            }"
          ></div>
          <div class="reportForm-tabs-left">
            <div class="reportForm-tablist">
              <div
                class="reportForm-tabs-item"
                v-for="(item, i) in navList"
                :key="i"
                :id="'tabsId-' + i"
                :class="{ tabsCr: item.id === activeTab }"
                @click="tabsClick(item, i, 1)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="reportForm-tabs-right"></div>
        </div>
        <div class="reportForm-table">
          <div class="reportForm-table-center">
            <div class="detailed-search">
              日期
              <el-date-picker
                v-model="mouth"
                :editable="false"
                value-format="yyyy-MM"
                type="month"
                placeholder="选择月"
                @change="dateChange"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
              <span
                @click="popupShow = true"
                v-if="visit === 'visit'"
                class="addClass"
                >添加记录</span
              >
            </div>
            <slot v-if="tableData.length > 0">
              <table class="accountTable">
                <thead>
                  <tr>
                    <th><div class="deal-width1">序号</div></th>
                    <th><div class="deal-width2">单位名称</div></th>
                    <th><div class="deal-width3">1~30天</div></th>
                    <th><div class="deal-width3">31~60天</div></th>
                    <th><div class="deal-width3">61~90天</div></th>
                    <th><div class="deal-width3">91~180天</div></th>
                    <th><div class="deal-width3">181~1年</div></th>
                    <th><div class="deal-width3">1年以上</div></th>
                    <th><div class="deal-width3">合计</div></th>
                    <th v-if="showMouth === ''">
                      <div class="deal-width4">操作</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <slot v-for="(item, index) in tableData">
                    <tr
                      :key="index + '-1'"
                      @click="isShowOneClick(item, index)"
                    >
                      <td>
                        <span class="deal-span1">{{ index + 1 }}</span>
                      </td>
                      <td>
                        <span class="deal-span2">{{ item.title }}</span>
                      </td>
                      <td>
                        <span class="deal-span3">{{
                          item.comeAndGo.termOne
                        }}</span>
                      </td>
                      <td>
                        <span class="deal-span3">{{
                          item.comeAndGo.termTwo
                        }}</span>
                      </td>
                      <td>
                        <span class="deal-span3">{{
                          item.comeAndGo.termThree
                        }}</span>
                      </td>
                      <td>
                        <span class="deal-span3">{{
                          item.comeAndGo.termFour
                        }}</span>
                      </td>
                      <td>
                        <span class="deal-span3">{{
                          item.comeAndGo.termFive
                        }}</span>
                      </td>
                      <td>
                        <span class="deal-span3">{{
                          item.comeAndGo.termSix
                        }}</span>
                      </td>
                      <td>
                        <span class="deal-span3">{{
                          item.comeAndGo.total
                        }}</span>
                      </td>
                      <td v-if="showMouth === ''">
                        <div class="deal-span4">
                          <a
                            href="javascript:"
                            style="color: #4c8afc; padding: 0 5px"
                            @click.stop="dealClick(item)"
                            >处置</a
                          >
                        </div>
                      </td>
                    </tr>
                    <slot v-if="item.isShow">
                      <tr
                        v-for="(item1, index1) in item.tableDetail"
                        :key="index + '-' + index1 + '-2'"
                      >
                        <td
                          colspan="5"
                          class="td-color"
                          style="text-align: right; padding-right: 10px"
                        >
                          {{ item1.subjectName }}
                        </td>
                        <td class="td-color" colspan="2">
                          {{ item1.createTime }}
                        </td>
                        <td class="td-color">
                          <slot v-if="item1.debitORCredit === 11">贷</slot>
                          <slot v-else>借</slot>
                        </td>
                        <td class="td-color">
                          <slot v-if="item1.debitORCredit === 11">{{
                            item1.creditPrice
                          }}</slot>
                          <slot v-else>{{ item1.debitPrice }}</slot>
                        </td>
                        <td class="td-color">
                          <span style="color: #bbb" v-if="item1.isQichu === 1"
                            >期初</span
                          >
                        </td>
                      </tr>
                      <tr :key="index + '-3'" v-if="item.pages">
                        <td class="td-color" colspan="10">
                          <div
                            class="load-more"
                            @click="isShowClick(item, index, true)"
                          >
                            加载更多
                          </div>
                        </td>
                      </tr>
                    </slot>
                  </slot>
                </tbody>
              </table>
              <el-pagination
                v-if="totalPage > 1"
                style="margin-top: 20px; text-align: right"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageNum"
                :page-sizes="[20, 50, 100]"
                :page-size="pages.pageSize"
                layout="total,  prev, pager, next, jumper"
                :total="pages.total"
                >>
              </el-pagination>
            </slot>

            <NoRecord v-else></NoRecord>
            <!-- <el-pagination
              v-if="totalPage > 1"
              style="margin-top: 20px; text-align: right"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pages.pageNum"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pages.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pages.total"
              >>
            </el-pagination> -->
          </div>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
    <div class="popup-layer" v-if="invitationShow">
      <div class="popup-layer-bg" @click="invitationShow = false"></div>
      <div class="invitation-main">
        <div class="popup-layer-close" @click="invitationShow = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="banklist-main">
          <p class="payment-p1">{{ deal.title }}</p>
          <p class="payment-p2"><span>¥</span>{{ deal.comeAndGo.total }}</p>
          <div class="dealType-main webkit-scrollbar">
            <slot v-for="(item, index) in dealTypeList">
              <div
                class="dealType-main-box"
                :key="index"
                @click="dealTypeClick(item, index)"
                :class="{ active: item.value === active }"
              >
                <!-- v-if="item.memo.indexOf('/个人名下') === -1" -->
                {{ item.memo }}
                <span
                  class="el-icon-arrow-right arrowTop activeS"
                  v-if="
                    item.memo.indexOf('用其他收款冲抵收入') !== -1 ||
                    item.memo.indexOf('用已预付的钱冲抵货款') !== -1 ||
                    item.memo.indexOf('用已预收的钱冲抵经营收入') !== -1 ||
                    item.memo.indexOf('用已预收的钱冲抵其他收款') !== -1 ||
                    item.memo.indexOf('用已预付的钱冲抵货款') !== -1 ||
                    item.memo.indexOf('用已预付的钱冲抵报销款') !== -1 ||
                    item.memo.indexOf('用已预收的钱冲抵其他收款') !== -1 ||
                    item.memo.indexOf('用已预付的钱冲抵报销款') !== -1 ||
                    item.memo.indexOf('/个人名下') !== -1 ||
                    item.memo.indexOf('公司/个人') !== -1
                  "
                ></span>
                <div style="color: #4c8afc">{{ item.company }}</div>
              </div>
            </slot>
          </div>
          <div
            class="company-main webkit-scrollbar"
            v-if="queryTitelList.length > 0 && companyShow"
          >
            <!-- @mouseleave="companyShow = false" -->
            <span class="arrow-up table-popup-el"></span>
            <slot v-for="(item, index) in queryTitelList">
              <div
                class="company-main-box"
                v-if="deal.title !== item.memo"
                :key="index"
                @click="titleClick(item)"
              >
                {{ item.memo }}
              </div>
            </slot>
          </div>
          <div class="payment-text2" :class="{ active1: company }" v-if="0">
            <span
              class="payment-text2-span"
              @mouseover="companyShow = true"
              :class="{ active: company }"
            >
              把该笔资金合并到
              <slot v-if="!company">其他公司名下</slot>
              <slot v-if="company"><br />{{ company }}</slot>
            </span>
            <span
              class="el-icon-arrow-right"
              :class="{ arrowTop: company, active: company }"
            ></span>
            <div
              class="company-main webkit-scrollbar"
              v-if="queryTitelList.length > 0 && companyShow"
              @mouseleave="companyShow = false"
            >
              <span class="arrow-up table-popup-el"></span>
              <slot v-for="(item, index) in queryTitelList">
                <div
                  class="company-main-box"
                  v-if="deal.title !== item.memo"
                  :key="index"
                  @click="titleClick(item)"
                >
                  {{ item.memo }}
                </div>
              </slot>
            </div>
          </div>
        </div>
        <div class="clear"></div>
        <div class="payment-main">
          <div class="payment-btns" style="margin-top: 40px">
            <el-button
              type="primary"
              style="width: 260px; height: 44px; font-size: 14px"
              @click="dealFun"
            >
              确定
            </el-button>
            <br />
          </div>
        </div>
      </div>
    </div>
    <addAssets
      v-if="popupShow"
      :type="type"
      v-bind:popupShow.sync="popupShow"
      @closePopupFun="closePopupFun"
    ></addAssets>
  </div>
</template>
<script>
import ReportFormHeader from "./components/reportFormHeader.vue";
import addAssets from "./components/addAssets.vue";
import TheFooter from "@/components/TheFooter.vue";
import NoRecord from "@/components/NoRecord.vue";
import {
  accountsPayable,
  accountsReceivable,
  advanceReceipts,
  prepayment,
  accountsPayableOther,
  accountsReceivableOther,
  queryDealType,
  queryTitel,
  deal,
  accountsPayableOtherDetail,
  accountsPayableDetail,
  accountsReceivableDetail,
  accountsReceivableOtherDetail,
  advanceReceiptsDetail,
  prepaymentDetail,
} from "@/http/api";
import SvgIcon from "../../components/SvgIcon.vue";
export default {
  data() {
    return {
      popupShow: false,
      companyShow: false,
      invitationShow: false,
      headerShow: false,
      overflowStyle: false,
      date: this.$config.getDate(),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      visit: sessionStorage.getItem("visit"),
      mouth: "",
      showMouth: "",
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      headerData: {
        left: false,
        right: false,
        title: "往来明细",
      },
      type: "10",
      moduleName: "yinszk",
      activeTab: 2,
      activeIndex: 0,
      totalPage: 0,
      index: 0,
      navList: [
        {
          id: 1,
          title: "应收账款",
          moduleName: "yinszk",
          width: 52,
          listFun: accountsReceivable,
          detailsFun: accountsReceivableDetail,
          type: "10",
        },
        {
          id: 2,
          title: "应付账款",
          moduleName: "yinfzk",
          width: 52,
          listFun: accountsPayable,
          detailsFun: accountsPayableDetail,
          type: "11",
        },

        {
          id: 3,
          title: "预收账款",
          moduleName: "yuszk",
          width: 52,
          listFun: advanceReceipts,
          detailsFun: advanceReceiptsDetail,
          type: "20",
        },
        {
          id: 4,
          title: "预付账款",
          moduleName: "yufzk",
          width: 52,
          listFun: prepayment,
          detailsFun: prepaymentDetail,
          type: "21",
        },
        {
          id: 5,
          title: "其他应收账款",
          moduleName: "qtyinszk",
          width: 78,
          listFun: accountsReceivableOther,
          detailsFun: accountsReceivableOtherDetail,
          type: "30",
        },
        {
          id: 6,
          title: "其他应付账款",
          moduleName: "qtyinfzk",
          width: 78,
          listFun: accountsPayableOther,
          detailsFun: accountsPayableOtherDetail,
          type: "31",
        },
      ],
      pages: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      tableData: [],
      oldDate: "",
      listFun: accountsReceivable,
      detailsFun: accountsReceivableDetail,

      params: {},
      dealItem: {},
      dealType: "",
      detailsIndex: "",
      dealTypeList: [],
      queryTitelList: [],
      deal: {
        title: "",
        comeAndGo: {},
      },
      active: "",
      company: "",
      ajaxValue: "",
      bankValue: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.query.show === undefined) {
        location.reload();
      }
    },
  },
  components: {
    // ReportFormHeader,
    TheFooter,
    NoRecord,
    SvgIcon,
    addAssets,
  },
  mounted() {
    let _route = this.$route;

    if (_route.query.mouth) {
      this.mouth = _route.query.mouth;
      this.showMouth = _route.query.mouth;
    } else {
      this.mouth = this.date.year + "-" + this.date.thisMounth;
    }
    this.oldDate = this.mouth;
    if (_route.query.activeIndex) {
      this.activeIndex = _route.query.activeIndex - 1;
      this.params = this.navList[_route.query.activeIndex - 1];
      console.log(this.params);
      this.type = this.params.type;
    } else {
      this.params = this.navList[0];
    }
    this.navTab(this.params, this.params.id - 1, 1);
    this.$nextTick(() => {
      this.headerShow = true;
    });
    this.init();
    // this.activeIndex = 8;

    this.$forceUpdate();
  },
  methods: {
    closePopupFun() {
      this.init();
    },
    dealTypeClick(item, index) {
      this.index = index;
      let typeNum = this.params.type;
      this.dealItem = item;
      console.log();
      if (
        item.memo.indexOf("/个人名下") !== -1 ||
        item.memo.indexOf("公司/个人") !== -1
      ) {
        this.queryTitel(this.deal, typeNum);
      } else if (item.memo.indexOf("用其他收款冲抵收入") !== -1) {
        typeNum = "30";
        this.queryTitel(this.deal, typeNum);
      } else if (item.memo.indexOf("用已预付的钱冲抵货款") !== -1) {
        typeNum = "21";
        this.queryTitel(this.deal, typeNum);
      } else if (
        item.memo.indexOf("用已预收的钱冲抵经营收入") !== -1 ||
        item.memo.indexOf("用已预收的钱冲抵其他收款") !== -1
      ) {
        typeNum = "20";
        this.queryTitel(this.deal, typeNum);
      } else if (item.memo.indexOf("用已预付的钱冲抵货款") !== -1) {
        typeNum = "11";
        this.queryTitel(this.deal, typeNum);
      } else if (item.memo.indexOf("用已预付的钱冲抵报销款") !== -1) {
        typeNum = "31";
        this.queryTitel(this.deal, typeNum);
      } else if (item.memo.indexOf("用已预收的钱冲抵其他收款") !== -1) {
        typeNum = "20";
        this.queryTitel(this.deal, typeNum);
      } else if (item.memo.indexOf("用已预付的钱冲抵报销款") !== -1) {
        typeNum = "21";
        this.queryTitel(this.deal, typeNum);
      } else {
        // this.queryTitel(this.deal, typeNum);
        this.active = item.value;

        this.bankValue = "";
        this.ajaxValue = item.value;
        console.log(this.dealTypeList);
        this.dealTypeList.forEach((e) => {
          e.company = "";
        });
        this.companyShow = false;
      }
      console.log(typeNum);
    },
    titleClick(item) {
      this.dealTypeList.forEach((e) => {
        e.company = "";
      });
      this.active = this.dealTypeList[this.index].value;
      this.dealTypeList[this.index].company = item.memo;
      // this.dealTypeList[this.index].company = item.memo;
      this.company = item.memo;
      this.deal.company = item.memo;
      // this.active = item.value;
      this.ajaxValue = this.dealItem.value;
      this.bankValue = item.key;
      this.companyShow = false;
    },
    dealFun() {
      console.log(this.dealType);
      if (!this.ajaxValue) {
        this.$store.commit("MyMessage", "请选择处置方式");
        return;
      }
      let amt =
        this.deal.comeAndGo.total >= 0
          ? this.deal.comeAndGo.total
          : this.deal.comeAndGo.total * -1;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        type: this.params.type,
        cgId: this.deal.id,
        amt: amt,
        dealType: this.ajaxValue,
      };
      if (this.company) {
        param.cgIdTo = this.bankValue;
      }
      // console.log(param);
      // return;
      deal(param).then((res) => {
        // console.log("queryTitel", res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "处置成功");
          this.init();
          this.invitationShow = false;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    init() {
      this.accountsFun();
      // this.queryTitel(this.params.type);
      this.queryDealType();
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.accountsFun();
    },
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.accountsFun();
    },
    dealClick(item) {
      this.deal = item;
      // this.queryTitel(item);
      this.invitationShow = true;
      this.$forceUpdate();
    },
    dateChange(mouth) {
      this.$router.push({
        path: "/detailed",
        query: {
          mouth: mouth,
          show: 1,
          activeIndex: this.params.id,
        },
      });
      this.pages = {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      };
      this.accountsFun();
    },
    navTab(item, index, increment) {
      if (increment > 0) {
        this.activeIndex = 0;
        this.navList.forEach((v, k) => {
          if (k < index) {
            this.activeIndex = this.activeIndex + v.width + 43;
          }
          if (k === index) {
            this.activeIndex = this.activeIndex + (v.width - 38) / 2;
          }
        });
      } else {
        // let div = document.getElementById("reportFormRight").offsetWidth;
        this.activeIndex = 1328;
        this.navListRight.forEach((v, k) => {
          if (k > index) {
            this.activeIndex = this.activeIndex - v.width - 43;
          }
          if (k === index) {
            this.activeIndex = this.activeIndex - (v.width + 38) / 2;
          }
        });
      }
    },
    tabsClick(item, index, increment) {
      this.navTab(item, index, increment);
      this.type = item.type;
      let _route = this.$route.query;
      this.company = "";
      this.ajaxValue = "";
      if (_route.show === "1") {
        this.$router.push({
          path: "/detailed",
          query: {
            mouth: this.mouth,
            show: 1,
            activeIndex: item.id,
          },
        });
      } else {
        this.$router.push({
          path: "/detailed",
          query: {
            show: 2,
            activeIndex: item.id,
          },
        });
      }
      this.moduleName = item.moduleName;
      this.activeTab = item.id;
      this.params = item;
      this.accountsFun();
      // this.queryTitel();
      this.queryDealType();
      this.$forceUpdate();
    },
    queryDealType() {
      queryDealType().then((res) => {
        // console.log("queryDealType", res);
        this.dealTypeList = [];
        this.dealType = "";
        res.data.options.forEach((element) => {
          if (element.key === this.type) {
            if (element.memo.indexOf("/个人名下") !== -1) {
              this.dealType = element.value;
            }
            this.dealTypeList.push(element);
          }
        });
      });
    },
    queryTitel(deal, typeNum) {
      console.log(typeNum);
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        type: typeNum,
        title: deal.title,
      };
      queryTitel(param).then((res) => {
        this.queryTitelList = res.data;
        if (this.queryTitelList.length === 0) {
          this.$store.commit("MyMessage", "暂无记录");
        } else {
          this.companyShow = true;
        }
      });
    },
    isShowOneClick(item, index, type) {
      if (item.pages && item.pageNum === 1) {
        this.accountsDetailFun(item, index);
      }
      item.isShow = !item.isShow;
      this.$forceUpdate();
    },
    isShowClick(item, index, type) {
      if (!item.pages) {
        return false;
      }
      this.accountsDetailFun(item, index);
      this.$forceUpdate();
    },
    accountsFun() {
      let mouth = this.mouth;
      if (this.mouth === "") {
        mouth = this.date.year + "-" + this.date.thisMounth;
      }
      let param = {
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        month: mouth,
        enterpriseId: this.enterpriseId,
      };
      this.$store.commit("showLoading", "");
      this.params.listFun(param).then((res) => {
        if (res.code === 0) {
          if (res.data.list) {
            this.tableData = res.data.list;
            this.totalPage = res.data.pages;
            this.pages.total = res.data.total;
            this.oldDate = mouth;
            if (this.$route.query.mouth) {
              this.showMouth = mouth;
            }

            this.tableData.forEach((element) => {
              element.isShow = false;
              element.pageNum = 1;
              element.pages = true;
            });
            this.$nextTick(() => {
              this.overflowStyle = this.$config.hasScrolled(
                "reportFormMain",
                "horizontal"
              );
            });
            this.$forceUpdate();
          } else {
            this.tableData = [];
          }
          this.$forceUpdate();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    accountsDetailFun(item, index) {
      let param = {
        cgId: item.id,
        enterpriseId: this.enterpriseId,
        pageSize: "20",
        pageNum: item.pageNum,
        month: this.mouth,
      };
      this.params.detailsFun(param).then((res) => {
        console.log("detailsFun", res);
        if (res.code === 0) {
          this.tableDetail = res.data.list;
          if (!this.tableData[index].hasOwnProperty("tableDetail")) {
            this.tableData[index].tableDetail = [];
          }
          let data1 = this.tableData[index].tableDetail;
          let data2 = [...data1, ...res.data.list];
          this.tableData[index].tableDetail = data2;
          this.tableData[index].pageNum++;
          if (res.data.list.length === 0) {
            this.tableData[index].pages = false;
            this.$store.commit("MyMessage", "数据已全部加载完成");
          }
          this.$forceUpdate();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addClass {
  float: right;
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  background: #4c8afc;
  border-radius: 3px;
  cursor: pointer;
}
.deal-width1 {
  display: inline-block;
  width: 40px;
}
.deal-width2 {
  display: inline-block;
  width: 130px;
}
.deal-width3 {
  display: inline-block;
  width: 120px;
}
.deal-width4 {
  display: inline-block;
  width: 80px;
}
.td-color {
  padding: 0;
  border-left: none;
  border-right: none;
  background: #efefef;
  .load-more {
    display: inline-block;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #4c8afc;
    // background: #dbdbdb;
    border: 1px solid #4c8afc;
    border-radius: 3px;
    cursor: pointer;
  }
}
.invitation-main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 420px;
  margin-left: -210px;
  height: 480px;
  margin-top: -240px;
  border-radius: 12px;
  background: #fff;
  text-align: center;
  padding-bottom: 30px;
}
.banklist-main {
  // overflow-y: auto;
  // margin-left: 15px;
  width: calc(100% - 20px);
  padding: 20px 0px 0;
  margin: 0 auto;
  max-height: 370px;
  height: auto;
  &.minWidth {
    width: calc(100% - 30px);
  }
}
.payment-p1 {
  font-size: 15px;
  font-weight: bold;
  color: #262626;
  margin-top: 20px;
}
.payment-p2 {
  height: 50px;
  line-height: 50px;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 67px;
  color: #262626;
  span {
    color: #262626;
    font-size: 24px;
    padding-right: 10px;
  }
}
.dealType-main {
  width: 100%;
  max-height: 230px;
  overflow-y: auto;
  padding: 20px 0 0;
  border-top: 1px solid rgba(187, 187, 187, 0.2);
  // border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  margin: 30px 0px 0;
  .dealType-main-box {
    padding: 15px 0;
    cursor: pointer;
    color: #262626;
    position: relative;
    &:hover {
      background: #f4f4f4;
    }
  }
}
.payment-text2 {
  width: 397px;
  padding: 15px 0;
  text-align: center;
  position: relative;
  // margin-bottom: 30px;
  // min-height: 44px;
  // overflow: hidden;
  &.active1 {
    padding: 0 0 15px;
  }
  .payment-text2-span {
    width: 300px;
    display: inline-block;
    font-weight: 400;
    // font-size: 15px;

    cursor: pointer;
    &.active {
      position: relative;
      top: 7px;
    }
  }
  &:hover {
    .company-main {
      display: block;
    }
  }
}
.company-main {
  width: 260px;
  position: absolute;
  top: 100px;
  right: -282px;
  background: #fff;
  border: 1px solid #ebeef5;
  padding: 14px 0;
  border-radius: 5px;
  display: block;
  max-height: 200px;
  overflow-y: auto;
  min-height: 200px;
  .company-main-box {
    cursor: pointer;
    padding: 5px 0;
    font-weight: bold;
    font-size: 15px;
  }
  .table-popup-el {
    top: -9px;
    width: 26px;
    left: 147px;
    height: 10px;
    position: absolute;
  }
}
.active {
  color: #4c8afc !important;
}
.activeS {
  color: #4c8afc !important;
  float: right;
  position: absolute;
  right: 10px;
}
</style>