<template>
  <div class="reportForm-table-center">
    <detailedSearch :month="month" @dateChange="dateChange"></detailedSearch>
    <div class="elTable">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#fff',
          color: '#262626',
        }"
        style="width: 100%; border: 1px solid #ebeef5"
        :height="tableHeight"
        v-loadmore="loadMore"
      >
        <ex-table-column
          prop="summary"
          fixed="left"
          label="摘要"
          align="center"
          width="150"
        >
        </ex-table-column>
        <ex-table-column
          prop="commodityName"
          width="300"
          label="商品名称"
          fixed="left"
          align="center"
        >
          <template slot-scope="scope">
            <span style="min-width: 100px">{{ scope.row.commodityName }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="commodityUnit"
          label="规格型号"
          align="center"
          fixed="left"
          width="140"
        >
        </ex-table-column>

        <ex-table-column prop="commodityPrice" label="单价" align="center">
        </ex-table-column>
        <ex-table-column prop="commodityNum" label="数量" align="center">
        </ex-table-column>
        <ex-table-column prop="commodityAmount" label="金额" align="center">
        </ex-table-column>

        <ex-table-column
          width="120"
          prop="idNum"
          fixed="right"
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <slot v-if="scope.row.isLast === 1">
              <span
                class="scope-span"
                :title="scope.row"
                @click="delDetail(scope.row)"
                >删除</span
              >
              <!-- <span class="scope-span" :title="scope.row">修改</span> -->
              <span @click="mergeFun(scope.row)" class="scope-span1">合并</span>
            </slot>
            <slot v-else>
              <span
                class="scope-span"
                :title="scope.row"
                @click="delDetail(scope.row)"
                >删除</span
              >
            </slot>
          </template>
        </ex-table-column>
      </el-table>

      <!-- <div class="merge-popup"></div> -->
      <div class="popup-layer" v-if="mergeShow">
        <div class="popup-layer-bg"></div>
        <div class="merge-popup">
          <div class="popup-layer-close" @click="rosterClose">
            <svg-icon icon-class="popup-close"></svg-icon>
          </div>
          <div class="merge-title">库存合并</div>
          <div class="merge-body">
            <div class="merge-body-box">
              <div class="merge-body-box-l">{{ goodsName }}</div>
              <div class="merge-body-box-r">
                = <input class="merge-ipt" type="text" />
              </div>
            </div>
          </div>
          <div class="merge-footer">
            <el-button type="primary" @click="rosterClose">保 存</el-button>
          </div>
        </div>
      </div>
    </div>
    <addKc @closeFun="closeFun" v-if="show"></addKc>
  </div>
</template>

<script>
import detailedSearch from "./detailedSearch.vue";
import addKc from "./addKc.vue";
import {
  deleteInventory,
  inventoryDetail,
  delDetail,
  queryWaiting,
} from "@/http/api";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      tableData: [],
      mergeShow: false,
      tableHeight: null,
      goodsName: "",
      month: "",
      show: false,
      date: this.$config.getDate(),
      pages: {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      },
    };
  },
  components: {
    detailedSearch,
    addKc,
  },
  props: {
    dataBase: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.month = this.date.year + "-" + this.date.thisMounth;
    this.inventoryDetail();
    this.tableHeight = document.body.clientHeight - 150;
  },

  methods: {
    loadMore(el) {
      if (this.pageFlag) {
        this.pages.pageNum++;
        this.inventoryDetail();
      }
    },
    dateChange(month) {
      this.month = month;
      this.tableData = [];
      this.pages.pageNum = 1;
      this.inventoryDetail();
    },
    closeFun() {},
    mergeFun(scope) {
      console.log(scope);
      this.goodsName = scope.goodsName;
      this.mergeShow = true;
    },
    delDetail(scope) {
      let param = {
        // userId: this.userId,
        inventoryId: scope.inventoryId,
      };
      delDetail(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.inventoryDetail();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    inventoryDetail() {
      let param = {
        enterpriseId: this.enterpriseId,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        // year: this.date.year,
        // month: this.date.year + "-" + this.date.thisMounth,
        year: this.month.split("-")[0],
        month: this.month,
        // year: "2022",
        // month: "2022-12",
      };
      queryWaiting(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.tableData = res.data;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    rosterClose() {
      this.mergeShow = false;
    },
    deleteInventory(item) {
      this.$confirm("此操作不可逆，是否进行删除", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let param = {
            enterpriseId: this.enterpriseId,
            goodsId: item.goodsId,
          };
          deleteInventory(param).then((res) => {
            if (res.code === 0) {
              this.$emit("closeFun", true);
              this.$store.commit("MyMessage", "操作成功");
            } else {
              this.$store.commit("MyMessage", res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less">
.el-table .cell {
  // white-space: nowrap;
  // width: fit-content;
  // margin: 0 auto;
  span {
    display: inline-block;
  }
}

.reportForm-table-center {
  .el-table td,
  .el-table th {
    padding: 5px 0;
  }
  // .el-table__body tr.hover-row.current-row > td,
  // .el-table__body tr.hover-row.el-table__row--striped.current-row > td,
  // .el-table__body tr.hover-row.el-table__row--striped > td,
  // .el-table__body tr.hover-row > td {
  //   background-color: #bbb;
  // }
  .el-table__body-wrapper {
    // padding-bottom: 40px;

    td {
      padding: 5px 0;
    }
    &::-webkit-scrollbar {
      width: 20px;
      height: 20px !important;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      border: 5px solid transparent;
    }
    &::-webkit-scrollbar-track {
      box-shadow: 3px 1px 13px rgba(219, 219, 219, 0.6) inset;
    }
    &::-webkit-scrollbar-thumb {
      min-height: 20px;
      background-clip: content-box;
      box-shadow: 0 0 0 0px rgba(153, 153, 153, 0.5);
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .el-table {
    th.is-leaf,
    td {
      border-right: 1px solid #ebeef5;
    }
    .el-table__header {
      tr {
        th {
          padding: 8px 0;
          background: #fff;
          .cell {
            color: #262626 !important;

            font-weight: 400;
            font-size: 14px;
          }
        }

        &:last-child {
          th {
            font-weight: 400;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      height: 20px !important;
    }
  }
  .el-table__body-wrapper {
    // max-height: 530px !important;
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track {
  &:hover {
    .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
      box-shadow: 0 0 0 8px rgba(153, 153, 153, 0.5) inset;
    }
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  box-shadow: 0 0 0 8px rgba(219, 219, 219, 0.3) inset;
  &:hover {
    box-shadow: 0 0 0 8px rgba(219, 219, 219, 0.6) inset;
  }
}
</style>
<style lang="less" scoped>
.merge-popup {
  width: 460px;
  height: 300px;
  background: #ffffff;
  border-radius: 6px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -230px;
  margin-top: -150px;
}
.merge-title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #262626;
}
.merge-body {
  width: 100%;
  margin-top: 50px;
  .merge-body-box {
    padding: 10px 20px;
    font-size: 14px;
    .merge-body-box-l {
      float: left;
      width: 100px;
      margin-right: 20px;
      text-align: right;
    }
    .merge-body-box-r {
      float: left;
      width: calc(100% - 120px);
      text-align: left;
    }
  }
}
.scope-span {
  font-size: 14px;
  margin-right: 8px;
  color: #4c8afc;
  cursor: pointer;
}
.scope-span1 {
  font-size: 14px;
  color: #4c8afc;
  cursor: pointer;
}
.merge-ipt {
  width: 220px;
  border-bottom: 1px solid #ddd;
  margin-left: 10px;
}
.merge-footer {
  text-align: center;
  margin-top: 50px;
}
.table-width1 {
  width: 62px;
}
.table-width2 {
  width: 153px;
  padding: 5px 10px;
}

.table-width3 {
  width: 168px;
  padding: 5px 10px;
}
.operate {
  display: inline-block;
  padding: 0 17px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
.operate-edit {
  color: #4c8afc;
}
.operate-del {
  color: #bbbbbb;
}
</style>