<template>
  <div class="assetMain">
    <div class="assetMain-table">
      <table class="accountTable">
        <thead>
          <tr>
            <th rowspan="2"><div class="table-width1">编号</div></th>
            <th rowspan="2"><div class="table-width2">所属部门</div></th>
            <th rowspan="2"><div class="table-width3">入账时间</div></th>
            <th colspan="2">资产类别</th>
            <th rowspan="2"><div class="table-width4">资产名称</div></th>
            <th rowspan="2"><div class="table-width5">资产原值</div></th>
            <th rowspan="2">
              <div class="table-width6">无形资产<br />减值损失</div>
            </th>
            <th rowspan="2">
              <div class="table-width6">以前年度<br />累计摊销额</div>
            </th>
            <th rowspan="2">
              <div class="table-width6">无形资产<br />期初净值</div>
            </th>
            <th rowspan="2">
              <div class="table-width7">使用寿命<br />（月）</div>
            </th>
            <th rowspan="2">
              <div class="table-width7">已用寿命<br />（月）</div>
            </th>
            <th rowspan="2">
              <div class="table-width7">剩余寿命 <br />（月）</div>
            </th>
            <th rowspan="2"><div class="table-width7">月摊销额</div></th>
            <th rowspan="2">
              <div class="table-width7">本年累计<br />摊销额</div>
            </th>
            <th rowspan="2">
              <div class="table-width7">无形资产<br />期末净值</div>
            </th>
          </tr>
          <tr>
            <th><div class="table-width7">大类</div></th>
            <th><div class="table-width8">细类</div></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td class="table-width1">{{ index + 1 }}</td>
            <td class="table-width2">{{ item.assetDepartmentName }}</td>
            <td class="table-width3">
              {{ item.assetRecordDate.split(" ")[0] }}
            </td>
            <td class="table-width7">{{ item.assetKindName }}</td>
            <td class="table-width8">{{ item.assetKindSecName }}</td>
            <td class="table-width4">{{ item.assetName }}</td>
            <td class="table-width5">{{ item.assetInitPrice }}</td>
            <td class="table-width6"></td>
            <td class="table-width6"></td>
            <td class="table-width6">}</td>
            <td class="table-width7">{{ item.assetLife }}</td>
            <td class="table-width7">{{ item.assetUsedLife }}</td>
            <td class="table-width7">{{ item.assetShengyuLife }}</td>
            <td class="table-width7"></td>
            <td class="table-width7"></td>
            <td class="table-width7"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.assetMain {
  width: 100%;
}
.table-width1 {
  width: 50px;
}
.table-width2 {
  width: 100px;
}
.table-width3 {
  width: 140px;
}
.table-width4 {
  width: 92px;
}
.table-width5 {
  width: 90px;
}
.table-width6 {
  width: 80px;
}
.table-width7 {
  width: 70px;
}

.table-width8 {
  width: 84px;
}
</style>