<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="popup-layer-body">
      <div class="popup-layer-close">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="assets-header">添加资产</div>
      <div class="assets-body">
        <div class="assets-body-l">
          <div class="assets-body-box">
            <div class="assets-body-box-title">添加方式</div>
            <div class="assets-body-box-content">
              <el-select v-model="dataList.addType" placeholder="请选择">
                <el-option
                  v-for="item in addModeList"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">所属部门</div>
            <div class="assets-body-box-content">
              <el-select
                v-model="dataList.assetDepartmentId"
                @change="
                  selectChange(dataList.assetDepartmentId, departmentList, '2')
                "
                placeholder="请选择"
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.departmentId"
                  :label="item.departmentName"
                  :value="item.departmentId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">存放地点</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.stroePlace"
                placeholder="请输入"
              />
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">资产类型</div>
            <div class="assets-body-box-content">
              <el-select
                v-model="assetType"
                @change="selectChange(assetType, assetTypeList, '3')"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in assetTypeList"
                  :key="item.xiLei"
                  :label="item.xiLeiName"
                  :value="item.xiLei"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="assets-body-box" v-if="dataList.assetType === '2'">
            <div class="assets-body-box-title">细类</div>
            <div class="assets-body-box-content">
              <el-select
                v-model="dataList.assetKindSec"
                @change="
                  selectChange(dataList.assetKindSec, subdivisionList, '5')
                "
                placeholder="请选择"
              >
                <el-option
                  v-for="item in subdivisionList"
                  :key="item.xiLei"
                  :label="item.xiLeiName"
                  :value="item.xiLei"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">资产名称</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.assetName"
                placeholder="请输入"
              />
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">规格型号</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.assetMode"
                placeholder="请输入"
              />
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">单位</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.assetUnit"
                placeholder="请输入"
              />
            </div>
          </div>
        </div>
        <div class="assets-body-l">
          <div class="assets-body-box">
            <div class="assets-body-box-title">资产数量</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.assetNum"
                placeholder="请输入"
              />
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">资产原值</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.assetInitPrice"
                placeholder="请输入"
              />
              <span class="assets-body-desc">元</span>
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">使用寿命</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.assetLife"
                placeholder="请输入"
              />
              <span class="assets-body-desc">期/月</span>
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">已用寿命</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.assetUsedLife"
                placeholder="请输入"
              />
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">折旧方法</div>
            <div class="assets-body-box-content">
              <el-select
                v-model="dataList.zhejiuFangfaId"
                @change="
                  selectChange(dataList.zhejiuFangfaId, depreciationList, '4')
                "
                placeholder="请选择"
              >
                <el-option
                  v-for="item in depreciationList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title">预计净残值率</div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.yjjczl"
                placeholder="请输入"
              />
              <span class="assets-body-desc">%</span>
            </div>
          </div>
          <div class="assets-body-box">
            <div class="assets-body-box-title" style="line-height: 15px">
              <span class="span1"> 已计提 <br />累计折旧额</span>
            </div>
            <div class="assets-body-box-content">
              <input
                type="text"
                v-model="dataList.yijitiLeijiZhejiu"
                placeholder="请输入"
              />
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="assets-footer">
        <el-button class="plainStyle" plain> 保 存 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getZhejiuFangfa, getAssetListType } from "@/http/api";
export default {
  data() {
    return {
      value: "",
      addModeList: this.$dictionaries.addAssets.addMode.list, //添加方式
      serviceLifeList: this.$dictionaries.addAssets.serviceLife.list, //使用寿命
      departmentList: JSON.parse(sessionStorage.getItem("departmentList")), //所属部门
      assetTypeList: this.$dictionaries.addAssets.assetTypeList,
      depreciationList: [],
      subdivisionList: [],
      addData: {},
      bumenData: {},
      zhichanData: {},
      zhejiuData: {},

      assetType: "",
      dataList: {
        addType: "",
        assetCode: "",
        assetDepartmentId: "",
        assetDepartmentName: "",
        assetInitPrice: "",
        assetKind: "",
        assetKindName: "",
        assetKindSec: "",
        assetKindSecName: "",
        assetLife: "",
        assetMode: "",
        assetName: "",
        assetNum: "",
        assetShengyuLife: "",
        assetType: "",
        assetUnit: "",
        assetUsedLife: "",
        enterpriseId: sessionStorage.getItem("enterpriseId"),
        stroePlace: "",
        yijitiLeijiZhejiu: "",
        yiqiannianleijizhejiu: "",
        yjjczl: "",
        zhejiuFangfaId: "",
        zhejiuFangfaName: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getZhejiuFangfa();
      this.getAssetListType();
    },
    selectChange(value, list, type) {
      console.log(this.dataList, list);
      list.forEach((e) => {
        if (type === "1") {
          if (e.id === value) {
            this.addData = e;
          }
        } else if (type === "2") {
          if (e.departmentId === value) {
            this.dataList.assetDepartmentId = e.departmentId;
            this.dataList.assetDepartmentName = e.departmentName;
            this.bumenData = e;
          }
        } else if (type === "3") {
          if (e.xiLei === value) {
            if (e.daLei === "1") {
              this.dataList.assetKind = e.daLei;
              this.dataList.assetKindSec = e.xiLei;
              this.dataList.assetKindSecName = e.xiLeiName;
              this.zhichanData = e;
              this.dataList.assetLife = e.life;
            } else {
              this.dataList.assetKind = "";
              this.dataList.assetKindSec = "";
              this.dataList.assetKindSecName = "";
            }
            this.dataList.assetType = e.daLei;
          }
        } else if (type === "4") {
          if (e.key === value) {
            this.zhejiuData = e;
          }
        } else if (type === "5") {
          if (e.xiLei === value) {
            this.dataList.assetKind = e.daLei;
            this.dataList.assetKindSec = e.xiLei;
            this.dataList.assetKindSecName = e.xiLeiName;
            this.dataList.assetLife = e.life;
            this.zhichanData = e;

            console.log(this.dataList);
          }
        }
      });
    },
    // 查询固定资产折旧方法
    getZhejiuFangfa() {
      getZhejiuFangfa().then((res) => {
        console.log("getZhejiuFangfa", res);
        this.depreciationList = res.data.options;
        this.dataList.zhejiuFangfaId = this.depreciationList[0].key;
        if (this.type === "add") {
        }
      });
    },
    // 查询资产类型
    getAssetListType() {
      getAssetListType().then((res) => {
        console.log("getAssetListType1", res);
        // this.assetTypeList = res.data.options;
        this.subdivisionList = [];
        res.data.options.forEach((e) => {
          if (e.daLei === "2") {
            this.subdivisionList.push(e);
          }
        });
        console.log(this.subdivisionList);
      });
    },
    submitClick() {
      // this.$emit("closePopupFun", "add");
      console.log(this.dataList);
      // console.log(this.addData);
      // console.log(this.bumenData);
      // console.log(this.zhichanData);
      // console.log(this.zhejiuData);
      this.dataList.enterpriseId = this.$route.query.id;
      this.dataList.assetKindSecName = this.zhichanData.xiLeiName;
      this.dataList.zhejiuFangfaName = this.zhejiuData.value;
      this.dataList.assetShengyuLife =
        this.dataList.assetLife * 1 - this.dataList.assetUsedLife * 1 + "";
      if (this.dataList.assetType === "1") {
        this.dataList.assetKindName = "固定资产";
        this.addGudingZiChan();
      } else {
        this.dataList.assetKindName = "无形资产";
        this.addWuxingZiChan();
      }
    },
    addGudingZiChan() {},
    addWuxingZiChan() {},
  },
};
</script>

<style lang="less" scoped>
.popup-layer .popup-layer-close {
  padding: 18px 15px;
}
.popup-layer-body {
  width: 960px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -480px;
  margin-top: -300px;
  background: #fff;
  border-radius: 5px;
}
.assets-header {
  height: 56px;
  line-height: 56px;
  width: 100%;
  text-align: center;
  background: #f4f4f4;
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: #262626;
}
.assets-body {
  width: calc(100% - 50px);
  padding: 10px 25px;
  min-height: 450px;
}
.assets-body-l {
  width: calc((100% - 30px) / 2);
  margin-left: 30px;
  float: left;
  &:first-child {
    margin-left: 0;
  }
}
.assets-body-box {
  height: 40px;
  line-height: 40px;
  margin-bottom: 15px;
}
.assets-body-box-title {
  display: inline-block;
  width: 100px;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  position: relative;
  .span1 {
    position: relative;
    top: 9px;
  }
}
.assets-body-box-content {
  display: inline-block;
  width: calc(100% - 115px);
  height: 100%;
  text-align: left;
  margin-left: 15px;
  position: relative;
  input {
    width: calc(100% - 17px);
    border: 1px solid #dbdbdb;
    height: calc(100% - 2px);
    border-radius: 3px;
    padding-left: 15px;
    font: 400 16px Techna;
    color: #262626;
    &::placeholder {
      color: #999;
    }
  }
  .assets-body-desc {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    // width: 32px;
    text-align: right;
    padding-right: 10px;
  }
  .el-select {
    width: 100%;
  }
}
.plainStyle {
  margin-top: 10px auto 0;
  width: 400px;
  border: 1px solid #4c8afc;
  color: #4c8afc;
  font-weight: bold;
  &:hover {
    border: 1px solid #4c8afc;
    color: #4c8afc;
  }
}
.assets-footer {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
</style>
<style lang="less">
.assets-body-box-content {
  .el-select {
    width: 100%;
    .el-input__inner {
      color: #262626;
      font: 400 16px Techna;
      &::placeholder {
        color: #999;
      }
    }
  }
}
</style>