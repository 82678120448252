<template>
  <table class="accountTable">
    <thead>
      <tr>
        <th><div class="table-width1">项目</div></th>
        <th><div class="table-width2">行次</div></th>
        <th><div class="table-width3">本年累计金额</div></th>
        <th v-if="jidu !== ''">
          <div class="table-width4">{{ jidu }}</div>
        </th>
        <th v-else><div class="table-width4">本月金额</div></th>
        <th v-if="visit === 'visit' && reportIdMonth && flag">
          <div class="table-width5">操作</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(itemList, i1) in tableData" :key="i1 + '-2'">
        <td>
          <div class="table-width1">
            <span
              :class="{
                tableColor1: itemList.level === 99,
                tableColor2: itemList.level == '2',
                tableColor3: itemList.level == '3',
              }"
              >{{ itemList.name }}</span
            >
          </div>
        </td>
        <td>
          <div class="table-width2">{{ itemList.line }}</div>
        </td>
        <td>
          <div class="table-width3">{{ itemList.yearAmount }}</div>
        </td>
        <td>
          <div class="table-width4">{{ itemList.monthAmount }}</div>
        </td>
        <td v-if="visit === 'visit' && reportIdMonth && flag">
          <div class="table-width5">
            <slot></slot>
            <slot
              v-if="
                itemList.monthAmount !== '' &&
                itemList.reportIdMonth !== '' &&
                itemList.reportIdMonth !== undefined
              "
              ><span @click="reportClick(itemList)">修改</span>
            </slot>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  data() {
    return {
      visit: sessionStorage.getItem("visit"),
      reportIdMonth: false,
    };
  },
  mounted() {
    console.log(this.tableData);
    this.reportIdMonth = false;
    this.tableData.forEach((element) => {
      if (element.reportIdMonth && !this.reportIdMonth) {
        this.reportIdMonth = true;
      }
    });
  },
  methods: {
    reportClick(itemList) {
      this.$emit("reportClick", itemList);
    },
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    flag: {
      type: Boolean,
      default() {
        return false;
      },
    },
    jidu: {
      type: String,
      default() {
        return "";
      },
    },
  },
};
</script>

<style lang="less" scoped>
.accountTable {
  tbody {
    tr {
      &:nth-child(2n) {
        background: #f8f9fa;
      }
    }
  }
}
.table-width5 {
  width: 50px;
  text-align: center;
}
tbody {
  .table-width5 {
    color: #4c8afc;
    cursor: pointer;
  }
}
.table-width1 {
  width: 690px;
  padding-left: 18px;
  text-align: left;
  font-weight: bold;
}
.table-width2 {
  width: 80px;
}
.table-width3 {
  width: 230px;
}
.table-width4 {
  width: 230px;
}
.tableColor1 {
  color: #f58e21;
}
.tableColor2 {
  color: #262626;
  padding-left: 28px;
}
.tableColor3 {
  color: #999999;
  padding-left: 68px;
}
</style>