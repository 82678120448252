<template>
  <div class="reportForm-table-center">
    <detailedSearch :month="month" @dateChange="dateChange"></detailedSearch>
    <div class="elTable">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#fff',
          color: '#262626',
        }"
        style="width: 100%"
        :height="tableHeight"
        v-loadmore="loadMore"
        :row-class-name="tableRowClassName"
      >
        <!-- <el-table-column
          align="center"
          fixed="left"
          label="序号"
          type="index"
          width="50"
        >
        </el-table-column> -->
        <ex-table-column
          prop="inventoryDate"
          label="票据日期"
          align="center"
          fixed="left"
          width="100"
        >
        </ex-table-column>
        <ex-table-column
          prop="summary"
          fixed="left"
          label="摘要"
          align="center"
          width="150"
        >
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.summary === '本年累计'" style="color: #fc5531;">{{ scope.row.summary }}</span>
            <span v-else >{{ scope.row.summary }}</span>
          </template> -->
        </ex-table-column>
        <ex-table-column
          prop="goodsName"
          width="300"
          label="商品名称"
          fixed="left"
          align="center"
        >
          <template slot-scope="scope">
            <span style="min-width: 100px">{{ scope.row.goodsName }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="goodsModel"
          label="规格型号"
          align="center"
          fixed="left"
          width="140"
        >
        </ex-table-column>
        <ex-table-column prop="goodsUnit" label="单位" align="center">
        </ex-table-column>

        <ex-table-column label="期初库存" align="center">
          <ex-table-column prop="qichuPrice" label="单价" align="center">
          </ex-table-column>
          <ex-table-column prop="qichuNum" label="数量" align="center">
          </ex-table-column>
          <ex-table-column prop="qichuAmount" label="金额" align="center">
          </ex-table-column>
        </ex-table-column>
        <ex-table-column label="实际入库" align="center">
          <ex-table-column prop="monthPrice" label="单价" align="center">
          </ex-table-column>
          <ex-table-column prop="monthNum" label="数量" align="center">
          </ex-table-column>
          <ex-table-column prop="monthAmount" label="金额" align="center">
          </ex-table-column>
        </ex-table-column>
        <ex-table-column label="暂估入库" align="center">
          <ex-table-column prop="estimatedPrice" label="单价" align="center">
          </ex-table-column>
          <ex-table-column prop="estimatedNum" label="数量" align="center">
          </ex-table-column>
          <ex-table-column prop="estimatedAmount" label="金额" align="center">
          </ex-table-column>
        </ex-table-column>
        <ex-table-column label="出库" align="center">
          <ex-table-column prop="monthOutPrice" label="单价" align="center">
          </ex-table-column>
          <ex-table-column prop="monthOutNum" label="数量" align="center">
          </ex-table-column>
          <ex-table-column prop="monthOutAmount" label="金额" align="center">
          </ex-table-column>
        </ex-table-column>
        <ex-table-column label="期末库存" align="center">
          <ex-table-column prop="qimoPrice" label="单价" align="center">
          </ex-table-column>
          <ex-table-column prop="qimoNum" label="数量" align="center">
          </ex-table-column>
          <ex-table-column prop="qimoAmount" label="金额" align="center">
          </ex-table-column>
        </ex-table-column>
        <ex-table-column
          width="120"
          prop="idNum"
          fixed="right"
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <slot v-if="scope.row.isLast === 1">
              <span
                class="scope-span"
                :title="scope.row"
                @click="delDetail(scope.row)"
                >删除</span
              >
              <!-- <span class="scope-span" :title="scope.row">修改</span> -->
              <span @click="mergeFun(scope.row)" class="scope-span1">合并</span>
            </slot>
            <slot v-else>
              <span
                class="scope-span"
                :title="scope.row"
                @click="delDetail(scope.row)"
                >删除</span
              >
            </slot>
          </template>
        </ex-table-column>
      </el-table>
      <!-- <div class="pages" v-if="pages.totalPage > 1">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :page-size="pages.pageSize"
          :current-page.sync="pages.curren"
          :total="pages.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[20, 50, 100]"
        >
        </el-pagination>
      </div> -->

      <!-- <div class="merge-popup"></div> -->
      <div class="popup-layer" v-if="mergeShow">
        <div class="popup-layer-bg"></div>
        <div class="merge-popup">
          <div class="popup-layer-close" @click="rosterClose">
            <svg-icon icon-class="popup-close"></svg-icon>
          </div>
          <div class="merge-title">库存合并</div>
          <div class="merge-body">
            <div class="merge-body-box">
              <div class="merge-body-box-l" style="height: 40px;    line-height: 40px;">{{ goodsName }}</div>
              <div class="merge-body-box-r">
                =
                <el-select
                  v-model="productName"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.composeCode"
                    :label="item.productName"
                    :value="item.composeCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="merge-footer">
            <el-button type="primary" @click="mergeProduct">保 存</el-button>
          </div>
        </div>
      </div>
    </div>
    <addKc @closeFun="closeFun" v-if="show"></addKc>
  </div>
</template>

<script>
import detailedSearch from "./detailedSearch.vue";
import addKc from "./addKc.vue";
import {
  deleteInventory,
  inventoryDetail,
  delDetail,
  queryProductName,
  mergeProduct
} from "@/http/api";
export default {
  data() {
    return {
      productName: "",
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      tableData: [],
      mergeShow: false,
      tableHeight: null,
      goodsName: "",
      month: "",
      show: false,
      date: this.$config.getDate(),
      pages: {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      },
      pageFlag: true,
      options: [],
      loading: false,
      inventoryId:""
    };
  },
  components: {
    detailedSearch,
    addKc,
  },
  props: {
    dataBase: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.month = this.date.year + "-" + this.date.thisMounth;
    this.inventoryDetail();
    // this.queryProductName("B");
    this.tableHeight = document.body.clientHeight - 150;
  },

  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row.summary === '本年累计' && rowIndex !== this.tableData.length-1) {
        return 'success-row';
      } 
      return '';
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = false;
        this.queryProductName(query);
      } else {
        this.options = [];
      }
    },
    loadMore(el) {
      if (this.pageFlag) {
        this.pages.pageNum++;
        this.inventoryDetail();
      }
    },
    closeFun() {},
    dateChange(month) {
      this.month = month;
      this.pages.pageNum = 1;
      this.tableData = [];
      this.inventoryDetail();
    },
    mergeFun(scope) {
      console.log(scope);
      this.goodsName = scope.goodsName;
      this.inventoryId = scope.inventoryId;
      this.mergeShow = true;
    },
    delDetail(scope) {
      let param = {
        // userId: this.userId,
        inventoryId: scope.inventoryId,
      };
      delDetail(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "删除成功");
          this.inventoryDetail();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    mergeProduct() {
      let param = {
        enterpriseId: this.enterpriseId,
        composeCode: this.productName,
        inventoryId: this.inventoryId
      };
      mergeProduct(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "操作成功");
          this.rosterClose()
          this.tableData = [];
          this.inventoryDetail();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    inventoryDetail() {
      let param = {
        // enterpriseId: this.enterpriseId,
        enterpriseId: this.enterpriseId,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        year: this.month.split("-")[0],
        month: this.month,
        // year: "2022",
        // month: "2022-12",
      };
      inventoryDetail(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.totalPage = res.data.totalPage;
          this.pages.total = res.data.totalRow;
          this.tableData = [...this.tableData, ...res.data.list];
          if (res.data.list.length < this.pages.pageSize) {
            this.pageFlag = false;
          }
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    queryProductName(query) {
      let param = {
        enterpriseId: this.enterpriseId,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        productName: query,
      };
      queryProductName(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.options = res.data.list;
          console.log(this.options);
          this.$forceUpdate()
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    rosterClose() {
      this.mergeShow = false;
      this.productName = ""
    },
    deleteInventory(item) {
      this.$confirm("此操作不可逆，是否进行删除", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let param = {
            enterpriseId: this.enterpriseId,
            goodsId: item.goodsId,
          };
          deleteInventory(param).then((res) => {
            if (res.code === 0) {
              this.$emit("closeFun", true);
              this.$store.commit("MyMessage", "操作成功");
            } else {
              this.$store.commit("MyMessage", res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less">
.el-table .success-row {
  td{
    border-bottom-width: 2px;
  }
}
.el-table .cell {
  // white-space: nowrap;
  // width: fit-content;
  // margin: 0 auto;
  span {
    display: inline-block;
  }
}

.reportForm-table-center {
  .el-table td,
  .el-table th {
    padding: 5px 0;
  }
  // .el-table__body tr.hover-row.current-row > td,
  // .el-table__body tr.hover-row.el-table__row--striped.current-row > td,
  // .el-table__body tr.hover-row.el-table__row--striped > td,
  // .el-table__body tr.hover-row > td {
  //   background-color: #bbb;
  // }
  .el-table__body-wrapper {
    // padding-bottom: 40px;

    td {
      padding: 5px 0;
    }
    &::-webkit-scrollbar {
      width: 20px;
      height: 20px !important;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      border: 5px solid transparent;
    }
    &::-webkit-scrollbar-track {
      box-shadow: 3px 1px 13px rgba(219, 219, 219, 0.6) inset;
    }
    &::-webkit-scrollbar-thumb {
      min-height: 20px;
      background-clip: content-box;
      box-shadow: 0 0 0 0px rgba(153, 153, 153, 0.5);
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .el-table {
    .el-table__header {
      tr {
        th {
          padding: 8px 0;
          background: #fff;
          .cell {
            color: #262626 !important;

            font-weight: 400;
            font-size: 14px;
          }
        }
        &:last-child {
          th {
            font-weight: 400;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      height: 20px !important;
    }
  }
  .el-table__body-wrapper {
    // max-height: 530px !important;
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track {
  &:hover {
    .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
      box-shadow: 0 0 0 8px rgba(153, 153, 153, 0.5) inset;
    }
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  box-shadow: 0 0 0 8px rgba(219, 219, 219, 0.3) inset;
  &:hover {
    box-shadow: 0 0 0 8px rgba(219, 219, 219, 0.6) inset;
  }
}
</style>
<style lang="less" scoped>
.merge-popup {
  width: 460px;
  height: 300px;
  background: #ffffff;
  border-radius: 6px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -230px;
  margin-top: -150px;
}
.merge-title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #262626;
}
.merge-body {
  width: 100%;
  margin-top: 50px;
  .merge-body-box {
    padding: 10px 20px;
    font-size: 14px;
    .merge-body-box-l {
      float: left;
      width: 100px;
      margin-right: 20px;
      text-align: right;
    }
    .merge-body-box-r {
      float: left;
      width: calc(100% - 120px);
      text-align: left;
    }
  }
}
.scope-span {
  font-size: 14px;
  margin-right: 8px;
  color: #4c8afc;
  cursor: pointer;
}
.scope-span1 {
  font-size: 14px;
  color: #4c8afc;
  cursor: pointer;
}
.merge-ipt {
  width: 220px;
  border-bottom: 1px solid #ddd;
  margin-left: 10px;
}
.merge-footer {
  text-align: center;
  margin-top: 50px;
}
.table-width1 {
  width: 62px;
}
.table-width2 {
  width: 153px;
  padding: 5px 10px;
}

.table-width3 {
  width: 168px;
  padding: 5px 10px;
}
.operate {
  display: inline-block;
  padding: 0 17px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
.operate-edit {
  color: #4c8afc;
}
.operate-del {
  color: #bbbbbb;
}
</style>