<template>
  <div class="height100">
    <ReportFormHeader
      v-if="headerShow"
      :mouth="mouth"
      @dateChange="dateChange"
      @dowmReport="dowmReport"
      :headerData="headerData"
      jindu="true"
    ></ReportFormHeader>
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-table">
          <div class="reportForm-table-center">
            <componentTable
              v-if="tableShow"
              :tableData="tableData"
              @reportClick="reportClick"
              :flag="true"
            ></componentTable>
          </div>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
    <reprotPopup
      type="2"
      :reportItem="reportItem"
      v-if="reprotPopupFlag"
      @sucFun="sucFun"
      @closeFun="closeFun"
    ></reprotPopup>
  </div>
</template>
<script>
import ReportFormHeader from "./components/reportFormHeader.vue";
import componentTable from "./components/componentTable.vue";
import reprotPopup from "./components/reprotPopupC.vue";
import TheFooter from "@/components/TheFooter.vue";
import { profitReport, profitReportSmall } from "@/http/api";
export default {
  data() {
    return {
      headerShow: false,
      tableShow: false,
      reprotPopupFlag: false,
      overflowStyle: false,
      downUrl: "",
      value: "",
      mouth: "",
      date: this.$config.getDate(),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      accountingCriterion: sessionStorage.getItem("accountingCriterion"),
      userId: sessionStorage.getItem("userId"),
      company: sessionStorage.getItem("company"),
      token: sessionStorage.getItem("pc-tkn"),
      tableData: [],
      headerData: {
        left: true,
        right: true,
        title: "利润表",
        btn: 1,
        xiazai: "true",
        url: "profitQuarter",
      },
      reportItem: {},
    };
  },
  components: {
    ReportFormHeader,
    componentTable,
    TheFooter,
    reprotPopup,
  },
  mounted() {
    let _route = this.$route;
    if (_route.query.mouth) {
      this.mouth = _route.query.mouth;
    } else {
      this.mouth = this.date.year + "-" + this.date.thisMounth;
    }
    this.init();
    this.$nextTick(() => {
      this.headerShow = true;
    });
  },
  methods: {
    sucFun() {
      this.closeFun();
      this.init();
    },
    closeFun() {
      this.reprotPopupFlag = false;
    },
    reportClick(itemList) {
      console.log(itemList);
      this.reportItem = itemList;
      this.reprotPopupFlag = true;
    },
    dowmReport() {
      let that = this;
      this.$store.commit("showLoading", "");
      const formData = new FormData();
      formData.append("enterpriseId", this.enterpriseId);
      formData.append("userId", this.userId);
      formData.append("month", this.mouth);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          console.log(data);
          const blob = new Blob([xhr.response], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.company + " " + this.mouth + "利润表.xlsx"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          }
          this.$store.commit("hideLoading", "");
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open("POST", url + that.downUrl);
      xhr.setRequestHeader("token", this.token);
      xhr.responseType = "blob";
      xhr.send(formData);
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    init() {
      this.tableShow = false;
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
      };
      if (this.accountingCriterion + "" == "1") {
        this.downUrl = "/manager/enterprise-small-report/profitReportDownload";
        this.profitReportSmall(param);
      } else {
        this.profitReport(param);
        this.downUrl = "/manager/enterprise-report-query/profitReportDownload";
      }
    },
    dateChange(mouth) {
      this.$router.push({
        path: "/profit",
        query: {
          mouth: mouth,
        },
      });
      this.mouth = mouth;
      this.init();
    },
    profitReport(param) {
      profitReport(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.tableData = [];
          res.data.forEach((e1, i1) => {
            let line = "";
            let monthAmountTotal =
              e1.monthAmountTotal === "0.00" ? "0" : e1.monthAmountTotal;
            let yearAmountTotal =
              e1.yearAmountTotal === "0.00" ? "0" : e1.yearAmountTotal;
            if (e1.name === "一、营业收入") {
              line = "1";
            } else if (e1.name === "三、利润总额（亏损总额以“-”号填列）") {
              line = "19";
            } else if (e1.name === "二、营业利润（亏损以“-”号填列）") {
              line = "16";
            } else if (e1.name === "五、其他综合收益的税后净额") {
              line = "24";
            } else if (e1.name === "四、净利润（净亏损以“-”号填列）") {
              if (this.accountingCriterion === 1) {
                line = "32";
              } else {
                line = "21";
              }
            } else if (e1.name === "六、综合收益总额") {
              line = "34";
            } else if (e1.name === "七、每股收益：") {
              line = "35";
            }
            this.tableData.push({
              id: e1.id,
              level: 99,
              line: line,
              monthAmount: monthAmountTotal,
              name: e1.name,
              reportIdMonth: e1.reportIdMonth,
              yearAmount: yearAmountTotal,
            });
            e1.list.forEach((e2, i2) => {
              e2.monthAmount = e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
              e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
              this.tableData.push(e2);
            });
          });
          this.$nextTick(() => {
            this.tableShow = true;
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          this.overflowStyle = false;
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    profitReportSmall(param) {
      profitReportSmall(param).then((res) => {
        console.log(res);
        // this.tableData = res.data;
        if (res.code === 0) {
          this.tableData = [];
          res.data.forEach((e1, i1) => {
            let line = "";
            let monthAmountTotal =
              e1.monthAmountTotal === "0.00" ? "0" : e1.monthAmountTotal;
            let yearAmountTotal =
              e1.yearAmountTotal === "0.00" ? "0" : e1.yearAmountTotal;
            if (e1.name === "一、营业收入") {
              line = "1";
            } else if (e1.name === "二、营业利润（亏损以“-”号填列）") {
              line = "21";
            } else if (e1.name === "三、利润总额（亏损总额以“-”号填列）") {
              line = "30";
            } else if (e1.name === "四、净利润（净亏损以“-”号填列）") {
              line = "32";
            }
            this.tableData.push({
              id: e1.id,
              level: 99,
              line: line,
              monthAmount: monthAmountTotal,
              name: e1.name,
              reportIdMonth: e1.reportIdMonth,
              yearAmount: yearAmountTotal,
            });
            e1.list.forEach((e2, i2) => {
              e2.monthAmount = e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
              e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
              this.tableData.push(e2);
            });
          });
          this.tableShow = true;
          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          this.overflowStyle = false;
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>
