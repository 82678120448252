<template>
  <div class="reportForm-header voucherHeader">
    <div class="reportForm-header-left">
      <div class="reportForm-header-width" v-if="headerData.left">
        <el-date-picker
          v-if="dataShow"
          v-model="mouthR"
          :editable="false"
          value-format="yyyy-MM"
          format="yyyy年 MM月"
          type="month"
          placeholder="选择月"
          @change="dateChange"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <i class="el-icon-arrow-down voucher-icon"></i>
        <div class="voucher-tips" v-if="jurisdiction === '1'">
          <span class="voucher-span1"
            >注：字号有断续的，可点击重新排序进行更正</span
          >
          <div class="voucher-link">
            <span class="voucher-span sHide" @click="voucherPopup = true"
              >更新凭证序号</span
            >
            <div class="voucher-popup sHide" v-if="voucherPopup">
              <Triangle direction="top" left="78" color="#cccccc"></Triangle>
              <p class="sHide">确定凭证序号重新排列？</p>
              <div class="sHide">
                <span class="span1 sHide" @click="voucherPopup = false"
                  >取消</span
                >
                <span
                  class="span2 sHide"
                  @click="
                    voucherCodeReset();
                    voucherPopup = false;
                  "
                  >确定</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="reportForm-header-right">
      <div class="reportForm-header-width" v-if="headerData.right">
        <!-- <el-button
          type="primary"
          v-if="headerData.btn === 2 && jurisdiction === '1'"
          class="btn-primary"
          @click="voucherCodeReset"
          style="width: 140px"
        >
          重新生成编码
        </el-button> -->
        <el-button
          type="primary"
          v-if="headerData.btn === 2"
          class="btn-primary downStyle"
          @click="downloadVoucherUp"
        >
          更新下载任务
        </el-button>
        <el-button
          type="primary"
          v-if="headerData.btn === 2"
          class="btn-primary downStyle"
          @click="queryDownloadTaskLoad"
        >
          下载凭证
        </el-button>
        <el-button
          type="primary"
          v-if="headerData.btn === 2 && visit === 'visit'"
          class="btn-primary"
          @click="popupShow = true"
        >
          添加凭证
        </el-button>
      </div>
    </div>
    <voucherListPopup
      v-if="headerData.btn === 2 && popupShow"
      @popupClose="popupClose"
      @voucherSave="voucherSave"
    ></voucherListPopup>
  </div>
</template>

<script>
import {
  voucherCodeReset,
  downloadVoucher,
  queryDownloadTask,
  downloadGet,
} from "@/http/api";
import voucherListPopup from "./voucherListPopup.vue";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      company: sessionStorage.getItem("company"),
      userId: sessionStorage.getItem("userId"),
      visit: sessionStorage.getItem("visit"),
      token: sessionStorage.getItem("pc-tkn"),
      jurisdiction: sessionStorage.getItem("jurisdiction"),
      popupShow: false,
      dataShow: true,
      showMouth: "",
      mouthR: "",
      jiduText: "",
      voucherIpt: "",
      voucherPopup: false,
      queryYear: "",
      quarterDate: "",
      date: this.$config.getDate(),
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      mouthText: "",
      quarterYear: "",
      quarterList: [],
    };
  },
  components: {
    voucherListPopup,
  },
  props: {
    mouth: {
      type: String,
      default: null,
    },
    jindu: {
      type: String,
      default: null,
    },
    headerData: {
      type: Object,
      default() {
        return {};
      },
    },
    sum: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  watch: {
    mouth(val) {
      this.mouthR = val;
      if (this.mouthR !== "") {
        // this.mouthText =
        //   this.mouthR.split("-")[0] + "年" + this.mouthR.split("-")[1] + "月";
      }
    },
  },
  mounted() {
    this.mouthR = this.mouth;
    if (this.mouthR !== "") {
      // this.mouthText =
      //   this.mouthR.split("-")[0] + "年" + this.mouthR.split("-")[1] + "月";
    }
    this.quarterYear = this.date.year;
    if (this.headerData.quarter === 4) {
      this.quarterYear = this.quarterYear - 1;
    }
    // console.log(window.location.origin);
    this.quarterList = [];
    if (
      this.date.thisMounth === "01" ||
      this.date.thisMounth === "02" ||
      this.date.thisMounth === "03"
    ) {
      this.queryYear = this.date.year - 1;
    } else {
      this.queryYear = this.date.year;
    }
    if (this.headerData.jiduShow) {
      for (let i = 0; i < this.headerData.quarter; i++) {
        this.quarterList.push({ id: i, title: `第${i + 1}季度` });
      }
      this.jiduText = `${this.queryYear}年第${this.headerData.quarter * 1}季度`;
      this.quarterDate = this.headerData.quarter * 1 - 1;
    }
    // this.downloadVoucher();
    let that = this;
    if (that.$route.path === "/voucherList") {
      document.addEventListener("click", function (e) {
        if (typeof e.target.className === "string") {
          if (e.target.className.indexOf("sHide") === -1) {
            that.voucherPopup = false;
            that.$forceUpdate();
          }
        }
      });
    }

    this.$forceUpdate();
  },
  methods: {
    downloadVoucherUp() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
      };
      downloadVoucher(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "更新成功");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    downloadVoucher() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
      };
      downloadVoucher(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.queryDownloadTask();
        }
      });
    },
    queryDownloadTask() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        taskType: 7,
        pageSize: 99999,
        pageNum: 1,
      };
      queryDownloadTask(param).then((res) => {
        if (res.code === 0) {
          if (res.data.list.length > 0) {
            if (res.data.list[0].createStatus === 2) {
              this.downloadGet(res.data.list[0].id);
            }
            // let origin = window.location.origin;
            // if (origin === "http://192.168.3.8:8010")
            //   origin = "http://www.ceshihr.com";
            // let downName = "/" + res.data.list[0].path;
            // window.open(origin + "" + downName);
          } else {
            this.$store.commit("showLoading", "");
            setTimeout(() => {
              this.queryDownloadTask();
            }, 2000);
          }
        }
      });
    },
    queryDownloadTaskLoad() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        taskType: 7,
        pageSize: 99999,
        pageNum: 1,
      };
      queryDownloadTask(param).then((res) => {
        if (res.code === 0) {
          if (res.data.list.length > 0) {
            if (res.data.list[0].createStatus === 2) {
              this.downloadGet(res.data.list[0].id);
            } else {
              this.$store.commit("showLoading", "");
              setTimeout(() => {
                this.queryDownloadTaskLoad();
              }, 2000);
            }
            // let origin = window.location.origin;
            // if (origin === "http://192.168.3.8:8010")
            //   origin = "http://www.ceshihr.com";
            // let downName = "/" + res.data.list[0].path;
            // window.open(origin + "" + downName);
          } else {
            this.downloadVoucher();
          }
        }
      });
    },
    downloadGet(downloadId) {
      let date = this.mouthR.split("-")[0] + "-" + this.mouthR.split("-")[1];

      let that = this;
      this.$store.commit("showLoading", "");
      const formData = new FormData();
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // type: "application/zip"

          const blob = new Blob([xhr.response], {
            type: "application/zip",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.company + " " + "记账凭证"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          }
          this.$store.commit("hideLoading", "");
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open(
        "GET",
        url +
          `/manager/voucher-download/download?enterpriseId=${this.enterpriseId}&downloadId=${downloadId}&month=${date}`
      );
      xhr.setRequestHeader("token", this.token);
      xhr.responseType = "blob";
      xhr.send(formData);
    },
    voucherCodeReset() {
      let date = this.mouthR.split("-")[0] + "" + this.mouthR.split("-")[1];
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: date,
      };
      voucherCodeReset(param).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$emit("voucherSave");
          this.$store.commit("MyMessage", "操作成功");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    printFun() {
      this.$emit("printFun");
    },
    selectChange() {
      this.jiduText = `${this.queryYear}年第${this.quarterDate * 1 + 1}季度`;
      this.$emit("jiduDate", this.quarterDate * 1 + 1);
    },
    dowmReport() {
      this.$emit("dowmReport");
    },
    voucherSave() {
      this.$emit("voucherSave");
      this.popupShow = false;
    },
    popupClose() {
      this.popupShow = false;
      this.$forceUpdate();
    },
    dateChange() {
      // this.showMouth = this.mouthR;
      this.showMouth =
        this.mouthR.split("-")[0] + "年" + this.mouthR.split("-")[1] + "月";
      this.$emit("dateChange", this.mouthR);
    },
    goToFun(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.pingzheng {
  padding-left: 20px;
}
.voucherIpt {
  width: 150px;
  margin-left: 20px;
}
.voucher-icon {
  position: relative;
  left: -20px;
  // z-index: 1;
  z-index: -1;
}
.voucherHeader {
  .reportForm-header-left,
  .reportForm-header-right {
    width: 50% !important;
  }
}
.voucher-tips {
  display: inline-block;

  color: #999999;
}
.voucher-link {
  display: inline-block;
  position: relative;

  margin-left: 10px;

  z-index: 1;
  .voucher-span {
    color: #4c8afc;
    cursor: pointer;
  }
  .voucher-popup {
    position: absolute;
    top: 50px;
    left: -40px;
    width: 162px;
    height: 72px;
    background: #ffffff;
    opacity: 1;
    border: 1px solid #dbdbdb;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    p {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      height: 40px;
      line-height: 40px;
    }
    .span1 {
      color: #999;
      width: 48px;
      height: 22px;
      line-height: 22px;
      background: #dbdbdb;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      display: inline-block;
      cursor: pointer;
    }
    .span2 {
      color: #fff;
      width: 48px;
      height: 22px;
      line-height: 22px;
      background: #4c8afc;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      display: inline-block;
      margin-left: 15px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="less">
.reportForm .voucherHeader .el-date-editor {
  width: auto;
  margin-left: 0;
  .el-input__inner {
    color: #262626;
    width: 140px;
    height: 34px;
    background: none;
    border: 1px solid #eeeeee;
    font-size: 14px;
    font-weight: 400;
  }
  .el-input__prefix {
    display: inline-block;
  }
}
</style>