<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="closeFun"></div>
    <div class="popup-layer-content">
      <div class="popup-layer-close">
        <svg-icon icon-class="popup-close" @click="closeFun"></svg-icon>
      </div>
      <div class="reprot-popup">
        <div class="reprot-popup-title">{{ item.subjectName }}</div>
        <div class="reprot-popup-box">
          <div class="reprot-popup-box-l"></div>
          <div class="reprot-popup-box-c">借方</div>
          <div class="reprot-popup-box-r">贷方</div>
        </div>
        <div class="reprot-popup-box">
          <div class="reprot-popup-box-l">期初余额</div>
          <div class="reprot-popup-box-c">
            <el-input
              v-model="item.qichuAmountDebit"
              placeholder="请输入金额"
              @input="jisuan"
            ></el-input>
          </div>
          <div class="reprot-popup-box-r">
            <el-input
              v-model="item.qichuAmountCredit"
              placeholder="请输入金额"
              @input="jisuan"
            ></el-input>
          </div>
        </div>
        <div class="reprot-popup-box">
          <div class="reprot-popup-box-l">本期发生</div>
          <div class="reprot-popup-box-c">
            <el-input
              v-model="item.benqiAmountDebit"
              placeholder="请输入金额"
              @input="jisuan"
            ></el-input>
          </div>
          <div class="reprot-popup-box-r">
            <el-input
              v-model="item.benqiAmountCredit"
              placeholder="请输入金额"
              @input="jisuan"
            ></el-input>
          </div>
        </div>
        <div class="reprot-popup-box">
          <div class="reprot-popup-box-l">本年累计</div>
          <div class="reprot-popup-box-c">
            <el-input
              v-model="item.yearAmountDebit"
              placeholder="请输入金额"
            ></el-input>
          </div>
          <div class="reprot-popup-box-r">
            <el-input
              v-model="item.yearAmountCredit"
              placeholder="请输入金额"
            ></el-input>
          </div>
        </div>
        <div class="reprot-popup-box">
          <div class="reprot-popup-box-l">期末余额</div>
          <div class="reprot-popup-box-c">
            <el-input
              disabled
              v-model="qimoAmountDebit"
              placeholder="请输入金额"
            ></el-input>
          </div>
          <div class="reprot-popup-box-r">
            <el-input
              disabled
              v-model="qimoAmountCredit"
              placeholder="请输入金额"
            ></el-input>
          </div>
        </div>

        <div class="reprot-popup-btns" @click="saveReport">保 存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { modifySubjectBalance } from "@/http/api";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      input: "",
      qimoAmountDebit: 0,
      qimoAmountCredit: 0,
      item: {
        name: "2123",
      },
    };
  },
  props: {
    reportItem: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default() {
        return "";
      },
    },
  },
  mounted() {
    console.log(this.reportItem);
    this.item = this.$config.cloneObj(this.reportItem);
    this.jisuan();
  },
  methods: {
    jisuan() {
      this.qimoAmountDebit = 0;
      this.qimoAmountCredit = 0;
      let a =
        this.item.qichuAmountDebit * 1 -
        this.item.qichuAmountCredit * 1 +
        this.item.benqiAmountDebit * 1 -
        this.item.benqiAmountCredit * 1;
      if (a > 0) {
        this.qimoAmountDebit = a;
      } else {
        this.qimoAmountCredit = Math.abs(a);
      }
    },
    closeFun() {
      this.$emit("closeFun");
    },
    saveReport() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        reportIdMonth: this.item.reportIdMonth,
        qichuAmountDebit: this.item.qichuAmountDebit * 1,
        qichuAmountCredit: this.item.qichuAmountCredit * 1,
        benqiAmountDebit: this.item.benqiAmountDebit * 1,
        benqiAmountCredit: this.item.benqiAmountCredit * 1,
        yearAmountDebit: this.item.yearAmountDebit * 1,
        yearAmountCredit: this.item.yearAmountCredit * 1,
        // qimoAmountDebit: this.item.qimoAmountDebit * 1,
        // qimoAmountCredit: this.item.qimoAmountCredit * 1,
      };
      this.modifySubjectBalance(param);
    },
    modifySubjectBalance(param) {
      modifySubjectBalance(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$emit("sucFun");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.popup-layer-content {
  min-height: 420px;
  border-radius: 5px;
  padding-bottom: 20px;
}
.reprot-popup {
  width: 100%;
  .reprot-popup-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #262626;
    padding: 15px 0 15px;
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
  }
  .reprot-popup-box {
    padding: 0 30px;
    margin-top: 30px;
    .reprot-popup-box-l {
      width: 60px;
      display: inline-block;
    }
    .reprot-popup-box-c {
      width: calc((100% - 80px) / 2);
      display: inline-block;
      margin-left: 10px;
      text-align: center;
    }
    .reprot-popup-box-r {
      width: calc((100% - 80px) / 2);
      display: inline-block;
      margin-left: 10px;
      text-align: center;
    }
  }
  .reprot-popup-btns {
    width: 150px;
    margin: 0 auto;
    text-align: center;
    height: 34px;
    line-height: 34px;
    background: #4c8afc;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    margin-top: 35px;
    user-select: none;
    cursor: pointer;
  }
}
</style>