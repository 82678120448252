<template>
  <el-container>
    <el-header>
      <div>添加平衡式</div>
      <div class="balanced">
        <div class="balanced-box balanced-box-1">
          主商品：<input type="text" placeholder="请输入名称" />
          (商品数量：<input type="text" placeholder="请输入" /> 商品单位：<input
            type="text"
            placeholder="请输入"
          />)
        </div>
        <div class="balanced-box" v-for="(e, i) in list" :key="i">
          <span v-if="i === 0" class="icon1">=</span>
          <span v-else class="icon1">+</span>
          子商品：<input
            type="text"
            v-model="e.name"
            placeholder="请输入名称"
          />
          (商品单价：<input
            type="text"
            v-model="e.num"
            placeholder="请输入"
          />%)

          <i
            @click="addBalanced"
            v-if="i === list.length - 1"
            class="el-icon-circle-plus-outline"
          ></i>
        </div>
        <el-button type="primary" size="medium">添 加</el-button>
      </div>
    </el-header>
    <div class="clear"></div>
    <el-main>
      <div>历史记录</div>
      <br />
      <div class="balanced-list">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="name" label="商品名称"> </el-table-column>
          <el-table-column prop="num" align="center" label="数量" width="180">
          </el-table-column>
          <el-table-column prop="unit" align="center" label="单位" width="180">
          </el-table-column>
          <el-table-column
            prop="address"
            align="center"
            width="80"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "",
          num: "",
          unit: "",
        },
      ],
      tableData: [
        {
          unit: "台",
          num: "1",
          name: "电脑",
        },
        {
          unit: "个",
          num: "4",
          name: "洗衣机",
        },
        {
          unit: "个",
          num: "3",
          name: "显示器",
        },
        {
          unit: "个",
          num: "5",
          name: "桌子",
        },
      ],
    };
  },
  methods: {
    handleClick(row) {},
    addBalanced() {
      this.list.push({
        name: "",
        num: "",
        unit: "",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.balanced {
  // width: 100%;
  padding: 10px 20px;
  text-indent: 20px;
  .balanced-box {
    font-size: 14px;
    height: 40px;
    position: relative;
    .icon1 {
      position: absolute;
      left: -20px;
      top: 3px;
      display: inline-block;
    }
    input {
      border-bottom: 1px solid #dbdbdb;
      font-size: 14px;
      font-weight: 400;
      &::placeholder {
        color: #bbb;
      }
    }
  }
}
.balanced-list {
}
.el-header,
.el-main {
  padding: 0;
  height: auto !important;
}

.el-main {
  border-top: 1px solid #dbdbdb;
  padding-top: 15px;
}
.balanced-box-1 {
}
.el-icon-circle-plus-outline {
  color: #4c8afc;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
</style>