<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="closeFun(false)"></div>
    <div class="stock-popup-main">
      <div class="popup-layer-close" @click="closeFun(false)">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="stock-popup-name popup-add-name">添加库存品类</div>
      <div class="stock-popup-box-scrollbar webkit-scrollbar1">
        <div class="stock-popup-box">
          <div class="stock-popup-box-l" style="width: 100%">
            <el-input
              v-model="stockValue"
              placeholder="请输入库存品类"
            ></el-input>
            <!-- <el-select v-model="stockValue" filterable placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.keyWordText"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>
      </div>
      <el-button type="primary" class="stock-popup-btn" @click="addStock">
        添 加
      </el-button>
    </div>
  </div>
</template>

<script>
import { addInventory } from "@/http/api";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      stockValue: "",
    };
  },
  mounted() {},
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    addStock() {
      let param = {
        enterpriseId: this.enterpriseId,
        keywordId: this.stockValue,
      };
      addInventory(param).then((res) => {
        if (res.code === 0) {
          this.$store.commit("MyMessage", "操作成功");
          this.closeFun(true);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    closeFun(flag) {
      this.$emit("closeFun", flag);
    },
  },
};
</script>

<style lang="less" scoped>
.stock-popup-main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 412px;
  min-height: 189px;
  margin-left: -206px;
  margin-top: -300px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 12px;
  padding: 10px 0;
  text-align: center;
  .stock-popup-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    .el-icon-close {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .stock-popup-box-scrollbar {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
  }
  .stock-popup-title {
    width: 323px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #262626;
  }
  .stock-popup-name {
    width: 323px;
    margin: 23px auto 10px;
    text-align: left;

    font-size: 16px;
    font-weight: 400;
    color: #262626;
    &.popup-add-name {
      margin: 28px auto 10px;
    }
  }
  .stock-popup-box {
    width: 323px;
    margin-left: 45px;
    text-align: left;
    padding-bottom: 10px;
    .stock-popup-box-l {
      width: 273px;
      display: inline-block;
      .el-select {
        width: 100%;
      }
    }
    .stock-popup-box-r {
      width: calc(100% - 273px);
      display: inline-block;
      text-align: right;
    }
  }
  .stock-popup-btn {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 323px;
    height: 34px;
    line-height: 34px;
    background: #4c8afc;
    border: 1px solid #4c8afc;
    border-radius: 3px;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  }
}
</style>