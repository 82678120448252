import { render, staticRenderFns } from "./taxReturns.vue?vue&type=template&id=1d1d0d36&scoped=true&"
import script from "./taxReturns.vue?vue&type=script&lang=js&"
export * from "./taxReturns.vue?vue&type=script&lang=js&"
import style0 from "./taxReturns.vue?vue&type=style&index=0&id=1d1d0d36&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d1d0d36",
  null
  
)

export default component.exports