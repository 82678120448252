<template>
  <div class="height100">
    <ReportFormHeader
      v-if="headerShow"
      :mouth="mouth"
      @dateChange="dateChange"
      :headerData="headerData"
      @dowmReport="dowmReport"
      @addAccount="addAccount"
      addType="kemu"
    ></ReportFormHeader>
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-table">
          <div class="reportForm-table-center">
            <table class="accountTable" v-if="subjectList.length > 0">
              <thead>
                <tr>
                  <th rowspan="2">科目代码</th>
                  <th rowspan="2">科目名称</th>
                  <th colspan="2">期初余额</th>
                  <th colspan="2">本期发生</th>
                  <th colspan="2">本年累计</th>
                  <th colspan="2">期末余额</th>
                  <th rowspan="5" v-if="visit === 'visit'">操作</th>
                </tr>
                <tr>
                  <th><span>借方</span></th>
                  <th><span>贷方</span></th>
                  <th><span>借方</span></th>
                  <th><span>贷方</span></th>
                  <th><span>借方</span></th>
                  <th><span>贷方</span></th>
                  <th><span>借方</span></th>
                  <th><span>贷方</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in subjectList" :key="index">
                  <td>
                    <div
                      class="tableWidth1"
                      :class="{
                        tdLeft1: item.subjectCode.length === 4,
                        tdLeft2: item.subjectCode.length === 6,
                        tdLeft3: item.subjectCode.length > 6,
                      }"
                    >
                      <slot v-if="!item.subjectCodeSelf">{{
                        item.subjectCode
                      }}</slot>
                      <slot v-if="item.subjectCodeSelf">
                        {{ item.fatherCode }}{{ item.subjectCodeSelf }}
                      </slot>
                    </div>
                  </td>
                  <!-- <td v-if="item.textFlag" :rowspan="item.num"> -->
                  <td>
                    <div class="tableWidth2">
                      {{ item.subjectName }}
                    </div>
                  </td>
                  <td>
                    <div class="tableWidth3">
                      {{
                        item.qichuAmountDebit === 0
                          ? "0"
                          : item.qichuAmountDebit
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="tableWidth3">
                      {{
                        item.qichuAmountCredit === 0
                          ? "0"
                          : item.qichuAmountCredit
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="tableWidth3">
                      {{
                        item.benqiAmountDebit === 0
                          ? "0"
                          : item.benqiAmountDebit
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="tableWidth3">
                      {{
                        item.benqiAmountCredit === 0
                          ? "0"
                          : item.benqiAmountCredit
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="tableWidth3">
                      {{
                        item.yearAmountDebit === 0 ? "0" : item.yearAmountDebit
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="tableWidth3">
                      {{
                        item.yearAmountCredit === 0
                          ? "0"
                          : item.yearAmountCredit
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="tableWidth3">
                      {{
                        item.qimoAmountDebit === 0 ? "0" : item.qimoAmountDebit
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="tableWidth3">
                      {{
                        item.qimoAmountCredit === 0
                          ? "0"
                          : item.qimoAmountCredit
                      }}
                    </div>
                  </td>
                  <td v-if="visit === 'visit'">
                    <div
                      class="tableWidth5"
                      v-if="item.show"
                      @click="reportClick(item)"
                    >
                      修改
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <NoRecord v-else></NoRecord>
          </div>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
    <reprotPopupA
      v-if="reprotPopupFlag"
      :reportItem="reportItem"
      @sucFun="sucFun"
      @closeFun="closeFun"
    ></reprotPopupA>
    <BalanceAdd
      @closeFun="closeFun"
      v-if="showPopup"
      :mouth="mouth"
      v-bind:showPopup.sync="showPopup"
    ></BalanceAdd>
  </div>
</template>
<script>
import ReportFormHeader from "./components/reportFormHeader.vue";
import reprotPopupA from "./components/reprotPopupA.vue";
import TheFooter from "@/components/TheFooter.vue";
import NoRecord from "@/components/NoRecord.vue";
import BalanceAdd from "./components/balanceAdd.vue";
import { querySubjectBalance, querySubjectBalanceSmall } from "@/http/api";
export default {
  data() {
    return {
      showPopup: false,
      headerShow: false,
      tableShow: false,
      reprotPopupFlag: false,
      overflowStyle: false,
      mouth: "",
      downUrl: "",
      date: this.$config.getDate(),
      visit: sessionStorage.getItem("visit"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      accountingCriterion: sessionStorage.getItem("accountingCriterion"),
      company: sessionStorage.getItem("company"),
      token: sessionStorage.getItem("pc-tkn"),
      userId: sessionStorage.getItem("userId"),
      subjectList: [],
      oldDate: "",
      headerData: {
        left: true,
        right: true,
        // title: "科目余额表",
        title: "",
        btn: 1,
        xiazai: "true",
      },
      reportItem: {},
    };
  },
  components: {
    ReportFormHeader,
    TheFooter,
    NoRecord,
    reprotPopupA,
    BalanceAdd,
  },
  mounted() {
    let _route = this.$route;
    if (_route.query.mouth) {
      this.mouth = _route.query.mouth;
    } else {
      this.mouth = this.date.year + "-" + this.date.thisMounth;
    }
    this.oldDate = this.mouth;
    this.init();
    this.$nextTick(() => {
      this.headerShow = true;
    });
  },
  methods: {
    addAccount() {
      this.showPopup = true;
    },
    sucFun() {
      this.closeFun();
      this.init();
    },
    closeFun() {
      this.showPopup = false;
      this.reprotPopupFlag = false;
    },
    reportClick(itemList) {
      this.reportItem = itemList;
      this.reprotPopupFlag = true;
    },
    dowmReport() {
      let that = this;
      this.$store.commit("showLoading", "");
      const formData = new FormData();
      formData.append("enterpriseId", this.enterpriseId);
      formData.append("userId", this.userId);
      formData.append("beginDate", this.mouth);
      formData.append("endDate", this.mouth);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          console.log(data);
          const blob = new Blob([xhr.response], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.company + " " + this.mouth + "科目余额表.xlsx"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          }
          this.$store.commit("hideLoading", "");
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open("POST", url + that.downUrl);

      xhr.setRequestHeader("token", this.token);
      xhr.responseType = "blob";
      xhr.send(formData);
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    init() {
      this.tableShow = false;

      if (this.accountingCriterion + "" == "1") {
        this.querySubjectBalanceSmall();
        this.downUrl =
          "/manager/enterprise-small-report/subjectBalanceDownload";
      } else {
        this.querySubjectBalance();
        this.downUrl =
          "/manager/enterprise-report-query/subjectBalanceDownload";
      }
    },
    dateChange(mouth) {
      this.$router.push({
        path: "/accountBalance",
        query: {
          mouth: mouth,
        },
      });
      this.mouth = mouth;
      this.init();
    },
    copyListFun(list) {
      list.forEach((element) => {
        let zhiduan = {};
        for (let key in element) {
          if (key !== "subList") {
            zhiduan[key] = element[key];
          }
        }
        this.copyList.push(element);
        if (element.subList.length > 0) {
          this.copyListFun(element.subList);
        } else {
          element.show = true;
        }
      });
    },
    querySubjectBalance() {
      let param = {
        enterpriseId: this.enterpriseId,
        beginDate: this.mouth,
        endDate: this.mouth,
      };
      querySubjectBalance(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.copyList = [];
          if (res.data.length > 0) {
            this.subjectList = res.data;
            let date = [];
            this.copyListFun(res.data);
            this.subjectList = this.copyList;
            this.$nextTick(() => {
              this.overflowStyle = this.$config.hasScrolled(
                "reportFormMain",
                "horizontal"
              );
            });
            this.oldDate = this.mouth;
            // this.hbFun(this.subjectList);
          } else {
            this.overflowStyle = false;
            // this.subjectList = [];
            if (this.oldDate !== this.mouth) {
              this.$store.commit("MyMessage", "暂无记录");
              this.mouth = this.oldDate;
            }
            return;
          }
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    hbFun(list) {
      let num = 1;
      let index = 0;
      list.forEach((e, i) => {
        if (i !== 0) {
          // if(e.subjectName)
          if (e.subjectName === list[i - 1].subjectName) {
            num++;
          } else {
            list[index].num = num;
            list[index].textFlag = true;
            num = 1;
            index = i;
          }
        }
      });
    },
    querySubjectBalanceSmall() {
      let param = {
        enterpriseId: this.enterpriseId,
        beginDate: this.mouth,
        endDate: this.mouth,
      };
      querySubjectBalanceSmall(param).then((res) => {
        if (res.code === 0) {
          if (Array.isArray(res.data)) {
            this.copyList = [];
            if (res.data.length > 0) {
              this.subjectList = res.data;
              let date = [];
              this.copyListFun(res.data);
              this.subjectList = this.copyList;
              this.oldDate = this.mouth;
              // console.log(this.subjectList);
              // this.hbFun(this.subjectList);
            } else {
              this.subjectList = [];
              // if (this.oldDate !== this.mouth) {
              //   this.$store.commit("MyMessage", "暂无记录");
              //   this.mouth = this.oldDate;
              //   this.$router.push({
              //     path: "/accountBalance",
              //     query: {
              //       mouth: this.oldDate,
              //     },
              //   });
              // }
              return;
            }
          }
          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          this.overflowStyle = false;
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tableWidth1 {
  width: 190px;
  margin: 0 auto;
  &.tdLeft1 {
    text-align: left;
    font-weight: bold;
  }
  &.tdLeft2 {
    text-align: left;
    text-indent: 10px;
  }
  &.tdLeft3 {
    text-align: left;
    text-indent: 20px;
  }
}
.tableWidth2 {
  width: 180px;
  margin: 0 auto;
}
.tableWidth3 {
  width: 95px;
  margin: 0 auto;
}
.tableWidth5 {
  width: 50px;
  // padding-left: 18px;
  text-align: center;
  margin: 0 auto;
}
tbody {
  .tableWidth5 {
    color: #4c8afc;
    cursor: pointer;
  }
}
</style>
