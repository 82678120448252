<template>
  <div class="height100">
    <ReportFormHeader
      v-if="headerShow"
      :mouth="mouth"
      @dateChange="dateChange"
      :headerData="headerData"
      @dowmReport="dowmReport"
      @jiduDate="jiduDate"
      jindu="yue"
      hideDate="true"
    ></ReportFormHeader>
    <div class="reportForm-main" id="reportFormMain">
      <div
        class="reportForm-main-conent"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="reportForm-table">
          <div class="reportForm-table-center">
            <componentTable
              v-if="tableShow"
              :tableData="tableData"
              jidu="本季度金额"
            ></componentTable>
          </div>
        </div>
      </div>
      <TheFooter></TheFooter>
    </div>
  </div>
</template>
<script>
import ReportFormHeader from "./components/reportFormHeader.vue";
import componentTable from "./components/componentTable.vue";
import TheFooter from "@/components/TheFooter.vue";
import { cashFlowReport, cashFlowReportSmall } from "@/http/api";
export default {
  data() {
    return {
      headerShow: false,
      tableShow: false,
      overflowStyle: false,
      value: "",
      mouth: "",
      date: this.$config.getDate(),
      visit: sessionStorage.getItem("visit"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      accountingCriterion: sessionStorage.getItem("accountingCriterion"),
      company: sessionStorage.getItem("company"),
      tableData: [],
      tableLeft: [],
      tableRight: [],
      headerData: {
        left: false,
        right: true,
        title: "现金流量表",
        btn: 1,
        xiazai: "true",
        jiduShow: true,
        quarter: "",
        url: "cashFlow",
      },
      queryList: {
        data1: ["03", "02", "01"],
        data2: ["06", "05", "04"],
        data3: ["09", "08", "07"],
        data4: ["12", "11", "10"],
      },
      downText: "",
      quarter: "",
      queryYear: "",
      queryDate: [],
      porxyData: [],
    };
  },
  components: {
    ReportFormHeader,
    componentTable,
    TheFooter,
  },
  mounted() {
    let _route = this.$route;
    if (_route.query.mouth) {
      this.mouth = _route.query.mouth;
    } else {
      this.mouth = this.date.year + "-" + this.date.thisMounth;
    }
    this.quarter = this.$config.status(this.date.thisMounth);
    this.queryDate = this.queryList["data" + this.quarter];
    this.headerData.quarter = this.quarter;
    this.downText = `第${this.quarter}季度`;
    this.porxyData = [];
    this.queryYear = this.date.year;
    if (
      this.date.thisMounth === "01" ||
      this.date.thisMounth === "02" ||
      this.date.thisMounth === "03"
    ) {
      this.queryYear = this.date.year - 1;
    }
    let param = {
      enterpriseId: this.enterpriseId,
      month: this.queryYear + "-" + this.queryDate[0],
    };
    if (this.accountingCriterion + "" == "1") {
      this.cashFlowReportSmall(param, 0);
    } else {
      this.cashFlowReport(param, 0);
    }
    this.$nextTick(() => {
      this.headerShow = true;
    });
  },
  methods: {
    jiduDate(index) {
      this.porxyData = [];
      this.queryDate = this.queryList["data" + index];
      if (
        this.date.thisMounth === "01" ||
        this.date.thisMounth === "02" ||
        this.date.thisMounth === "03"
      ) {
        this.queryYear = this.date.year - 1;
      }
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.queryYear + "-" + this.queryDate[0],
      };
      if (this.accountingCriterion + "" == "1") {
        this.cashFlowReportSmall(param, 0);
      } else {
        this.cashFlowReport(param, 0);
      }
      this.downText = `第${index}季度`;
    },
    dowmReport() {
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/util/Export2Excel.js");

        const tHeader = ["项目", "行次", "本年累计金额", "本月金额"]; // 上面设置Excel的表格第一行的标题
        const filterVal = ["name", "line", "yearAmount", "monthAmount"]; // 上面的index、nickName、name是tableData里对象的属性
        const list = this.tableData; //把data里的tableData存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(
          tHeader,
          data,
          `${this.company} 现金流量表--${this.downText}`,
          true
        );
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    porxyFun() {
      let array = this.porxyData[0];
      // console.log(this.porxyData);
      this.porxyData.forEach((e, i) => {
        if (i !== 0) {
          e.forEach((e1, i1) => {
            console.log(e1);
            if (e1.name === "加：期初现金余额") {
              if (i === 2) {
                array[i1].monthAmount = (e1.monthAmount * 1).toFixed(2);
              }
            } else if (e1.name === "五、期末现金余额") {
            } else {
              if (e1.monthAmount !== "")
                array[i1].monthAmount = (
                  array[i1].monthAmount * 1 +
                  e1.monthAmount * 1
                ).toFixed(2);
              if (array[i1].monthAmount === "0.00") array[i1].monthAmount = 0;
            }
          });
        }
      });
      this.tableData = array;
      this.tableShow = true;
      this.$nextTick(() => {
        // reportFormMain
        this.overflowStyle = this.$config.hasScrolled(
          "reportFormMain",
          "horizontal"
        );
      });
    },
    init() {
      this.tableShow = false;
      if (this.accountingCriterion + "" == "1") {
        this.cashFlowReportSmall();
      } else {
        this.cashFlowReport();
      }
    },
    dateChange(mouth) {
      this.mouth = mouth;
      this.init();
    },
    cashFlowReport(param, i) {
      // let param = {
      //   enterpriseId: this.enterpriseId,
      //   month: this.mouth,
      // };
      cashFlowReport(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.tableData = [];
          res.data.forEach((e1, i1) => {
            let line = "";
            let monthAmountTotal =
              e1.monthAmountTotal === "0.00" ? "0" : e1.monthAmountTotal;
            let yearAmountTotal =
              e1.yearAmountTotal === "0.00" ? "0" : e1.yearAmountTotal;
            if (e1.name === "一、经营活动产生的现金流量：") {
              line = "1";
            } else if (e1.name === "二、投资活动产生的现金流量：") {
              line = "12";
            } else if (e1.name === "三、筹资活动产生的现金流量：") {
              line = "25";
            } else if (e1.name === "四、汇率变动对现金及现金等价物的影响") {
              line = "35";
            } else if (e1.name === "五、现金及现金等价物净增加额") {
              line = "36";
            } else if (e1.name === "五、现金及现金等价物净增加额") {
              line = "24";
            } else if (e1.name === "加：期初现金及现金等价物余额") {
              line = "38";
            } else if (e1.name === "六、期末现金及现金等价物余额") {
              line = "39";
            }
            this.tableData.push({
              id: e1.id,
              level: 99,
              line: line,
              monthAmount: monthAmountTotal,
              name: e1.name,
              yearAmount: yearAmountTotal,
            });
            e1.list.forEach((e2, i2) => {
              e2.level = 2;
              e2.monthAmount = e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
              e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
              this.tableData.push(e2);
            });
          });

          this.porxyData.push(this.tableData);
          if (i === 2) {
            this.porxyFun(i);
          }
          i++;
          if (i < 3) {
            let param1 = {
              enterpriseId: this.enterpriseId,
              month: this.queryYear + "-" + this.queryDate[i],
            };
            this.cashFlowReport(param1, i);
          }

          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          this.overflowStyle = false;
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    cashFlowReportSmall(param, i) {
      cashFlowReportSmall(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.tableData = [];
          res.data.forEach((e1, i1) => {
            let line = "";
            let monthAmountTotal =
              e1.monthAmountTotal === "0.00" ? "0" : e1.monthAmountTotal;
            let yearAmountTotal =
              e1.yearAmountTotal === "0.00" ? "0" : e1.yearAmountTotal;
            if (e1.name === "一、经营活动产生的现金流量：") {
              monthAmountTotal = "";
              yearAmountTotal = "";
            } else if (e1.name === "二、投资活动产生的现金流量：") {
              monthAmountTotal = "";
              yearAmountTotal = "";
            } else if (e1.name === "三、筹资活动产生的现金流量：") {
              monthAmountTotal = "";
              yearAmountTotal = "";
            } else if (e1.name === "四、汇率变动对现金及现金等价物的影响") {
              line = "35";
            } else if (e1.name === "五、现金及现金等价物净增加额") {
              line = "36";
            } else if (e1.name === "五、现金及现金等价物净增加额") {
              line = "24";
            } else if (e1.name === "加：期初现金及现金等价物余额") {
              line = "38";
            } else if (e1.name === "六、期末现金及现金等价物余额") {
              line = "39";
            } else if (e1.name === "四、现金净增加额") {
              line = "20";
            } else if (e1.name === "六、期末现金及现金等价物余额") {
              line = "39";
            } else if (e1.name === "加：期初现金余额") {
              line = "22";
            } else if (e1.name === "五、期末现金余额") {
              line = "23";
            }
            this.tableData.push({
              id: e1.id,
              level: 99,
              line: line,
              monthAmount: monthAmountTotal,
              name: e1.name,
              yearAmount: yearAmountTotal,
            });

            e1.list.forEach((e2, i2) => {
              e2.level = 2;
              e2.monthAmount = e2.monthAmount === "0.00" ? "0" : e2.monthAmount;
              e2.yearAmount = e2.yearAmount === "0.00" ? "0" : e2.yearAmount;
              if (e2.name !== "   加：期初现金余额") {
                this.tableData.push(e2);
              }
            });
          });

          this.porxyData.push(this.tableData);

          if (i === 2) {
            this.porxyFun(i);
          }
          i++;
          if (i < 3) {
            let param1 = {
              enterpriseId: this.enterpriseId,
              month: this.queryYear + "-" + this.queryDate[i],
            };
            this.cashFlowReportSmall(param1, i);
          }

          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "reportFormMain",
              "horizontal"
            );
          });
        } else {
          this.overflowStyle = false;
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>
